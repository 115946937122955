import React from 'react';
import moment from 'moment';
import FormattedDecimal from '../common/FormattedDecimal';
import { useDispatch } from 'react-redux';
import { setSelectedItem, deleteItem } from './duck/actions';

const Activity = props => {
    const { item } = props;
    const account = item.expensePaymentName || item.invoicePaymentName || item.sourceBankName;

    const dispatch = useDispatch();
    const setItem = () => dispatch(setSelectedItem(item));
    const del = () => dispatch(deleteItem(item));

    return (
        <div className={`activities__item`}>
            <div className="activities__item-type" onClick={setItem}>
                <span>
                    {item.activityTypeName}
                </span>
            </div>
            <div className="activities__item-activitydate middle" onClick={setItem}>
                <span>
                    {moment(item.activityDate).format('DD/MM/YYYY')}
                </span>
            </div>
            <div className="activities__item-account" onClick={setItem}>
                <span>
                    {account}
                </span>
            </div>
            <div className="activities__item-description" onClick={setItem}>
                <span>
                    {item.activityDescription}
                </span>
            </div>
            <div className="activities__item-amount right" onClick={setItem}>
                <FormattedDecimal text="" value={item.debitAmount - item.creditAmount} abb={item.abbreviation} className="activities__item-amount--main" />
            </div>
            <div className="activities__item-balance right" onClick={setItem}>
                <FormattedDecimal text="" value={item.balance} abb={item.abbreviation} className="activities__item-balance--main" />
            </div>
            <div className="activities__item-button middle">
                <svg className="icon icon-bin red" onClick={del}>
                    <use xlinkHref="../img/sprite.svg#icon-bin"></use>
                </svg>
            </div>
        </div>
    )
}

export default Activity;