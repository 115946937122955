import * as comops from '../common/comops'
import urls from '../constants/urls'

export const login = async (userName, password) => {

    const browserInformation = {
        name: navigator.appName,
        codename: navigator.appCodeName,
        version: navigator.appVersion.substring(0, 4),
        platform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        screenWidth: screen.width,
        screenHeight: screen.height,
        userAgent: navigator.userAgent
    }

    const options = {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
            userName,
            password,
            browserInformation
        })
    }

    const res = await comops.fetchAsync(urls.LOGIN, options);

    if (res.isSuccess && res.data)
        localStorage.userData = JSON.stringify(res.data);
    else {
        delete localStorage.userData;
    }
    return res;
}

export const validatetoken = async () => {

    const options = {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({

        })
    }

    const res = await comops.fetchAsync(urls.VALIDATETOKEN, options);

    if (!res.isSuccess || !res.data)
        delete localStorage.userData;
    return res;
}

export const limited = async () => {
    const options = {
        method: 'post',
        mode: 'cors'
    }
    const res = await comops.fetchAsync(urls.USER_LIMITED, options);
    
    return res;
}