import React from 'react';
import { useSelector } from 'react-redux';
import { taxRates } from '../apis/parameter';
import SelectSync from './SelectSync';

const TaxSelect = props => {

    const options = useSelector(state => state.main.taxRates);

    return (
        <SelectSync
            changeFn={props.setValue}
            optionsFn={taxRates}
            value={props.value}
            placeholder='Tax'
            options = {options}
        />
    )
}

export default TaxSelect;