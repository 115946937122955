import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../apis/user';
import { useHistory } from "react-router-dom";
import * as modalActions from '../modal/duck/actions';
import { Translate } from '../common/Translate';
import useTranslate from '../common/useTranslate';
import Loading from '../common/Loading';

export const Login = props => {

    const [logging, setLogging] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    
    const loginClicked = e => {
        e.preventDefault();
        setLogging(true);
        const userName = document.querySelector('#user-email').value;
        const password = document.querySelector('#user-password').value;
        login(userName, password).then(result => {
            if (result.isSuccess) {
                history.push('/invoice-list');
            }
            else {
                dispatch(modalActions.errorModal(result));
                setLogging(false);
            }
        }).catch(err => {
            console.log(err);
            setLogging(false)
        })
    };

    return (
        <form className="basic-form login" onSubmit={loginClicked}>
            <div id="login-email" className="basic-form__row">
                <div className="basic-form__label">
                    <svg className="basic-form__icon">
                        <use xlinkHref="../img/sprite.svg#icon-user"></use>
                    </svg>
                </div>
                <div className="basic-form__input-container">
                    <input
                        type="text"
                        id="user-email"
                        className="basic-form__input"
                        placeholder={useTranslate('KullaniciAdi')}  //Burayi translate et
                    />
                </div>
            </div>
            <div id="login-password" className="basic-form__row">
                <div className="basic-form__label">
                    <svg className="basic-form__icon">
                        <use xlinkHref="../img/sprite.svg#icon-key"></use>
                    </svg>
                </div>
                <div className="basic-form__input-container">
                    <input
                        type="password"
                        id="user-password"
                        className="basic-form__input"
                        placeholder={useTranslate('Password')} //Burayi translate et
                    />
                </div>
            </div>
            <div id="login-button" className="basic-form__row">
                <div className="basic-form__label">

                </div>
                <div className="basic-form__input-container button">
                    <button className="btn btn-form basic-form__button" >
                        {logging ? <Loading/> : <Translate>Login</Translate> /*Burayi translate et*/}
                    </button>
                </div>
            </div>
        </form>
    );
}