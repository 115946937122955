import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import PolicyAsync from '../selectAsync/PolicyAsync';
import ExpenseContext from '../contexts/expense-context';
import { Checkbox } from '../checkbox/Checkbox';

const ExpensePolicy = props => {

    const { setPolicy, policy, expenseId, genel, setGenel, setAccount } = useContext(ExpenseContext);

    return (
        <div className="expense-general__row larger">
            <div className="expense-general__label">
                <svg className="expense-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-copy"></use>
                </svg>
                <span className="expense-general__label--label">
                    <Translate>Policy</Translate>
                </span>
            </div>
            <div className="expense-general__input">
                <PolicyAsync setPolicy={setPolicy} value={policy} />
                <span className="expense-general__input--text"><Translate>ChoseFromClientPeriod</Translate></span>
            </div>
            <div className="expense-general__buttons">
                <Checkbox id="ex-genel" name="ex-genel" title="Genel" isChecked={genel} checkChange={() => {
                    setAccount(null);
                    setPolicy(null);
                    setGenel(!genel)
                }} />
            </div>
        
        </div>
    )
}

export default ExpensePolicy;