import React, { memo } from 'react';
import FormattedDecimal from '../common/FormattedDecimal';
import { Translate } from '../common/Translate';

const StatusTable = memo(props => {

    const { data, datakey, header } = props;
    const sortedList = [...data].sort((a, b) => - a.dued - a.notDued + b.dued + b.notDued).slice(0, 6);

    return (<div className="dashboard-table">
        <h3 className="generic-chart__header"><Translate>{header}</Translate></h3>
        <div className="dashboard-table__row column-head">
            <div className="dashboard-table__column dashboard-table__column--definition column-head">

            </div>
            <div className="dashboard-table__column dashboard-table__column--dued-usd column-head">
                <span><Translate>Vadesi Geçmis</Translate></span>
            </div>
            <div className="dashboard-table__column dashboard-table__column--not-dued-usd column-head">
                <span><Translate>Vadesi Gelmemis</Translate></span>
            </div>
        </div>
        {
            sortedList.map(item => {
                return <div className="dashboard-table__row" key={item[datakey]}>

                    <div className="dashboard-table__column dashboard-table__column--definition">
                        <span className="dashboard-table__span">  {item[datakey]}</span>
                    </div>
                    <div className="dashboard-table__column dashboard-table__column--dued-usd">
                        <FormattedDecimal className="dashboard-table__span" value={item.duedUsd} abb="$" />
                    </div>
                    <div className="dashboard-table__column dashboard-table__column--not-dued-usd">
                        <FormattedDecimal className="dashboard-table__span" value={item.notDuedUsd} abb="$" />
                    </div>
                </div>
            })
        }
    </div>)

});


export default StatusTable;