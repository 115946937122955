import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCulture } from '../main/duck/actions';

const Language = props => {

    const culture = useSelector(state => state.main.culture);
    const dispatch = useDispatch();
    return (
        <div className="culture-container">
            <Link to="#" id="en-Us" onClick={e => {
                e.preventDefault();
                dispatch(setCulture("en-Us"));
            }} style={culture === "en-Us" ? { backgroundSize: '3rem' } : { backgroundSize: '2rem' }} >en</Link>
            <Link to="#" id="tr-Tr" onClick={(e) => {
                e.preventDefault();
                dispatch(setCulture("tr-Tr"));
            }} style={culture === "tr-Tr" ? { backgroundSize: '3rem' } : { backgroundSize: '2rem' }}>tr</Link>
        </div>
    )
}

export default Language