import React from 'react';
import Expense from './Expense';
import ExpenseHeader  from './ExpenseHeader';

const Expenses = props => {


    const { data, loading } = props;

    return <>
        <ExpenseHeader />
        <div className="expenses">
            {data.map(item => {
                return <Expense item={item} key={`key_${item.id}`} />
            })}
        </div>
    </>
}

export default Expenses;