import React, { useContext,useEffect } from 'react';
import { Translate } from '../common/Translate';
import PolicyAsync from '../selectAsync/PolicyAsync';
import InvoviceContext from '../contexts/invoice-context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openPaymentPage } from './duck/actions';
import { Checkbox } from '../checkbox/Checkbox';

const InvoicePolicy = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { setPolicy, policy, invoiceId,genel, setGenel, setAccount,account } = useContext(InvoviceContext);


    useEffect(() => {
        if (props.data.isAp) {
            const element = document.querySelector("#idinvoice-ap");
            if (element)
                element.setAttribute("checked", "true");
        }
    }, [props.data.isAp])

   
    return (
        <div className="invoice-general__row larger">
            <div className="invoice-general__label">
                <svg className="invoice-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-copy"></use>
                </svg>
                <span className="invoice-general__label--label">
                    <Translate>Policy</Translate>
                </span>
            </div>
            <div className="invoice-general__input">
                <PolicyAsync setPolicy={setPolicy} value={policy} />
                <span className="invoice-general__input--text"><Translate>ChoseFromClientPeriod</Translate></span>
            </div>
       
        
            <div style={{ display: 'flex', flexDirection: 'column'}} className="invoice-general__buttons">
                <button className="btn" onClick={() => dispatch(openPaymentPage(history, invoiceId))}><Translate>Payment</Translate></button>
                <Checkbox id="ex-genel" name="ex-genel" title="Diğer Gelirler" isChecked={genel} checkChange={() => {
                 setAccount(null);
                    setPolicy(null);
                    setGenel(!genel)
                }} />
             <Checkbox id="invoice-ap" name="invoice-ap" title="AP" /> 
            </div>

              
        </div>
    )
}

export default InvoicePolicy;