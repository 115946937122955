import React from 'react';
import { Main } from "../app/main/Main";
import { Landing } from '../app/common/Landing';

export const LandingPage = props => {

    return (<Main>
       <Landing/>
    </Main>
    )
}