import * as comops from '../common/comops'
import urls from '../constants/urls'

export const save = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.EXPENSEPAYMENT_SAVE, options);

    return res;
}


export const list = async (expenseId) => {

    const payload = {
        expenseId
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.EXPENSEPAYMENT_LIST, options);

    if (!res.isSuccess || !res.data)
        res = [];

    return res;
}