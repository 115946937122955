import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from '../common/Translate';
import DecimalNumber from '../common/DecimalNumber';
import { unformat, formatNumber } from 'accounting';
import ActivityTypeSelect from '../select/ActivityTypeSelect';
import ExPaySourceSelect from '../select/ExPaySourceSelect';
import TciSimpleDate from '../datePicker/TciSimpleDate';
import businessKeys from '../constants/businessKeys';
import { selectValue } from '../common/comops';
import { setSelected, saveAll } from './duck/actions';
import Loading from '../common/Loading';
import dictionary from '../constants/dictionary';

const ActivityForm = props => {
    const [date, setDate] = useState(new Date());
    const [activityType, setActivityType] = useState({});
    const [relatedBank, setRelatedBank] = useState({});
    const data = useSelector(state => state.activity.selected);
    const paymentSources = useSelector(state => state.main.expensePaymentSource);
    const refAmount = useRef();
    const refRate = useRef();

    const dispatch = useDispatch();
    const setItem = (item) => dispatch(setSelected(item));

    useEffect(() => {
        setActivityType(data.activityType);
        if (data.activityDate)
            setDate(data.activityDate);
        if (data.relatedBankId) {
            setRelatedBank(data.relatedBankId);
        }
    }, [, data.loading])

    const saveItem = () => {
        var item = {
            ...data,
            activityType: selectValue(activityType),
            relatedBankId: selectValue(relatedBank),
            activityDate: new Date(date)
        }

        dispatch(saveAll(item));
    }

    const mainBank = paymentSources.find(item => item.value === data.bankId);

    return (
        <div className="activity-form-container">
            {
                data.loading ?
                    <Loading className="medium" /> :
                    <div className="activity-form">
                        <div className="activity-form__row close-row">
                            <span className="activity-form__bank-info">{`${mainBank.label}${data.isSource ? ' (KAYNAK)' : ''}`}</span>
                            <span className="activity-form__close" onClick={() => setItem({})}>&times;</span>
                        </div>
                        <div className="activity-form__row">
                            <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_HEADER_DESCRIPTION}</Translate></label>
                            <input type="text" className="activity-form__input wide" id={businessKeys.ACTIVITY_DESCRIPTION} defaultValue={data.activityDescription}></input>
                        </div>
                        <div className="activity-form__row">
                            <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_TYPE}</Translate></label>
                            <ActivityTypeSelect className="activity-form__input" value={activityType} setValue={setActivityType} />
                            <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_DATE}</Translate></label>
                            <TciSimpleDate className="activity-form__input" startDate={date} setStartDate={setDate} />
                        </div>
                        <div className="activity-form__row">
                            <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_AMOUNT}</Translate></label>
                            <DecimalNumber className="activity-form__input" ref={refAmount} pre={2} id={businessKeys.ACTIVITY_AMOUNT} defaultValue={formatNumber(data.debitAmount || data.creditAmount, 2) || 0} />
                            <label className="activity-form__label"></label>
                            <div className="activity-form__input"></div>
                        </div>
                        {
                            selectValue(activityType) == businessKeys.ACTIVITYTYPE_TRANSFER &&
                            <div className="activity-form__row">
                                {/*Burası tipi para transferi seçilince active olacak*/}
                                <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_TARGETBANK}</Translate></label>
                                <ExPaySourceSelect className="activity-form__input" value={relatedBank} setValue={setRelatedBank} />
                                <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_TARGETAMOUNT}</Translate></label>
                                <DecimalNumber className="activity-form__input" ref={refRate} pre={2} id={businessKeys.ACTIVITY_TARGET_AMOUNT} defaultValue={formatNumber(data.targetAmount || 0, 2) || 1} />
                            </div>
                        }
                        <div className="activity-form__row button-row">
                            <button className="activity-form__button btn" onClick={saveItem}><Translate>{dictionary.GENERAL_KAYDET}</Translate></button>
                            <button className="activity-form__button btn" onClick={() => setItem({})}><Translate>{dictionary.GENERAL_KAPAT}</Translate></button>
                        </div>
                    </div>
            }
        </div>
    )
}


export default ActivityForm;