import React from 'react';
import { useSelector } from 'react-redux';
import { expenseTypes } from '../apis/parameter';
import SelectSync from './SelectSync';

const ExpenseTypeSelect = props => {

    const options = useSelector(state => state.main.expenseTypes);

    return (
        <SelectSync
            changeFn={props.setValue}
            optionsFn={expenseTypes}
            value={props.value}
            placeholder='Expense Types'
            options = {options}
        />
    )
}

export default ExpenseTypeSelect;