import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EkoSimplePieChart from '../charts/EkoSimplePieChart';
import EkoSimpleBarchart from '../charts/EkoSimpleBarchart';
import ReportDate from '../filter/ReportDate';
import { accountBreakdown, accountInvoicesList } from './duck/actions';
import { Invoices } from '../invoiceList/Invoices';
import Loading from '../common/Loading';
import Pagination from '../common/Pagination';
import useStateIfMounted from "../hook/useStateIfMounted";
import GenericSummaryTable from '../common/GenericSummaryTable';

const SalesContainer = props => {
    const dispatch = useDispatch();
    const [tableLoading, setTableLoading] = useState(false);
    const [data, setData] = useStateIfMounted([]);
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const culture = useSelector(state => state.main.culture);
    const [key, setKey] = useState(null);
    const [limited, setLimited] = useStateIfMounted({ sales: { list: [] }, timeline: [] });
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [expanded, setExpanded] = useState(false);
    const sort = useSelector(state => state.filter.sort);
    const sortPayload = sort.screen === 'Invoice' ? sort : {};

    useEffect(() => {
        if (!selectedFilterValues || !selectedFilterValues.length) return;
        dispatch(accountBreakdown(selectedFilterValues, key)).then(res => {
            setData(res);
        })
    }, [selectedFilterValues])

    useEffect(() => {
        getLimited();
    }, [key])

    useEffect(() => {
        getLimited(key);
    }, [page, sortPayload.column, sortPayload.direction])

    const getLimited = () => {
        if (!selectedFilterValues || !selectedFilterValues.length) return;
        setTableLoading(true);
        dispatch(accountInvoicesList(selectedFilterValues, key, sortPayload, page)).then(res => {
            setLimited(res);
            setPageCount(res.pageCount)
        }).finally(() => setTableLoading(false));
    }

    return <div className="report-sales">
        <ReportDate pickerName='DueDate' />
        <div className="report-sales__summary">
            <EkoSimplePieChart data={data} setKey={setKey} keyName='accountId' />
            <GenericSummaryTable
                data={data}
                setKey={setKey}
                nameKey='accountName'
                keyKey='accountId'
                dataKey='amount'
                expanded={expanded}
                setExpanded={setExpanded}
                itemCount={4}
            />

        </div>
        <div className="report-sales__timeline">
            {(limited.timeline && limited.timeline.length > 0) && <EkoSimpleBarchart data={limited.timeline} culture={culture} dataKey="periodName" />}
        </div>
        <div className="report-sales__invoices">
            {tableLoading ? <Loading className="medium" /> :
                <>
                    <Invoices data={limited.sales.list} loading={tableLoading} />
                    <div className="invoice-footer invoice-paginition">
                        <Pagination pageCount={pageCount} pageNumber={page} setPage={pageNumber => {
                            setPage(pageNumber)
                        }} />
                    </div>
                </>
            }
        </div>
    </div>
}

export default SalesContainer;