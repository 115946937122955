import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import { useHistory } from 'react-router-dom';
import PaymentContext from '../contexts/payment-context';
import { saveAll } from './duck/actions';
import { useDispatch } from 'react-redux';
import Loading from '../common/Loading';

const ButtonPart = props => {

    const history = useHistory();
    const { data, invoiceId, setGetData, updating, setUpdating } = useContext(PaymentContext);
    const dispatch = useDispatch();

    const savePayment = () => {
        if (updating) return;
        const payload = {
            invoiceId,
            paymentPayloads: data
        }
        setUpdating(true);
        dispatch(saveAll(payload)).then(res => setGetData(true)).finally(() => setUpdating(false));
    }

    return (
        <div className="payment-general__row">
            <div className="payment-general__buttons">
                <button className="btn btn-white" onClick={() => history.goBack()}><Translate>Geri</Translate></button>
                <button className="btn" onClick={savePayment}>{updating ? <Loading /> : <Translate>Update</Translate>}</button>
            </div>
        </div>
    )
}

export default ButtonPart;