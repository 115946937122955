import { save, deleteItem } from '../../apis/expenseType';
import { showMessage, showConfirmation } from '../../modal/duck/actions';

export const saveExpenseType = (item, setGetValues) => async dispatch => {

    var result = await save(item);

    dispatch(showMessage(result.data.message));

    if (result.isSuccess)
        setGetValues(true);

}

export const deleteExpenseType = (item, setGetValues) => dispatch => {

    var message = "Gider türünü silmek istediğinize eminmisiniz?";
    dispatch(showConfirmation(message, () => dispatch(deleteExpenseTypeNative(item, setGetValues))));
}


const deleteExpenseTypeNative = (item, setGetValues) => async dispatch => {

    var result = await deleteItem(item);

    dispatch(showMessage(result.data.message));

    if (result.isSuccess)
        setGetValues(true);

} 