import React, { useEffect } from 'react';
import Select from 'react-select';
import useStateIfMounted from '../hook/useStateIfMounted';

const SelectSync = props => {

    const [value, setValue] = useStateIfMounted(undefined);

    useEffect(() => {
        if (props.value && !props.value.value && props.value.value !== 0) {
            console.log('bu kalsin bug icin', props.options);
            const item = props.options ? props.options.find(t => t.value === props.value) : {};
            setValue(item);
        } else {
            setValue(props.value);
        }

    }, [props.options, props.value])
    
    const selectClass = props.className ? ` ${props.className}` : '';

    return <Select
        placeholder={props.placeholder || 'Seçiniz'}
        defaultValue={null}
        onChange={props.changeFn}
        isClearable={true}
        isSearchable={true}
        options={props.options}
        className={`tci-select-container${selectClass}`}
        classNamePrefix="tci-select"
        value={value}
    />
}

export default SelectSync;