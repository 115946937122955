import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '../common/Translate';

export const Expense = props => {

    const { subAktif, setSubAktif } = props;

    return (
        <div className="first-level__sub-items">
            <ul className="second-level">
                <li className="second-level__item">
                    <svg className="icon second-level__icon">
                        <use xlinkHref="../img/sprite.svg#icon-file-empty"></use>
                    </svg>
                    <Link className={`second-level__definition${(subAktif === 'giderler' ? ' active' : '')}`} to="/expense-list" onClick={e => { setSubAktif('giderler'); }}>
                        <span className="second-level__span">
                            <Translate>Giderler</Translate>
                        </span>
                    </Link>
                </li>
                {/*  <li className="second-level__item">
                    <svg className="icon second-level__icon">
                        <use xlinkHref="../img/sprite.svg#icon-credit-card"></use>
                    </svg>
                    <Link className="second-level__definition" to="/collection-list">
                        <span className="second-level__span">
                            Ödemeler
                    </span>
                    </Link>
                </li> */}
            </ul>
        </div>
    )
}