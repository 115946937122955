import React from 'react';
import { Main } from "../app/main/Main";
import { InvoiceList } from '../app/invoiceList/InvoiceList';

export const InvoiceListPage = props => {

    return (<Main>
       <InvoiceList/>
    </Main>
    )
}