import React, { useEffect, useState } from 'react';
import useStateIfMounted from '../hook/useStateIfMounted';
import ExpensePaymentContext from '../contexts/expense-payment-context';
import ExpenseButton from './ExpenseButton';
import ExpensePaymentHeader from './ExpensePaymentHeader';
import ExpensePaymentRows from './ExpensePaymentRows';
import ExpenseTotal from './ExpenseTotal';
import Loading from '../common/Loading';
import { list } from '../apis/expensePayment';

const ExpensePayment = props => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useStateIfMounted([]);
    const [active, setActive] = useState(-1);
    const { expenseId } = props;
    const [getData, setGetData] = useState(true);
    const [showEmpty, setShowEmpty] = useState(false);
    const [total, setTotal] = useState(0);
    const [abbreviation, setAbbreviation] = useState(0);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!getData) return;
        setLoading(true);
        list(expenseId).then(result => {
            setData(result.data.expensePayments);
            setAbbreviation(result.data.abbreviation);
        }).finally(() => { setGetData(false); setLoading(false); });
    }, [getData])

    return (
        <ExpensePaymentContext.Provider value={{
            data, expenseId, setData, active, setActive, showEmpty, setShowEmpty,
            total, setTotal, abbreviation, setGetData, loading, updating, setUpdating
        }}>
            {loading ? <Loading className="big" /> : <div className="payment">
                <ExpenseButton />
                <ExpensePaymentHeader />
                <ExpensePaymentRows />
                <ExpenseTotal data={data} abb={abbreviation} />
            </div>}
        </ExpensePaymentContext.Provider>
    )
}



export default ExpensePayment;