import React from 'react';
import { Main } from "../app/main/Main";
import SalesContainer from '../app/sales/SalesContainer';

const SalesPage = props => {

    return (<Main>
       <SalesContainer/>
    </Main>
    )
}

export default SalesPage;