import { accountsales, expensesByType } from '../../apis/report';
import { showMessage } from '../../modal/duck/actions';

const accountBreakdown = (filters, accountId) => async (dispatch) => {
    const payload = {
        accountId,
        filters
    };
    var result = await accountsales(payload);
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}

const expenseBreakdown = (filters) => async (dispatch) => {
    const payload = {
        filters
    };
    var result = await expensesByType(payload);
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}

export const expenseSales = (filters) => async dispatch => {
    const result = await Promise.all([dispatch(accountBreakdown(filters, null)), dispatch(expenseBreakdown(filters))]);
    return {
        sales: result[0],
        expenses: result[1]
    }
}