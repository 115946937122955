import React from 'react';
import { Main } from "../app/main/Main";
import Payment from '../app/payment/Payment';

const PaymentPage = props => {

    const invoiceId = parseInt(props.match.params.invoiceId);
    return (<Main>
        <Payment invoiceId={invoiceId} />
    </Main>
    )
}

export default PaymentPage;