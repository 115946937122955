import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from '../common/Translate';
import { SortObject } from '../common/SortObject';
import { setOrder } from '../filter/duck/actions';
import { Link } from 'react-router-dom';
import {  excelSummaryExport } from "../apis/invoice";



const SCREENNAME = 'Invoice';


export const InvoiceHeader = props => {

    const dispatch = useDispatch();

    const onLinkClick = (e, columnName) => {
        e.preventDefault();
        dispatch(setOrder(SCREENNAME, columnName));
    };

    return (
        <div className="invoices__header">
            <div className="invoices__header-image">

            </div>
            <div className="invoices__header-definition">
                <Link to="#" className="invoices__header-link" onClick={e => onLinkClick(e, 'InvoiceDefinition')}>
                    <Translate>InvoiceDefinition</Translate><SortObject screen={SCREENNAME} column='InvoiceDefinition' />
                </Link>
            </div>
            <div className="invoices__header-date invoices__header-arrangement middle">
                <Link to="#" className="invoices__header-link" onClick={e => onLinkClick(e, 'ArrangementDate')}>
                    <Translate>ArrangementDate</Translate><SortObject screen={SCREENNAME} column='ArrangementDate' />
                </Link>
            </div>
            {<div className="invoices__header-date invoices__header-paymentDue middle">
                <Link to="#" className="invoices__header-link" onClick={e => onLinkClick(e, 'PaymentDueDate')}>
                    <Translate>PaymentDueDate</Translate><SortObject screen={SCREENNAME} column='PaymentDueDate' />
                </Link>
            </div> }
            <div className="invoices__header-date invoices__header-due  middle">
                <Link to="#" className="invoices__header-link" onClick={e => onLinkClick(e, 'DueDate')}>
                    <Translate>DueDate</Translate><SortObject screen={SCREENNAME} column='DueDate' />
                </Link>
            </div>
            <div className="invoices__header-amount  middle">
                <Link to="#" className="invoices__header-link" onClick={e => onLinkClick(e, 'RemainingAmount')}>
                    <Translate>RemainingAmount</Translate><SortObject screen={SCREENNAME} column='RemainingAmount' />
                </Link>
            </div>
            {  props.excelUrl && <div style={{justifyContent:"flex-end" } }   className="filter-button padded"> 
              <svg                          
                className="icon-inner"
                onClick={() => {
                    excelSummaryExport(props.excelUrl, props.excelPayload);
                }}
              >
                <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
              </svg>
        </div> }
            <div className="invoices__header-button">
             
             </div>
        </div>
    )
}