import React, { useEffect, useState } from 'react';
import DatetPicker from 'react-datepicker';
import moment from 'moment';
import { Translate } from '../common/Translate';

export const TciDatePicker = props => {

    const { isSecond, startDate, setStartDate, baseDate, header } = props;

    const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
    const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz'];

    const sameMonth = baseDate && baseDate.getMonth() === startDate.getMonth() && baseDate.getFullYear() === startDate.getFullYear();

    const locale = {
        localize: {
            month: n => months[n],
            day: n => days[n]
        },
        formatLong: {}
    }

    useEffect(() => {

        const timeOut = isSecond ? 200 : 1;

        setTimeout(() => {
            const results = document.querySelectorAll('.react-datepicker__week--selected');
            if (results) {
                for (const result of results) {
                    result.classList.remove("react-datepicker__week--selected");
                }
            }
            const selectedDays = document.querySelectorAll('.react-datepicker__day--selected');

            for (const selectedDay of selectedDays) {
                const parent = selectedDay.closest('.react-datepicker__week');

                if (parent)
                    parent.classList.add("react-datepicker__week--selected");
            }
        }, timeOut);




    }, [startDate])


    return (


        <div className={`tci-date${(!isSecond ? ' first' : (sameMonth ? ' third' : ' second'))}`}>

            <h3 className="filter__date-header">
                <Translate>{header || 'StartDate'}</Translate>
            </h3>

            <span className="tci-date__span-date">{moment(startDate).format('DD/MM/YYYY')}</span>

            <DatetPicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                inline
                locale={locale}
                startDate={sameMonth ? baseDate : null}
                endDate={startDate}

            />

        </div>)
}