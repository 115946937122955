import React, { useState, useEffect } from 'react';
import {
    PieChart, Pie, Sector, Cell,
} from 'recharts';
import FormattedDecimal from '../common/FormattedDecimal';
import { Translate } from '../common/Translate';

const COLORS = ['#f88379', '#699de0', '#ffffc1', '#557c5c'];

const getTotal = data => {
    const total = data.reduce((a, b) => a + b.value, 0);
    return {
        name: '',
        value: total
    }
}

const EkoPieChart = props => {
    const { data, mainLabel, header } = props;
    const firstItem = data.length > 0 ? getTotal(data) : {};
    const [label, setLabel] = useState(firstItem.value);
    useEffect(() => {
        setLabel({ name: firstItem.name, value: firstItem.value, color: "#000000" });
    }, [firstItem.name])

    const setControlledLabel = (tagName, value, color) => {

        if (!mainLabel || !mainLabel.name) {
            setLabel({ name: tagName, value, color })
        }
    }

    return (
        <div className="generic-chart-container">
            <h3 className="generic-chart__header"><Translate>{header}</Translate></h3>
            <div className="generic-chart generic-chart__pie">

                <PieChart width={200} height={200} >
                    <Pie
                        startAngle={-270}
                        onMouseEnter={e => setControlledLabel(e.name, e.value, e.fill)}
                        onMouseLeave={e => {
                            if (e.name !== firstItem.name) {
                                setControlledLabel(firstItem.name, firstItem.value, "#000000");
                            }
                        }}
                        data={data}
                        innerRadius={88}
                        outerRadius={100}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        cursor="pointer"
                    >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={!entry.color ? COLORS[index % COLORS.length] : entry.color} />)
                        }
                    </Pie>
                </PieChart>
                <div className="generic-chart__text-container">
                    <span className="generic-chart__value" style={{ color: !mainLabel ? label.color : mainLabel.color }}> <FormattedDecimal text="" value={!mainLabel ? label.value : mainLabel.value} abb="$" className="generic-chart__value--formatted" /></span>
                    {(label.name || (mainLabel && mainLabel.name)) && <span className="generic-chart__text">{!mainLabel ? label.name : mainLabel.name}</span>}
                </div>
            </div>
        </div>
    );

}

export default EkoPieChart;