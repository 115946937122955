import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from '../common/Translate';
import { SortObject } from '../common/SortObject';
import { setOrder } from '../filter/duck/actions';
import { Link } from 'react-router-dom';

const SCREENNAME = 'Expense';

const ExpenseHeader = props => {

    const dispatch = useDispatch();

    const onLinkClick = (e, columnName) => {
        e.preventDefault();
        dispatch(setOrder(SCREENNAME, columnName));
    };

    return (
        <div className="expenses__header">
            <div className="expenses__header-image">

            </div>
            <div className="expenses__header-definition">
                <Link to="#" className="expenses__header-link" onClick={e => onLinkClick(e, 'ExpenseDescription')}>
                    <Translate>ExpenseDescription</Translate><SortObject screen={SCREENNAME} column='ExpenseDescription' />
                </Link>
            </div>
            <div className="expenses__header-date expenses__header-arrangement middle">
                <Link to="#" className="expenses__header-link" onClick={e => onLinkClick(e, 'ArrangementDate')}>
                    <Translate>ArrangementDate</Translate><SortObject screen={SCREENNAME} column='ArrangementDate' />
                </Link>
            </div>
            <div className="expenses__header-amount  middle">
                <Link to="#" className="expenses__header-link" onClick={e => onLinkClick(e, 'Remaining')}>
                    <Translate>RemainingAmount</Translate><SortObject screen={SCREENNAME} column='Remaining' />
                </Link>
            </div>
            <div className="expenses__header-button">
             
            </div>
        </div>
    )
}

export default ExpenseHeader;