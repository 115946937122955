import React from 'react';
import { Main } from "../app/main/Main";
import CurrentStatus from '../app/currentStatus/CurrentStatus';

const data = [
    { name: 'Gecikmis Fatura', value: 650, color: '#f88379' },
    { name: 'Vadesi Gelmemis Fatura', value: 300, color: '#699de0' },
];

const data2 = [
    { name: 'Gecikmis Fatura', value: 650, color: '#f88379' },
    { name: 'noname', value: 300, color: '#999593' },
];

const CurrentStatusPage = props => {

    return (<Main>
        <CurrentStatus />
    </Main>
    )
}

export default CurrentStatusPage; 