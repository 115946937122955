import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from '../common/Translate';
import { resetPickers, setFromPicker } from '../filter/duck/actions';

export const SimplePick = props => {

    const { setBothDates } = props;
    const date = new Date();
    const month = date.getMonth() % 3;

    const firstDate = useSelector(state => state.filter.firstDate);
    const secondDate = useSelector(state => state.filter.secondDate);
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const pickerDataName = useSelector(state => state.filter.pickerDataName);
    const dispatch = useDispatch();

    const thisMonth = {
        start: new Date(date.getFullYear(), date.getMonth(), 1),
        end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
    };
    const lastMonth = {
        start: new Date(date.getFullYear(), date.getMonth() - 1, 1),
        end: new Date(date.getFullYear(), date.getMonth(), 0)
    };
    const thisQuarter = {
        start: new Date(date.getFullYear(), date.getMonth() - month, 1),
        end: new Date(date.getFullYear(), date.getMonth() - month + 3, 0)
    };
    const lastQuarter = {
        start: new Date(date.getFullYear(), date.getMonth() - month - 3, 1),
        end: new Date(date.getFullYear(), date.getMonth() - month, 0)
    };
    const thisYear = {
        start: new Date(date.getFullYear(), 0, 1),
        end: new Date(date.getFullYear(), 11, 31)
    };
    const lastYear = {
        start: new Date(date.getFullYear() - 1, 0, 1),
        end: new Date(date.getFullYear() - 1, 11, 31)
    };
    const fn = (e, first, last) => {
        e.preventDefault();
        setBothDates(first, last);
    };

    useEffect(() => {
       

    }, [])

    return (
        <div className="filter-simple">
            <h3 className="filter__date-header">
                <Translate>SimplePick</Translate>
            </h3>
            <ul className="filter-simple__links">
                <li className="filter-simple__links-item">
                    <Link to="#" className="filter-simple__link" onClick={e => fn(e, thisMonth.start, thisMonth.end)}><Translate>ThisMonthLink</Translate></Link>
                </li>
                <li className="filter-simple__links-item">
                    <Link to="#" className="filter-simple__link" onClick={e => fn(e, lastMonth.start, lastMonth.end)}><Translate>LastMonthLink</Translate></Link>
                </li>
                <li className="filter-simple__links-item">
                    <Link to="#" className="filter-simple__link" onClick={e => fn(e, thisQuarter.start, thisQuarter.end)}><Translate>ThisQuarterLink</Translate></Link>
                </li>
                <li className="filter-simple__links-item">
                    <Link to="#" className="filter-simple__link" onClick={e => fn(e, lastQuarter.start, lastQuarter.end)}><Translate>LastQuarterLink</Translate></Link>
                </li>
                <li className="filter-simple__links-item">
                    <Link to="#" className="filter-simple__link" onClick={e => fn(e, thisYear.start, thisYear.end)}><Translate>ThisYearLink</Translate></Link>
                </li>
                <li className="filter-simple__links-item">
                    <Link to="#" className="filter-simple__link" onClick={e => fn(e, lastYear.start, lastYear.end)}><Translate>LastYearLink</Translate></Link>
                </li>
            </ul>
            <div className="filter-simple__buttons">
                <button className="filter-simple__simple-button filter-simple__simple-button--cancel" onClick={() => dispatch(resetPickers())}>
                    <Translate>Cancel</Translate>
                </button>
                <button className="filter-simple__simple-button filter-simple__simple-button--apply" onClick={() => dispatch(setFromPicker(firstDate, secondDate, pickerDataName, selectedFilterValues))}>
                    <Translate>Apply</Translate>
                </button>
            </div>
        </div>
    )
}

/*

onClickde value set etmek gerekeiyor. Ve

const onItemClick = () => {dispatch(setDateValue(selectedFilterValues, value, dataName)); setActive();};

*/