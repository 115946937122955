import React, { useEffect, useState } from 'react';
import { futureByExpenseType } from '../apis/expense';
import useStateIfMounted from '../hook/useStateIfMounted';
import Loading from "../common/Loading";
import StatusTable from "./StatusTable";

const ExpenseTypePayments = props => {

    const [data, setData] = useStateIfMounted([]);
    const [loading, setLoading] = useStateIfMounted(true);

    useEffect(() => {
        futureByExpenseType().then(result => setData(result.data)).finally(() => setLoading(false));
    }, []);

    return (
        loading ? <Loading className="medium" /> : <StatusTable data={data} datakey="expenseType" header="Gider Tipi Kirilimi" />
    )

}

export default ExpenseTypePayments;