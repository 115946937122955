import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from '../checkbox/Checkbox';
import { setFilterValue } from './duck/actions';

export const DropDownElement = props => {

    const { element, dataName } = props;
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const dispatch = useDispatch();
    const checkedChange = e => dispatch(setFilterValue(e, selectedFilterValues, dataName, element));

    return (
        <li className="selected-filter__item">
            <Checkbox title={element.label} checkChange={checkedChange} name={dataName} id={`${dataName}_${element.value}`} data/>
        </li>
    )

}
