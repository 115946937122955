import React from 'react';
import ExpenseType from '../app/expenseType/ExpenseType';
import { Main } from "../app/main/Main";

const ExpenseTypePage = props => {

    return (<Main>
        <ExpenseType />
    </Main>
    )
}

export default ExpenseTypePage;