import React from 'react';
import { BrowserRouter, Route, Switch, Prompt, browserHistory } from 'react-router-dom';
import { PageNotFoundPage } from './routes/PageNotFoundPage';
import { LoginPage } from './routes/LoginPage';
import { LandingPage } from './routes/LandingPage';
import { InvoiceListPage } from './routes/InvoviceListPage';
import InvoicePage from './routes/InvoicePage';
import PaymentPage from './routes/PaymentPage';
import ExpenseListPage from './routes/ExpenseListPage';
import ExpensePage from './routes/ExpensePage';
import ExpensePaymentPage from './routes/ExpensePaymentPage';
import ExpenseTypePage from './routes/ExpenseTypePage';
import ChartTestPage from './routes/ChartTestPage';
import CurrentStatusPage from './routes/CurrentStatusPage';
import BankActivityPage from './routes/BankActivityPage';
import AccountPage from './routes/AccountPage';
import ActivitySummaryPage from './routes/GroupedBankActivities';
import InvoiceReportPage from './routes/InvoiceReportPage';
import SalesPage from './routes/SalesPage';
import SalesExpensePage from './routes/SalesExpensePage';

export default () => {
  return (
    <BrowserRouter/*  history={browserHistory} */>
      <Switch>
        <Route path='/' exact component={LandingPage} />
        <Route path='/login' exact component={LoginPage} />
        <Route path='/invoice-list' exact component={InvoiceListPage} />
        <Route path='/expense-list' exact component={ExpenseListPage} />
        <Route path='/invoice/:invoiceId?' exact component={InvoicePage} />
        <Route path='/payment/:invoiceId?' exact component={PaymentPage} />
        <Route path='/expense/:expenseId?' exact component={ExpensePage} />
        <Route path='/expense-payment/:expenseId?' exact component={ExpensePaymentPage} />
        <Route path='/account' exact component={AccountPage} />
        <Route path='/expense-type' exact component={ExpenseTypePage} />
        <Route path='/chart-test' exact component={ChartTestPage} />
        <Route path='/current-status' exact component={CurrentStatusPage} />
        <Route path='/bank-activity' exact component={BankActivityPage} />
        <Route path='/activity-summary' exact component={ActivitySummaryPage} />
        <Route path='/report-invoice' exact component={InvoiceReportPage} />
        <Route path='/report-sales' exact component={SalesPage} />
        <Route path='/report-sales-expense' exact component={SalesExpensePage} />
        <Route component={PageNotFoundPage} />
      </Switch>
    </BrowserRouter>
  )
}