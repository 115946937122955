import { ListPayloads } from "../model/payloads";
import { list } from '../../apis/account';
import { result } from "lodash";

let waiting = {};

export const getData = async (setData) => {
    const payload = new ListPayloads();

    if (waiting.timeout) {
        clearTimeout(waiting.timeout);
        waiting.timeout = null;
    }

    if (!payload.accountName && !payload.identificationNumber)
        setData([]);

    await new Promise(resolve => {
        waiting.timeout = setTimeout(() => {
            list(payload).then(result => {
                setData(result);
                resolve(result)
            });
            waiting.timeout = null;
        }, 500)
    })

}