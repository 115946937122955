import React, { useContext, useRef } from 'react'
import InvoviceContext from '../contexts/invoice-context';
import { Translate } from '../common/Translate';
import { getNumeric } from '../common/comops';
import { setInsurerInstallments, setQuantityInstallments } from './duck/actions';
import { useDispatch } from "react-redux";
import Installments from './Installments';

const InvoiceInstallments = props => {

    const { data, insurer, setData, invoiceId, arrangement,genel } = useContext(InvoviceContext);
    const adetRef = useRef();
    const dispatch = useDispatch();

    return (
        <>
            <div className="invoice-general__row narrow borderless">
                <div className="invoice-general__label">
                    <svg className="invoice-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                        <use xlinkHref="../img/sprite.svg#icon-calendar"></use>
                    </svg>
                    <span className="invoice-general__label--label">
                        <Translate>Taksitler</Translate>
                    </span>
                </div>
                <div className="invoice-general__input double-flex">
                    <input type="text" className="invoice-general__input--center" onChange={getNumeric} ref={adetRef} placeholder="Taksit Sayısı" defaultValue={data.numberOfInstallment}></input>
                    <button className="btn" onClick={() => dispatch(setQuantityInstallments(invoiceId, parseInt(adetRef.current.value), arrangement, data, setData))}><Translate>Özel Taksitlendirme</Translate></button>
                    <button className="btn" onClick={() => dispatch(setInsurerInstallments(invoiceId, insurer, arrangement, data, setData,genel))}><Translate>Standart Taksit</Translate></button>
                </div>
                <div className="invoice-general__buttons">

                </div>
            </div>
            <Installments />
        </>
    )

}


export default InvoiceInstallments;