import * as comops from '../common/comops';
import urls from '../constants/urls';

export const list = async (payload) => {

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.ACCOUNT_LIST_SEARCH, options);

    if (!res.isSuccess || !res.data)
        res = [];

    return res.data;
}

export const save = async (payload) => {

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.ACCOUNT_SAVE, options);

    return res;
}