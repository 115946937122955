import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CreateComponent from './CreateComponent';
import { getData } from './duck/operations';
import ListComponent from './ListComponent';
import { saveAll } from './duck/actions';

const AccountContainer = props => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const dispatch = useDispatch();

    const onChange = () => {
        setLoading(true);
        getData(setData).finally(() => setLoading(false));
    }

    const saveAccount = () => dispatch(saveAll(setSaving));

    return (
        <div className="account-container">
            <CreateComponent onChange={onChange} saving={saving} saveAccount={saveAccount} />
            <ListComponent data={data} loading={loading} />
        </div>
    )
}


export default AccountContainer;