import React from 'react';
import urls from '../constants/urls';
import { SelectAsync } from './SelectAsync.js';

const AccountAsync = props => {

    return <SelectAsync
        placeholder={props.placeholder || 'Firma Seçiniz'}
        message="Firma kaydi yok"
        url={urls.ACCOUNT_SEARCHLIST}
        fn={props.setAccount}
        value={props.value}
    />
}

export default AccountAsync;