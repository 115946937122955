import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import dictionary from '../constants/dictionary';
import { useSelector } from 'react-redux';
import ExPaySourceSelect from '../select/ExPaySourceSelect';
import { Translate } from '../common/Translate';
import RadioButton from '../radioButton/RadioButton';
import businessKeys from '../constants/businessKeys';
import DecimalNumber from '../common/DecimalNumber';
import { setElementValue } from '../common/comops';
import TciSimpleDate from '../datePicker/TciSimpleDate';
import { saveExchange } from './duck/actions';
import Loading from '../common/Loading';

const Exchange = props => {

    const { data, close } = props;
    const [sourceAccount, setSourceAccount] = useState(null);
    const [targetAccount, setTargetAccount] = useState({});
    const [buySell, setBuySell] = useState('buy');
    const [saving, setSaving] = useState(false);
    const [bank, setBank] = useState('');
    const [date, setDate] = useState(new Date());
    const changeRadio = e => {
        if (e.target.checked) {
            setBuySell(e.target.dataset.val);
            setSourceAccount(null);
            setTargetAccount(null);
        }
    };
    const currencies = useSelector(state => state.main.currencies);
    const dispatch = useDispatch();

    useEffect(() => {
        setBank(sourceAccount ? sourceAccount.stringHelper3 : '');
    }, [sourceAccount])

    useEffect(() => {
        if (!targetAccount) {
            setElementValue(`#${businessKeys.EXCHANGE_RATE}`, 0);
            return;
        }
        var code = buySell === 'buy' ? targetAccount.stringHelper2 : sourceAccount.stringHelper2;
        const curr = currencies.find(item => item.label === code);
        if (!curr) return;

        setElementValue(`#${businessKeys.EXCHANGE_RATE}`, curr.decimalHelper);
    }, [targetAccount])

    const saveExchangeRate = e => {
        e.preventDefault();
        if (saving) return;
        setSaving(true);
        dispatch(saveExchange(date, sourceAccount, targetAccount)).then(res => {
            if (res.isSuccess)
                close();
        }).finally(() => setSaving(false))
    }

    return (<div className="exchange-form-container">
        <form className="exchange-form">
            <div className="activity-form__row close-row">
                <span className="activity-form__bank-info"><Translate>{dictionary.EXCHANGE_PAGE_HEADER}</Translate></span>
                <span className="activity-form__close" onClick={close}>&times;</span>
            </div>
            <div className="exchange-form__row  sell-buy">
                <RadioButton id="exchange-form-buy" name="exchange-form-radio" val='buy' value="Alış" checkChange={changeRadio} />
                <RadioButton id="exchange-form-sell" name="exchange-form-radio" value="Satış" val='sell' checkChange={changeRadio} />
            </div>
            <div className="exchange-form__row">
                <label className="exchange-form__label"><Translate>{dictionary.SOURCE_ACCOUNT}</Translate></label>
                <ExPaySourceSelect className="exchange-form__input" value={sourceAccount} screen='exchange' type={buySell === 'buy' ? 'tl' : 'for'} setValue={setSourceAccount} />
                <label className="exchange-form__label"><Translate>{dictionary.TARGET_ACCOUNT}</Translate></label>
                <ExPaySourceSelect bank={bank} className="exchange-form__input" value={targetAccount} screen='exchange' type={buySell === 'buy'/* ? 'for' : 'tl'*/} setValue={setTargetAccount} />
            </div>
            <div className="exchange-form__row">
                <label className="exchange-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_AMOUNT}</Translate></label>
                <DecimalNumber className="exchange-form__input" pre={2} id={businessKeys.EXCHANGE_AMOUNT} defaultValue={0} />
                <label className="exchange-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_TARGETAMOUNT}</Translate></label>
                <DecimalNumber className="exchange-form__input" pre={2} id={businessKeys.EXCHANGE_TARGET_AMOUNT} defaultValue={0} />
            </div>
            <div className="activity-form__row">
                <label className="activity-form__label"><Translate>{dictionary.ACTIVITY_ACTIVITY_DATE}</Translate></label>
                <TciSimpleDate className="activity-form__input" startDate={date} setStartDate={setDate} />
                <label className="activity-form__label"></label>
                <div className="activity-form__input"></div>
            </div>
            <div className="exchange-form__row button-row">
                <button className="exchange-form__button btn" onClick={saveExchangeRate}>{saving ? <Loading /> : <Translate>{dictionary.GENERAL_KAYDET}</Translate>}</button>
                <button className="exchange-form__button btn" onClick={close}>{saving ? <Loading /> : <Translate>{dictionary.GENERAL_KAPAT}</Translate>}</button>
            </div>
        </form>
    </div>)

}

export default Exchange;