import React from 'react';
import { useSelector } from 'react-redux';
import { currencies } from '../apis/parameter';
import SelectSync from './SelectSync';

const CurrencySelect = props => {

    const options = useSelector(state => state.main.currencies);

    return (
        <SelectSync
            className={props.className}
            changeFn={props.setValue}
            optionsFn={currencies}
            value={props.value}
            placeholder='Currency'
            options={options}
        />
    )
}

export default CurrencySelect;