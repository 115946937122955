import * as comops from '../common/comops'
import urls from '../constants/urls'

const generic = async (url) => {

    const options = {
        method: 'GET',
        mode: 'cors'
    };

    const res = await comops.fetchAsync(url, options);

    if (!res.isSuccess || !res.data)
        res = { data: [] };

    return res;
}

export const invoice = async () => {

    const res = await generic(urls.FILTER_INVOICE);

    return res;
}

export const bankingActivity = async () => {

    const res = await generic(urls.FILTER_BANKINGACTIVITY);

    return res;
}

export const expense = async () => {

    const res = await generic(urls.FILTER_EXPSENSE);

    return res;
}