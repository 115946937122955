import React, { useEffect } from 'react';
import { validatetoken } from '../apis/user';
import { useHistory } from "react-router-dom";

export const Landing = props => {

    const history = useHistory();

    useEffect(() => {
        validatetoken().then(result => {

            if (result.isSuccess)
                history.push('/invoice-list');
            else
                history.push('/login');
        }).catch(error => { history.push('/login'); })
    }, [])

    return <> </>
}