import React, { useContext, useEffect, useRef } from 'react';
import CurrencySelect from '../select/CurrencySelect';
import ExpenseContext from '../contexts/expense-context';  
import { Checkbox } from '../checkbox/Checkbox';
import DecimalNumber from '../common/DecimalNumber';
import { useSelector } from 'react-redux';
import businessKeys from '../constants/businessKeys';

const ExpenseCurrency = props => {

    const { currency, setCurrency, data } = useContext(ExpenseContext);
    const refExchangeRate = useRef();
    const currencies = useSelector(state => state.main.currencies);
    let rate = data.exchangeRate;
    if (!rate && typeof currency === 'number') {
        const curr = currencies.find(t => t.value == currency);
        rate = curr && curr.decimalHelper;
    } else if (!rate && currency) {
        rate = currency.decimalHelper;
    }

    useEffect(() => {
        if (data.isReturn) {
            const element = document.querySelector("#idexpense");
            if (element)
                element.setAttribute("checked", "true");
        }
    }, [data.isReturn])

    useEffect(() => {
        if (data.isAp) {
            const element = document.querySelector("#idexpense-ap");
            if (element)
                element.setAttribute("checked", "true");
        }
    }, [data.isAp])


    return (
        <div className="expense-general__row narrow">
            <div className="expense-general__label">

            </div>
            <div className="expense-general__input double-flex">
                <CurrencySelect value={currency} setValue={value => setCurrency(value)} />
                <DecimalNumber id={businessKeys.EXPENSE_EXCHANGE_RATE}
                    className="generic-detail__exchange-rate middle"
                    ref={refExchangeRate} pre={4}
                    defaultValue={(rate ? rate.toString() : '1.0000')}
                />
            </div>
                
            <div style={{ display: 'flex', flexDirection: 'column'}} className="expense-general__buttons">
               <Checkbox id="expense" name="expense-return" title="Iade" /> 
               <Checkbox id="expense-ap" name="expense-ap" title="AP" />                 
            </div>       
                     
         
        </div>
    )
}

export default ExpenseCurrency;