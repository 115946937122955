import React, { memo } from 'react';
import ListComponentHeader from './ListComponentHeader';
import ListItem from './ListItem';
import Loading from '../common/Loading';

const ListComponent = memo(props => {

    const { data, loading } = props;
    return (<div className="list-account">
        <ListComponentHeader />
        {loading ? <Loading className="medium" /> : data.map(item => <ListItem item={item} key={item.id} />)}
    </div>)
})

export default ListComponent;