import { showConfirmation, showMessage } from '../../modal/duck/actions';
import { save } from '../../apis/account';
import { ListPayloads } from '../model/payloads';

export const saveAll = (setSaving) => async dispatch => {

    setSaving(true);

    const payload = new ListPayloads();

    const result = await save(payload);

    setSaving(false);

    dispatch(showMessage(result.data.message));
}