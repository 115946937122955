export default {
    MAIN_SETDICTIONARY: 'MAIN_SETDICTIONARY',
    MAIN_SETCURRENCIES: 'MAIN_SETCURRENCIES',
    MAIN_SETTAXRATES: 'MAIN_SETTAXRATES',
    MAIN_SETEXPENSETYPES: 'MAIN_SETEXPENSETYPES',
    MAIN_SETEXPENSEMAINTYPES: 'MAIN_SETEXPENSEMAINTYPES',
    MAIN_SETVENDORS: 'MAIN_SETVENDORS',
    MAIN_SETUSERS: 'MAIN_SETUSERS',
    MAIN_SETCULTURE: 'MAIN_SETCULTURE',
    MAIN_SETEXPENSEPAYMENTSOURCE: 'MAIN_SETEXPENSEPAYMENTSOURCE',
    MAIN_ACTIVITYTYPES: 'MAIN_ACTIVITYTYPES',
    MAIN_PERIODS: 'MAIN_PERIODS'
}