import types from './types';

export const setDictionary = tciDictionary => {

    return {
        type: types.MAIN_SETDICTIONARY,
        tciDictionary
    }
}

export const setTaxRates = taxRates => {

    return {
        type: types.MAIN_SETTAXRATES,
        taxRates
    }
}

export const setCurrencies = currencies => {

    return {
        type: types.MAIN_SETCURRENCIES,
        currencies
    }
}

export const setExpenseTypes = expenseTypes => {

    return {
        type: types.MAIN_SETEXPENSETYPES,
        expenseTypes
    }
}

export const setExpenseMainTypes = expenseMainTypes => {

    return {
        type: types.MAIN_SETEXPENSEMAINTYPES,
        expenseMainTypes
    }
}



export const setVendors = vendors => {

    return {
        type: types.MAIN_SETVENDORS,
        vendors
    }
}

export const setUsers = users => {

    return {
        type: types.MAIN_SETUSERS,
        users
    }
}

export const setExpensePaymentSource = expensePaymentSource => {

    return {
        type: types.MAIN_SETEXPENSEPAYMENTSOURCE,
        expensePaymentSource
    }
}

export const setCulture = culture => {

    localStorage.setItem('culture', culture);

    return {
        type: types.MAIN_SETCULTURE,
        culture
    }
}

export const setActivityTypes = activityTypes => {

     return {
        type: types.MAIN_ACTIVITYTYPES,
        activityTypes
    }
}

export const setPeriods = periods => {

    return {
       type: types.MAIN_PERIODS,
       periods
   }
}