export class Period {

    static getPeriods(thisYear, type) {
        if (!type) return [];
        const explanations = Period.getExplanations(type);

        const items = Array(3).fill()
            .map((item, index) => ({ year: (thisYear - 2 + index), list: explanations }))
            .flatMap(item => item.list.map(subItem => ({ value: `${item.year}${subItem}`, label: `${item.year}${subItem}` })));
        return items;
    }

    static getExplanations(type) {
        const culture = localStorage.getItem('culture') || 'tr-Tr';
        const monthly = Array(12).fill().map((item, index) => `-${(index + 1).toString().padStart(2, '0')}`);
        switch (type) {
            case 1:
                return monthly
            case 3:
                return culture !== 'tr-Tr' ? ['-1.Qtr', '-2.Qtr', '-3.Qtr', '-4.Qtr'] : ['-1.Ceyrek', '-2.Ceyrek', '-3.Ceyrek', '-4.Ceyrek'];
            case 6:
                return culture !== 'tr-Tr' ? ['-1.Half', '-2.Half'] : ['-1.Yari Yil', '-2.Yari Yil'];
            case 12:
                return [''];
            default:
                return monthly;
        }
    }
}

