import React, {useEffect} from 'react';
import { Translate } from '../common/Translate';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectFilter } from './duck/actions';

export const FilterList = props => {

    const { filters, active, setActive } = props
    const className = `filter-list${active ? ' active' : ''}`
 
    const activeFilter = useSelector(state => state.filter.activeFilter);
    const dispatch = useDispatch();
    const onFilterSelect = item => dispatch(selectFilter(item, props.textFieldName));


    useEffect(() => {
        if (activeFilter !== 'main-filter' && active) {
            setActive(false);
        }

    }, [activeFilter])

    return (

        <div className={className}>
            <ul className="filter-list__list">
                {filters.map(item => {

                    return (<li className="filter-list__item" key={item.dataName} onClick={() => onFilterSelect(item)}>
                        <Link to="#" className="filter-list__item-text"  >
                            <Translate>{item.displayName}</Translate>
                        </Link>
                    </li>)
                })

                }
            </ul>
        </div>

    )
}