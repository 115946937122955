import types from './types';
import moment from 'moment';
import businessKeys from '../../constants/businessKeys';

export const selectFilter = (filter, textFieldName) => (dispatch, getState) => {

    let changed = false;
    let getValues = false;
    const { selectedFilters, selectedFilterValues:  selectedValues } = getState().filter;

    const textElement = document.querySelector("#filter-text");
    const textFilter = selectedFilters.find(f => f.dataName === textFieldName);
    const constValue = textElement ? textElement.value : '';

    let filters = [...selectedFilters];
    let values = [...selectedValues];

    if (textFilter && textFieldName) {
        filters = filters.filter(f => f.dataName !== textFieldName);
        values = values.filter(f => f.dataName !== textFieldName);
        changed = true; // aslinda tam değismeyi göstermiyor
        getValues = true;
    }
    if (constValue && textFieldName) {
        var item = {
            dataName: textFieldName,
            type: businessKeys.CONTAINS,
            selectedValues: [constValue]
        };
        filters.push(item);
        values.push(item);
        changed = true; // aslinda tam değismeyi göstermiyor, son state doğru;
        getValues = true;
    }

    if (textFilter && textFilter.selectedValues[0] === constValue) {
        changed = false; //eğer zaten bir text varsa ve ayniysa yukarda changed bosu bosuna set ediliyor
        getValues = false;
    }

    if (filter) {
        const checkSelected = selectedFilters.find(f => f.dataName === filter.dataName);
        if (!checkSelected) { filters.push(filter); changed = true; }
    }
    if (!changed) return;
    dispatch(setFilterAndValues(filters, values, getValues, false));
}

export const removeFilter = (selectedFilters, filter) => dispatch => {

    const filters = [...selectedFilters];

    const newSelected = filters.filter(f => f.dataName !== filter.dataName);

    dispatch(setFilter(newSelected));
}

export const initializeFilter = (currentPage, screen) => {
    return {
        type: types.FILTER_INITIALIZE,
        currentPage, 
        screen
    }
}

export const setFilterActive = (status) => {
    return {
        type: types.FILTER_TOGGLEACTIVE,
        status
    }
}


export const setDateValue = (selectedFilterValues, value, dataName) => dispatch => {
    let filters = [...selectedFilterValues];
    filters = filters.filter(item => item.dataName !== dataName);
    const dateFilter = { type: 'DateTime', dataName, selectedValues: [value] };
    filters.push(dateFilter);
    dispatch(setValues(filters));
}


export const setFilterValue = (event, selectedFilterValues, dataName, element) => dispatch => {


    let filters = [...selectedFilterValues];



    const isChecked = event.target.checked;
    let selectedFilter = filters.find(item => item.dataName === dataName);
    filters = filters.filter(item => item.dataName !== dataName);
    let selectedValues = [];

    if (isChecked) {
        if (!selectedFilter) {
            selectedValues.push(element.value);
            selectedFilter = { type: 'Dropdown', dataName, selectedValues: selectedValues };
        } else {
            selectedValues = [...selectedFilter.selectedValues];
            selectedValues.push(element.value);
            selectedFilter = { ...selectedFilter, selectedValues: [...new Set(selectedValues)] }
        }
    } else {
        if (selectedFilter) {
            selectedValues = [...selectedFilter.selectedValues].filter(t => t !== element.value);
            selectedFilter = { ...selectedFilter, selectedValues: selectedValues }
        }
    }

    if (selectedValues.length > 0)
        filters.push(selectedFilter);

    dispatch(setValues(filters))
}


export const setDates = (firstDate, secondDate) => {
    if (firstDate > secondDate) {
        firstDate = secondDate;
    }
    return {
        type: types.FILTER_SETDATES,
        firstDate,
        secondDate
    }
}

export const setPickerActive = (active) => {
    return {
        type: types.FILTER_PICKERACTIVE,
        active
    }
}

export const setPickerDataName = (dataName) => {
    return {
        type: types.FILTER_PICKERDATANAME,
        dataName
    }
}


export const resetPickers = () => {
    return {
        type: types.FILTER_RESETPICKER,
        dataName: '',
        active: false
    }
}

export const setFromPicker = (firstDate, secondDate, pickerDataName, selectedFilterValues) => dispatch => {
    const value = `${moment(firstDate).format('YYYYMMDD')}-${moment(secondDate).format('YYYYMMDD')}`;
    dispatch(setDateValue(selectedFilterValues, value, pickerDataName));
    dispatch(resetPickers());
}

export const changeActive = (dataName) => {
    return {
        type: types.FILTER_CHANGEACTIVE,
        dataName
    }
}

export const setGetValues = (getValues) => {
    return {
        type: types.FILTER_GETVALUE,
        getValues
    }
}

export const setOrder = (sortScreen, sortColumn) => {
    return {
        type: types.FILTER_SETORDER,
        sortScreen,
        sortColumn
    }
}


export const setPageNumber = (screen, pageNumber) => {

    return {
        type: types.FILTER_PAGENUMBER,
        screen,
        pageNumber
    }
}

const setFilter = (selectedFilters) => {

    return {
        type: types.FILTER_SETSELECTEDFILTER,
        selectedFilters
    }
}


const setValues = (selectedFilterValues) => {
    return {
        type: types.FILTER_CHANGESELECTEDVALUES,
        selectedFilterValues,
        getValues: true
    }
}

const setFilterAndValues = (selectedFilters, selectedValues, getValues, status) => {
    return {
        type: types.FILTER_SETSELECTEDFILTERANDVALUE,
        selectedFilters,
        selectedValues,
        getValues,
        status
    }
}