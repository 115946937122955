import React, { useContext } from 'react';
import FormattedDecimal from '../common/FormattedDecimal';
import { Translate } from '../common/Translate';
import InvoviceContext from '../contexts/invoice-context';

const InvoiceTotalPart = props => {
    const { data, active, showEmpty } = useContext(InvoviceContext);

    return (
        <div className="invoice-total">

            <div className="invoice-total__row">
                <div></div>
                <div className="invoice-total__value-container">
                    <span className="invoice-total__value-label">
                       <Translate>TotalLabel</Translate>
                    </span>
                    <FormattedDecimal text="" value={data.invoiceTotal} abb={data.currencyAbbreviation} className="invoice-total__value" />
                </div>
            </div>
            <div className="invoice-total__row">
                <div></div>
                <div className="invoice-total__value-container">
                    <span className="invoice-total__value-label">
                        <Translate>TaxLabel</Translate>
                    </span>
                    <FormattedDecimal text="" value={data.taxTotal} abb={data.currencyAbbreviation} className="invoice-total__value" />
                </div>
            </div>
            <div className="invoice-total__row">
                <div></div>
                <div className="invoice-total__value-container">
                    <span className="invoice-total__value-label">
                      <Translate>GrandLabel</Translate>   
                    </span>
                    <FormattedDecimal text="" value={data.invoiceGrandTotal} abb={data.currencyAbbreviation} className="invoice-total__value" />
                </div>
            </div>

        </div>
    )
}

export default InvoiceTotalPart;