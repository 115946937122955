import React, { memo } from 'react';

const Loading = memo(props => {

    const className = `basic-form__icon icon icon--${props.className || 'small'} gear basic-form__icon--${props.className || 'small'}`;

    return (
        <div className="loading-container">
            <svg className={className}>
                <use xlinkHref="../img/sprite.svg#icon-spinner"></use>
            </svg>
        </div>)
})

export default Loading;