import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { list, excelExport,deleteCache } from "../apis/invoice";
import { Filter } from "../filter/Filter";
import { invoice } from "../apis/filter";
import { Invoices } from "./Invoices";
import {
  setGetValues,
  setPageNumber,
  initializeFilter,
} from "../filter/duck/actions";
import Pagination from "../common/Pagination";
import { Translate } from "../common/Translate";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import useStateIfMounted from "../hook/useStateIfMounted";
import Loading from "../common/Loading";

const SCREENNAME = "Invoice";


const InvoiceButtons = (props) => {
  return (

    
    <>

    
      <div className="filter-button">
        <Link to="/invoice/0" className="btn btn-filter btn-link">
          <Translate>CreateNewInvoice</Translate>
        </Link>
      </div>
      <div className="filter-button padded">
        <svg
          className="icon-inner"
          onClick={() => {
            excelExport(props.filter);
          }}
        >
          <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
        </svg>
      </div>
    </>
  );
};

export const InvoiceList = (props) => {
  let selectedFilterValues = useSelector(
    (state) => state.filter.selectedFilterValues
  );
  const sort = useSelector((state) => state.filter.sort);
  const page = useSelector((state) => state.filter.page);
  const [data, setData] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(false);
  const getValues = useSelector((state) => state.filter.getValues);
  const dispatch = useDispatch();
  const sortPayload = sort.screen === SCREENNAME ? sort : {};
  const [pageCount, setPageCount] = useStateIfMounted(1);

  const loadData = () => {
    dispatch(setGetValues(false));
    setLoading(true);
    list(selectedFilterValues, sortPayload, page.pageNumber || 0)
      .then((result) => {
        setData(result.data.list);
        setPageCount(result.data.pageCount);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (getValues) {
      loadData();
    }
  }, [getValues]);

  useEffect(() => {
    const currentPage = page && SCREENNAME === page.screen ? page : null;
    dispatch(initializeFilter(currentPage, SCREENNAME));
  }, []);

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      await deleteCache(); // Call the deleteCache function to clear the cache
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="invoice-list">
      <Filter
        fn={invoice}
        buttons={<InvoiceButtons filters={selectedFilterValues} />}
        textFieldName="InvoiceDefinition"
      />
      {loading ? (
        <Loading className="big" />
      ) : (
        <>
          <Invoices data={data} loading={loading} />
          <div className="invoice-footer invoice-paginition">
            <Pagination
              pageCount={pageCount}
              pageNumber={page.pageNumber}
              setPage={(pageNumber) =>
                dispatch(setPageNumber(SCREENNAME, pageNumber))
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
