import React, { memo } from 'react';
import { Translate } from '../common/Translate';
import { formatNumber } from 'accounting';

const TotalPart = memo(props => {

    const { data, abb } = props;
    const sum = data.reduce((a, item) => a + item.amount, 0);

    return <div className="payment-total">
        <div className="payment-total__row">
            <span className="payment-total__label">
                <Translate>Toplam</Translate>
            </span>
            <span className="payment-total__value">
                    {formatNumber(sum, 2)} {abb}
            </span>
        </div>
    </div>
})

export default TotalPart;