import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '../common/Translate';

export const Reports = props => {

    const { subAktif, setSubAktif } = props;

    return (
        <div className="first-level__sub-items">
            <ul className="second-level">
                <li className="second-level__item">
                    <svg className="icon second-level__icon">
                        <use xlinkHref="../img/sprite.svg#icon-list"></use>
                    </svg>
                    <Link className={`second-level__definition${(subAktif === 'gelirraporu' ? ' active' : '')}`}  to="/report-invoice" onClick={e => { setSubAktif('gelirraporu'); }}>
                        <span className="second-level__span">
                            <Translate>Tahsilat Raporu</Translate>
                        </span>
                    </Link>
                </li>
                <li className="second-level__item">
                    <svg className="icon second-level__icon">
                        <use xlinkHref="../img/sprite.svg#icon-list"></use>
                    </svg>
                    <Link className={`second-level__definition${(subAktif === 'satisraporu' ? ' active' : '')}`}  to="/report-sales" onClick={e => { setSubAktif('satisraporu'); }}>
                        <span className="second-level__span">
                            <Translate>Satış Raporu</Translate>
                        </span>
                    </Link>
                </li> 
                <li className="second-level__item">
                    <svg className="icon second-level__icon">
                        <use xlinkHref="../img/sprite.svg#icon-list"></use>
                    </svg>
                    <Link className={`second-level__definition${(subAktif === 'gelirgiderraporu' ? ' active' : '')}`}  to="/report-sales-expense" onClick={e => { setSubAktif('gelirgiderraporu'); }}>
                        <span className="second-level__span">
                            <Translate>Gelir Gider Raporu</Translate>
                        </span>
                    </Link>
                </li> 
            </ul>
        </div>
    )
}