import React, { useState, useContext, useRef } from 'react';
import ExpensePaymentContext from '../contexts/expense-payment-context';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TciSimpleDate from '../datePicker/TciSimpleDate';
import DecimalNumber from '../common/DecimalNumber';
import { Translate } from '../common/Translate';
import { unformat, formatNumber } from 'accounting';
import { showConfirmation } from '../modal/duck/actions';
import ExPaySourceSelect from '../select/ExPaySourceSelect';
import { selectValue } from '../common/comops';

const ExpensePaymentRows = props => {
    const { data, active, showEmpty } = useContext(ExpensePaymentContext);
    const [sourceId, setSourceId] = useState(0);
    const expensePaymentSource = useSelector(state => state.main.expensePaymentSource);
    return (<>
        {data.map(item =>
            item.id === active ? <ActiveRow key={`key_${item.id}`} item={item} data={data} sourceId={sourceId} setSourceId={setSourceId} />
                : <PasifRow  key={`key_${item.id}`} item={item} data={data} sourceId={sourceId} setSourceId={setSourceId} expensePaymentSource={expensePaymentSource} />
        )}
        {(active === -1 && showEmpty) ? <ActiveRow sourceId={sourceId} setSourceId={setSourceId} key={`key__1`} item={{}} abb={""} data={data} /> : (active === -1 && <NewLineButton />)}
    </>)

}

const ActiveRow = props => {

    const { data, setActive, setShowEmpty, setData, setTotal, expenseId } = useContext(ExpensePaymentContext);
    const refDescription = useRef();
    const refAmount = useRef();
    const [date, setDate] = useState(new Date());
    const { item, sourceId, setSourceId } = props;
    const getItem = () => {

        const min = Math.min(data.map(s => s.id));

        return {
            ...item,
            amount: unformat(refAmount.current.value),
            expensePaymentDescription: refDescription.current.value,
            vade: date,
            id: item.id || (min > -10 ? -10 : min - 1),
            expenseId,
            sourceId: selectValue(sourceId)
        }
    }

    return (
        <div className="generic-detail__row double-flex">
            <div className="generic-detail__td generic-detail__td--payment-description">
                <input type="text" ref={refDescription} defaultValue={item.expensePaymentDescription}></input>
            </div>
            <div className="generic-detail__td generic-detail__td--payment-date">
                <TciSimpleDate startDate={date} setStartDate={setDate} />
            </div>
            <div className="generic-detail__td generic-detail__td--payment-amount">
                <DecimalNumber className="generic-detail__amount" ref={refAmount} pre={2} defaultValue={item.amount || 0} />
            </div>
            <div className="generic-detail__td generic-detail__td--payment-source w-select">
                <ExPaySourceSelect value={sourceId} setValue={setSourceId} />
            </div>
            <div className="generic-detail__td generic-detail__td--payment-buttons">
                <div className="generic-detail__button generic-detail__button--checkmark"
                    onClick={() => changeData(getItem(), setData, data, setActive, setShowEmpty, setTotal)}>
                    <svg className="icon icon-checkmark">
                        <use xlinkHref="../img/sprite.svg#icon-checkmark"></use>
                    </svg>
                </div>
                <div className="generic-detail__button generic-detail__button--cross"
                    onClick={() => { setActive(-1); setShowEmpty(false); }}>
                    <svg className="icon icon-cross">
                        <use xlinkHref="../img/sprite.svg#icon-cross"></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}


const PasifRow = props => {

  
    const { item, expensePaymentSource, setSourceId } = props;
    const { setActive, setShowEmpty, setData, data, setTotal, abbreviation } = useContext(ExpensePaymentContext);
    const dispatch = useDispatch();
    const deleteRow = () =>
        dispatch(showConfirmation("DoYouWantToDelete", () => deleteItem(item.id, setData, data, setActive, setShowEmpty, setTotal)));
    const exPaySource = expensePaymentSource.find(src => src.value == item.sourceId);
    const exPaySourceText = exPaySource ? exPaySource.label : '';

    return (
        <div className="generic-detail__row double-flex">
            <div className="generic-detail__td generic-detail__td--payment-description">
                <span className="generic-detail__td--text"> {item.expensePaymentDescription}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--payment-date">
                <span className="generic-detail__td--text">{moment(item.vade).format('DD/MM/YYYY')}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--payment-amount">
                <span className="generic-detail__td--text">{formatNumber(item.amount, 2)} {abbreviation}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--payment-source">
                <span className="generic-detail__td--text">{exPaySourceText}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--payment-buttons">
                <div className="generic-detail__button generic-detail__button--plus" onClick={() => { setActive(item.id); setSourceId(item.sourceId) }}>
                    <svg className="icon icon-plus">
                        <use xlinkHref="../img/sprite.svg#icon-plus"></use>
                    </svg>
                </div>
                <div className="generic-detail__button generic-detail__button--cross"
                    onClick={deleteRow}>
                    <svg className="icon icon-cross">
                        <use xlinkHref="../img/sprite.svg#icon-minus"></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}

const NewLineButton = props => {
    const { showEmpty, setShowEmpty } = useContext(ExpensePaymentContext);
    return <div className="generic-detail__row borderless">
        <button className="btn-empty" onClick={() => setShowEmpty(!showEmpty)}>
            <Translate>NewLine</Translate>
            <svg className="icon icon-plus">
                <use xlinkHref="../img/sprite.svg#icon-plus"></use>
            </svg>
        </button>
    </div >
}


const changeData = (item, setData, data, setActive, setShowEmpty, setTotal) => {

    const newItems = [...data].filter(t => t.id !== item.id);
    const orderedList = [...newItems, item].sort((a, b) => a.id > 0 && a.id < b.id ? -1 : 1);

    setData(orderedList);
    setNewData(orderedList, setActive, setShowEmpty, setTotal);
}

const setNewData = (orderedList, setActive, setShowEmpty, setTotal) => {
    const total = orderedList.reduce((agg, item) => { return agg + (item.amount) }, 0)

    setTotal(total);
    setActive(-1);
    setShowEmpty(false);
}

const deleteItem = (id, setData, data, setActive, setShowEmpty, setTotal) => {
    const newItems = [...data].filter(t => t.id !== id);
    setData(newItems);
    setNewData(newItems, setActive, setShowEmpty, setTotal);
}


export default ExpensePaymentRows;