import React from 'react';
import { Main } from "../app/main/Main";
import InvoiceReport from '../app/invoiceReport/InvoiceReport';

const InvoiceReportPage = props => {

    return (<Main>
    <InvoiceReport/>
    </Main>
    )
}

export default InvoiceReportPage;