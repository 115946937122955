import { save } from '../../apis/expensePayment';
import { showMessage } from '../../modal/duck/actions';

export const saveAll = (payload) => async dispatch => {

    const res = await save(payload);

    const message = res.data.message || 'SaveSucessful';

    dispatch(showMessage(message));
}