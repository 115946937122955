import React from 'react';
import { useSelector } from 'react-redux';
import businessKeys from '../constants/businessKeys';
import { DropDownFilter } from './DropDownFilter';
import { DateTimeFilter } from './DateTimeFilter';

export const GenericFilter = props => {

    const selectedFilters = useSelector(state => state.filter.selectedFilters);

    return (<>{
        selectedFilters.map((item, index) => {
            if (item.type === businessKeys.CONTAINS) return;
            return item.type === businessKeys.DATETIME ?
                <DateTimeFilter key={item.dataName} item={item} index={index + 1} /> : <DropDownFilter key={item.dataName} item={item} index={index + 1} />
        })}
    </>)

}