export default {
    enUs: 'en-Us',
    trTr: 'tr-Tr',
    DROPDOWN: 'DropDown',
    DATETIME: 'DateTime',
    CONTAINS: 'Contains',
    INVOICE_EXCHANGE_RATE: 'invovice-exchange-rate',
    EXPENSE_EXCHANGE_RATE: 'expense-exchange-rate',
    ACTIVITYTYPE_TRANSFER: 63,
    ACTIVITY_EXCHANGE_RATE: 'activity-exchange',
    ACTIVITY_AMOUNT: 'activity-amount',
    ACTIVITY_DESCRIPTION: 'activity-description',
    ACTIVITY_TARGET_AMOUNT: 'activity-target-amount',
    CREATE_ACCOUNT_ACCOUNTNAME: 'account-name',
    CREATE_ACCOUNT_IDENTIFICATION: 'identification-number',
    EXCHANGE_RATE: 'exchange-ex-rate',
    EXCHANGE_TARGET_AMOUNT: 'exchange-target-amount',
    EXCHANGE_AMOUNT: 'exchange-amount',
    DEBIT_CREDIT: 'borcalacak',
    EXCHANGE_INSTALLMENT_RATE: 'exchange-installment-rate',
    KNOWNCOLORS: [
        {
            name: 'Coface',
            color: '#03254c'
        }, {
            name: 'Euler Hermes',
            color: '#3a7ca5'
        }, {
            name: 'Atradius',
            color: '#990f02'
        }, {
            name: 'Credendo',
            color: '#ff6600'
        }, {
            name: 'Axa',
            color: '#1520a6'
        }, {
            name: 'EximBank',
            color: '#a71a40'
        },
    ],
    COLORS: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", '#EFADAE', '#148DFB', '#F5DF4D']
}