import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ExPaySourceSelect from '../select/ExPaySourceSelect';
import { setGetValues, setPageNumber, initializeFilter } from '../filter/duck/actions';
import { Filter } from '../filter/Filter';
import { bankingActivity } from '../apis/filter';
import { Translate } from '../common/Translate';
import { list } from '../apis/bankingActivity';
import dictionary from '../constants/dictionary';
import useStateIfMounted from '../hook/useStateIfMounted';
import { selectValue } from '../common/comops';
import Activities from './Activities';
import Pagination from '../common/Pagination';
import Loading from '../common/Loading';
import ActivityForm from './ActivityForm';
import { setSelectedItem } from './duck/actions';
import Exchange from './Exchange';


const SCREENNAME = 'Activity';

const NewActivityButton = props => {
    const dispatch = useDispatch();
    const setItem = () => dispatch(setSelectedItem({ id: -1, bankId: selectValue(props.bank) }));
    return (
        <div className="filter-button">
            <Link to="#" onClick={setItem} className="btn btn-filter btn-link">
                <Translate>{dictionary.NEW_BANK_ACTIVITY}</Translate>
            </Link>
            <Link to="#" onClick={props.toggle} className="btn btn-filter btn-link">
                <Translate>{dictionary.NEW_BANK_CURRENCY}</Translate>
            </Link>
        </div>
    )
}

const BankActivities = props => {

    // Source Id değiştikçe Data değişecek
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const [bank, setBank] = useState(0);
    const [data, setData] = useStateIfMounted([]);
    const page = useSelector(state => state.filter.page);
    const getValues = useSelector(state => state.filter.getValues);
    const selected = useSelector(state => state.activity.selected)
    const [pageCount, setPageCount] = useStateIfMounted(1);
    const [loading, setLoading] = useStateIfMounted(false);
    const [openCurrency, setOpenCurrency] = useState(false);
    const dispatch = useDispatch();
    const toggleOpenCurrency = () => setOpenCurrency(!openCurrency);
    const loadData = () => {
        dispatch(setGetValues(false));
        setLoading(true);
        list(selectedFilterValues, selectValue(bank), page.pageNumber || 0).then(result => {
            if (result.data) {
                setData(result.data.list);
                setPageCount(result.data.pageCount);
            }
        }).finally(() => setLoading(false))
    };

    useEffect(() => {
        if (getValues) {
            loadData();
        }
    }, [getValues])

    useEffect(() => {
        dispatch(setPageNumber(SCREENNAME, 0));
        /* if (selectValue(bank))
            dispatch(setGetValues(true)); */
    }, [bank])

    return (
        <>
            {openCurrency && <Exchange data={{}} close={() => setOpenCurrency(false)} />}
            {selected.id && <ActivityForm />}
            <div className="bank-activities">
                <div className="bank-activities__banks">
                    <ExPaySourceSelect value={bank} setValue={setBank} />
                </div>
                <Filter fn={bankingActivity} buttons={<NewActivityButton bank={bank} toggle={toggleOpenCurrency} />} textFieldName="ActivityDescription" />
                {loading ? <Loading className="big" /> :
                    <>
                        <Activities data={data} loading={loading} />
                        <div className="activities-footer activities-paginition">
                            <Pagination pageCount={pageCount} pageNumber={page.pageNumber} setPage={pageNumber => dispatch(setPageNumber(SCREENNAME, pageNumber))} />
                            {/* <Pagination pageCount={20} pageNumber={7} setPage={pageNumber => dispatch(setPageNumber(SCREENNAME, pageNumber))} /> */}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default BankActivities;