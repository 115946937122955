import React from 'react';
import { Main } from "../app/main/Main";
import EkoPieChart from '../app/charts/EkoPieChart';

const data = [
    { name: 'Gecikmis Fatura', value: 650, color: '#f88379' },
    { name: 'Vadesi Gelmemis Fatura', value: 300, color: '#699de0' },
];

const data2 = [
    { name: 'Gecikmis Fatura', value: 650, color: '#f88379' },
    { name: 'noname', value: 300, color: '#999593' },
];

const ChartTestPage = props => {

    return (<Main>
        <EkoPieChart data={data} />
        <EkoPieChart data={data2} mainLabel={ { name: '', value: 650, color: '#f88379' }}/>
    </Main>
    )
}

export default ChartTestPage;