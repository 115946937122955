import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectFilter } from './duck/actions';


export const FilterText = props => {

    const dispatch = useDispatch();
    const onFilterSelect = e => {
        if (e.keyCode !== 13) return;
        e.preventDefault();
        dispatch(selectFilter(null, props.textFieldName));
    };


    useEffect(() => {
        document.addEventListener("keyup", onFilterSelect);
        return () => document.removeEventListener("keyup", onFilterSelect);
    })

    return (<div className="filter-text">
        <input type="text" className="filter-text__text" id="filter-text"></input>
        <svg className="icon icon-user filter-text__icon" onClick={() => {
            dispatch(selectFilter(null, props.textFieldName)); }}>
            <use xlinkHref="../img/sprite.svg#icon-search"></use>
        </svg>
    </div>)
}