import { combineReducers } from 'redux';
import mainReducer from './app/main/duck/reducers';
import headerReducer from './app/header/duck/reducers';
import modalReducer from './app/modal/duck/reducers';
import filterReducer from './app/filter/duck/reducers';
import leftMenuReducer from './app/leftSidebar/duck/reducers';
import activityReducer from './app/bank/duck/reducers';

const rootReducer = combineReducers({
    main: mainReducer,
    header: headerReducer,
    modal: modalReducer,
    filter: filterReducer,
    leftMenu: leftMenuReducer,
    activity: activityReducer
});

export default rootReducer;