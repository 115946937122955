import React from 'react';
import { useSelector } from 'react-redux';
import { expensePaymentSource } from '../apis/parameter';
import dictionary from '../constants/dictionary';
import SelectSync from './SelectSync';

const ExPaySourceSelect = props => {

    const options = useSelector(state => state.main.expensePaymentSource);
    let filtered = [];
    
    if (props.screen === 'exchange') {
        filtered = props.type === 'tl' ? options.filter(item => item.stringHelper2 === 'TL') : options.filter(item => item.stringHelper2 !== 'TL');
        if (props.bank) {
            filtered = filtered.filter(item => item.stringHelper3 === props.bank);
        }
    }

    return (
        <SelectSync
            className={props.className}
            changeFn={props.setValue}
            optionsFn={expensePaymentSource}
            value={props.value}
            placeholder={dictionary.BANK_ACCOUNT}
            options={props.type ? filtered : options}
        />
    )
}

export default ExPaySourceSelect;