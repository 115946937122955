import React from 'react';
import ExpenseList from '../app/expenseList/ExpenseList';
import { Main } from "../app/main/Main";

const ExpenseListPage = props => {

    return (<Main>
        <ExpenseList />
    </Main>
    )
}


export default ExpenseListPage;