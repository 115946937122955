import React from 'react';
import { useHistory } from 'react-router-dom';
import { Translate } from '../common/Translate';
import dictionary from '../constants/dictionary';
import businessKeys from '../constants/businessKeys';
import Loading from '../common/Loading';

const CreateComponent = props => {

    const history = useHistory();

    const { onChange, saving, saveAccount } = props;

    return (<div className="create-account">
        <div className="create-account__row">
            <label className="create-account__label"><Translate>{dictionary.CREATE_ACCOUNT_ACCOUNTNAME}</Translate></label>
            <input type="text" onChange={onChange} className="create-account__input account-name" id={businessKeys.CREATE_ACCOUNT_ACCOUNTNAME} defaultValue={props.accountName}></input>
        </div>
        <div className="create-account__row">
            <label className="create-account__label"><Translate>{dictionary.CREATE_ACCOUNT_IDENTIFICATION}</Translate></label>
            <input type="text" onChange={onChange} className="create-account__input identification-number" id="identification-number" defaultValue={props.identifactionNumber}></input>
        </div>
        <div className="create-account__row buttons">
            {saving ? <Loading /> :
                <>
                    <button className="create-account__button btn" onClick={saveAccount} ><Translate>{dictionary.GENERAL_KAYDET}</Translate></button>
                    <button className="create-account__button btn" onClick={() => history.goBack()}><Translate>{dictionary.GENERAL_GERI}</Translate></button>
                </>
            }
        </div>
    </div>)
}

export default CreateComponent;