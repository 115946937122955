export default {
    UNMATCHING_TOTAL_AND_INSTALLMENT: 'Toplam ile taksit tutarları uyumlu degildir',
    INVOICE_SHOULD_BE_SAVED: 'Oncelikle fatura bilgisi kaydedilmelidir',
    QUANTITY_REQUIRED: 'Adet bilgisinin girilmesi gerekmektedir',
    AMOUNT_REQUIRED: 'Tutar girilmesi gerekmektedir',
    ARRANGEMENT_REQUIRED: 'Duzenleme tarihi girilmesi gerekmektedir',
    POLICY_REQUIRED: 'Policenin secilmesi gerekmektedir',
    INVOCE_DETAIL_REQUIRED: 'Tutar için detay girilmesi gerekmektedir',
    EXPENSE_SHOULD_BE_SAVED: 'Oncelikle gider bilgisi kaydedilmelidir',
    BANK_ACCOUNT: 'Banka Hesabi',
    NEW_BANK_ACTIVITY: 'Hareket',
    NEW_BANK_CURRENCY: 'Doviz',
    ACTIVITY_HEADER_ACTIVITYTYPE: 'Islem Turu',
    ACTIVITY_HEADER_ACTIVITYDATE: 'Islem Tarihi',
    ACTIVITY_HEADER_RELATEDACCOUNT: 'Ilgili Hesap',
    ACTIVITY_HEADER_DESCRIPTION: 'Aciklama',
    ACTIVITY_HEADER_AMOUNT: 'Meblag',
    ACTIVITY_HEADER_BALANCE: 'Bakiye',
    ACTIVITY_ACTIVITY_TYPE: 'Hareket Turu',
    ACTIVITY_ACTIVITY_DATE: 'Hareket Tarih',
    ACTIVITY_ACTIVITY_AMOUNT: 'Tutar',
    ACTIVITY_ACTIVITY_TARGETBANK: 'Hedef Banka',
    ACTIVITY_ACTIVITY_TARGETAMOUNT: 'Hedef Tutar',
    ACTIVITY_ACTIVITY_EXCHANGERATE: 'Degisim Kuru',
    CREATE_ACCOUNT_ACCOUNTNAME: 'Firma Adi',
    CREATE_ACCOUNT_IDENTIFICATION: 'Vergi No',
    GENERAL_KAYDET: 'Kaydet',
    GENERAL_KAPAT: 'Kapat',
    GENERAL_GERI: 'Geri',
    ACCOUNT_MANAGER: 'Temsilci',
    CITY: 'Sehir',
    ACTIVITIY_NO_RELATED_ACTIVITY: 'Para transferindeki ilgili diğer hareket bulunamadı, manual olarak iki hareketi de silip, tekrar giriniz!',
    ACTIVITIY_NO_BANK: 'Oncelikle banka seçmeniz gerekmektedir!',
    EXPENSE_PAYMENT_AWAITING_NO_SOURCE: 'Odeme bekleyen islemlerde odeme kaynagı giremezsiniz',
    EXPENSE_PAYMENT_SOURCE_RERQUIRED: 'Odeme yapılan islemler icin kaynak girmelisiniz',
    INVOICE_PAYMENT_AWAITING_NO_SOURCE: 'Tahsilat bekleyen islemlerde odeme kaynagı giremezsiniz',
    INVOICE_PAYMENT_SOURCE_RERQUIRED: 'Tahsilat yapılan islemler icin kaynak girmelisiniz',
    DETAIL_AMOUNT_REQUIRED: 'Tutar bilgisi girilmelidir',
    DESCRIPTION_REQUIRED: 'Açıklama girilmelidir',

    SOURCE_ACCOUNT: `Kaynak Hesap`,
    TARGET_ACCOUNT: `Hedef Hesap`,
    EXCHANGE_PAGE_HEADER: `Doviz Alım Satim`,
    CURRENCY: `Doviz Cinsi`,
    ACTIVITY_DATE_REQUIRED: `Hareket tarihi gereklidir!`,
    SOURCE_ACCOUNT_REQUIRED: `Kaynak hesabı secmeniz gerekmektedir`,
    TARGET_ACCOUNT_REQUIRED: `Hedef hesabı secmeniz gerekmektedir`,
    EXCHANGE_RATE_REQUIRED: `Dönüşüm kuru girilmelidir`,
    TARGET_AMOUNT_REQUIRED: `Hedef tutarı secmeniz gerekmektedir`,
    
    EXPANDED: `Ilk satırlar`,
    NOTEXPANDED: `Butun satırlar`,
    KDV_INCLUDED_AMOUNT: `Kdv Dahil Tutar`
}