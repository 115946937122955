import React, {  useContext, useRef,useState,useEffect } from 'react';
import DecimalNumber from '../common/DecimalNumber';
import TaxSelect from '../select/TaxSelect';
import InvoviceContext from '../contexts/invoice-context';
import { formatNumber } from 'accounting';
import { Translate } from '../common/Translate';
import { unformat } from 'accounting';
import { showConfirmation, showMessage } from '../modal/duck/actions';
import { useDispatch } from 'react-redux';
import dictionary from '../constants/dictionary';
import { detailDescription } from '../apis/invoice';


const DetailRows = props => {
    const { data, active, showEmpty } = useContext(InvoviceContext);
    const { invoiceDetails } = data;

    return <>
        {invoiceDetails.map(item =>
            item.id === active ? <ActiveRow key={`key_${item.id}`} item={item} abb={data.currencyAbbreviation} invoiceDetails={invoiceDetails} />
                : <PasifRow key={`key_${item.id}`} item={item} abb={data.currencyAbbreviation} invoiceDetails={invoiceDetails} />
        )}
        {(active === -1 && showEmpty) ? <ActiveRow key={`key__1`} item={{}} abb={""} invoiceDetails={invoiceDetails} /> : (active === -1 && <NewLineButton />)}
    </>

}



const ActiveRow = props => {

    const { item, invoiceDetails, abb } = props;
    const { taxRate, setTaxRate, setData, data, setActive, setShowEmpty } = useContext(InvoviceContext);
    const refDefinition = useRef();
    const refQuantity = useRef();
    const refPrice = useRef();
    const dispatch = useDispatch();
    const openModal = (message) => dispatch(showMessage(message));


    const [query, setQuery] = useState(item.detailDescription || ""); // Başlangıç değeri item'dan alınıyor
    const [results, setResults] = useState([]); // API sonuçları
    const [loading, setLoading] = useState(false); // Yüklenme durumu
    const debounceTimer = useRef(null); // Debounce için referans

   
    const fetchResults = async (searchQuery) => {
        console.log("searchQuery inside fetchResults:", searchQuery); // searchQuery değerini kontrol et
        setLoading(true);
        try {
            const response = await detailDescription(searchQuery, item.id);
            
            if (!response || !response.data || !Array.isArray(response.data.invoiceDetails)) {
                throw new Error("Veri alınırken bir hata oluştu.");
            }
            
            const descriptions = response.data.invoiceDetails.map((item) => item.detailDescription);
            setResults(descriptions || []);
        } catch (error) {
            console.error("Arama sırasında bir hata oluştu:", error);
        } finally {
            setLoading(false);
        }
    };
    
    

    
    


    useEffect(() => {
        console.log("Query değişti:", query);
        if (debounceTimer.current) clearTimeout(debounceTimer.current);
    
        debounceTimer.current = setTimeout(() => {
            if (query.trim() !== "") {
                console.log("Debounce sonrası sorgu gönderiliyor...");
                fetchResults(query);
            }
        }, 500);
    
        return () => clearTimeout(debounceTimer.current);
    }, [query]);


    const handleChange = (e) => {
        setQuery(e.target.value); // Kullanıcı girdisini güncelle
    };

    const handleSelect = (value) => {
        setQuery(value); // Seçilen değeri input'a yerleştir
    };

 
      const getItem = () => {

        return {
            ...item,
            amount: unformat(refPrice.current.value),
            quantity: unformat(refQuantity.current.value),
            detailDescription: refDefinition.current.value,
            taxRate: taxRate.value,
            id: item.id,
            MasterId: data.id
        }
    }

    return (
        <div className="generic-detail__row double-flex">
           <div className="generic-detail__td generic-detail__td--name">
    <input
        type="text"
        ref={refDefinition}
        list="detailDescriptions"
        defaultValue={item.detailDescription}
        value={query}
        onChange={handleChange}
        placeholder="Seçim yapın"
    />
    <datalist id="detailDescriptions">
        {results.map((description, index) => (
            <option key={index} value={description}  onClick={() => handleSelect(description)}  />
        ))}
    </datalist>
</div>
            <div className="generic-detail__td generic-detail__td--quantity">
                <DecimalNumber className="generic-detail__quantity" ref={refQuantity} pre={0} defaultValue={item.quantity || 1} />
            </div>
            <div className="generic-detail__td generic-detail__td--price">
                <DecimalNumber className="generic-detail__price" ref={refPrice} pre={2} defaultValue={item.amount || 0} />
            </div>
            <div className="generic-detail__td generic-detail__td--tax">
                <TaxSelect setValue={setTaxRate} value={taxRate} />
            </div>
            <div className="generic-detail__td generic-detail__td--total">
                <span className="generic-detail__td--text">{formatNumber(item.amount * item.quantity, 2)} {abb}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--buttons">
                <div className="generic-detail__button generic-detail__button--checkmark"
                    onClick={() => changeInvoiceDetails(getItem(), invoiceDetails, setData, data, setActive, setShowEmpty, openModal)}>
                    <svg className="icon icon-checkmark">
                        <use xlinkHref="../img/sprite.svg#icon-checkmark"></use>
                    </svg>
                </div>
                <div className="generic-detail__button generic-detail__button--cross"
                    onClick={() => { setActive(-1); setShowEmpty(false); }}>
                    <svg className="icon icon-cross">
                        <use xlinkHref="../img/sprite.svg#icon-cross"></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}


const PasifRow = props => {

    const { item, invoiceDetails, abb } = props;
    const { setActive, data, setData, setShowEmpty } = useContext(InvoviceContext);
    const dispatch = useDispatch();
    const deleteRow = () =>
        dispatch(showConfirmation("DoYouWantToDelete", () => deleteItem(item.id, invoiceDetails, setData, data, setActive, setShowEmpty)));


    return (
        <div className="generic-detail__row double-flex">
            <div className="generic-detail__td generic-detail__td--name">
                <span className="generic-detail__td--text"> {item.detailDescription}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--quantity">
                <span className="generic-detail__td--text">{formatNumber(item.quantity)}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--price">
                <span className="generic-detail__td--text">{formatNumber(item.amount, 2)} {abb}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--tax">
                <span className="generic-detail__td--text">{item.taxRate}%</span>
            </div>
            <div className="generic-detail__td generic-detail__td--total">
                <span className="generic-detail__td--text">{formatNumber(item.amount * item.quantity, 2)} {abb}</span>
            </div>
            <div className="generic-detail__td generic-detail__td--buttons">
                <div className="generic-detail__button generic-detail__button--plus" onClick={() => setActive(item.id)}>
                    <svg className="icon icon-plus">
                        <use xlinkHref="../img/sprite.svg#icon-plus"></use>
                    </svg>
                </div>
                <div className="generic-detail__button generic-detail__button--cross"
                    onClick={deleteRow}>
                    <svg className="icon icon-cross">
                        <use xlinkHref="../img/sprite.svg#icon-minus"></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}

const NewLineButton = props => {
    const { showEmpty, setShowEmpty } = useContext(InvoviceContext);
    return <div className="generic-detail__row borderless">
        <button className="btn-empty" onClick={() => setShowEmpty(!showEmpty)}>
            <Translate>NewLine</Translate>
            <svg className="icon icon-plus">
                <use xlinkHref="../img/sprite.svg#icon-plus"></use>
            </svg>
        </button>
    </div >
}

const checkData = (item, showMessage) => {
    if(!item.amount){
        showMessage(dictionary.DETAIL_AMOUNT_REQUIRED);
        return false;
    }
    if(!item.detailDescription){
        showMessage(dictionary.DESCRIPTION_REQUIRED);
        return false;
    }
    if(!item.quantity){
        showMessage(dictionary.QUANTITY_REQUIRED);
        return false;
    }

    return true;
}



const changeInvoiceDetails = (item, invoiceDetails, setData, data, setActive, setShowEmpty, showMessage) => {

    if(!checkData(item, showMessage)) return;
    const newItems = [...invoiceDetails].filter(t => t.id !== item.id);
    const orderedList = [...newItems, item].sort((a, b) => a.id > 0 && a.id < b.id ? -1 : 1);
    setNewData(orderedList, setData, data, setActive, setShowEmpty);
}

const setNewData = (orderedList, setData, data, setActive, setShowEmpty) => {
    const total = orderedList.reduce((agg, item) => { return agg + (item.amount * item.quantity) }, 0);

    const tax = Math.round(orderedList.reduce((agg, item) => { return agg + (item.amount * item.quantity * item.taxRate) }, 0)) / 100;
    const newData = { ...data, invoiceDetails: orderedList, invoiceTotal: total, taxTotal: tax, invoiceGrandTotal: total + tax };

    setData(newData);
    setActive(-1);
    setShowEmpty(false);
}

const deleteItem = (id, invoiceDetails, setData, data, setActive, setShowEmpty) => {
    const newItems = [...invoiceDetails].filter(t => t.id !== id);
    setNewData(newItems, setData, data, setActive, setShowEmpty);
}

export default DetailRows;