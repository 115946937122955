import React, {useContext} from 'react';
import { Translate } from '../common/Translate';
import TciSimpleDate from '../datePicker/TciSimpleDate';
import InvoviceContext from '../contexts/invoice-context';

const InvoiceArrangement = props => {

    const { arrangement, setArrangement } = useContext(InvoviceContext);

    return (
        <div className="invoice-general__row narrow borderless">
            <div className="invoice-general__label">
                <svg className="invoice-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-calendar"></use>
                </svg>
                <span className="invoice-general__label--label">
                    <Translate>InvoiceArrangement</Translate>
                </span>
            </div>
            <div className="invoice-general__input">
                <TciSimpleDate startDate={arrangement} setStartDate={setArrangement} />
            </div>
            <div className="invoice-general__buttons">

            </div>
        </div>
    )
}

export default InvoiceArrangement;