import React, { useEffect } from 'react';
import { list } from '../apis/expenseType';
import ExpenseTypeData from './ExpenseTypeData';
import useStateIfMounted from '../hook/useStateIfMounted';

const changeLimited = (data, text, setLimitedData, setLoading) => {
    setLoading(true);
    if (!text) {
        setLimitedData(data);
        setLoading(false);
        return;
    };
    const limited = data.filter(item => {
        return item.expenseDescription.toLowerCase().includes(text.toLowerCase())
    });
    setLimitedData(limited);
    setTimeout(() => {
        setLoading(false);
    }, 100);
}


const ExpenseType = props => {
    const [getValues, setGetValues] = useStateIfMounted(true);
    const [loading, setLoading] = useStateIfMounted(false);
    const [data, setData] = useStateIfMounted([]);
    const [limitedData, setLimitedData] = useStateIfMounted([]);
    const [selected, setSelected] = useStateIfMounted(0);
    const [mainType, setMainType] = useStateIfMounted(null);

    useEffect(() => {
        if (!getValues)
            return;

        setGetValues(false);
        setLoading(true);
        list().then(res => {
            setData(res.isSuccess ? res.data : []);
            setLimitedData(res.isSuccess ? res.data : []);
        }).finally(() => setLoading(false));

    }, [getValues])

    return (
        <div className="meta">
            {

                <ExpenseTypeData mainType={mainType} setMainType={setMainType} setGetValues={setGetValues} loading={loading} data={limitedData} selected={selected} setSelected={setSelected} onChange={e => {
                    changeLimited(data, e.target.value, setLimitedData, setLoading);
                }} />
            }
        </div>
    )

}

export default ExpenseType;