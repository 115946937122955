import React, { memo } from 'react';
import GenericSummaryTable from '../common/GenericSummaryTable';
import EkoSimplePieChart from '../charts/EkoSimplePieChart';

const SalesExpense = memo(props => {

    const { data, expanded, setExpanded, nameKey, keyKey, dataKey, header } = props;
    const setKey = () => { };
    return (
        <div className={props.className ? props.className : "report-sales"}>
            <div className="report-sales__summary big-margin">
                <GenericSummaryTable
                    data={data}
                    setKey={setKey}
                    nameKey={nameKey}
                    keyKey={keyKey}
                    dataKey={dataKey}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    itemCount={4}
                    header= {header}
                />
                <EkoSimplePieChart data={data} setKey={setKey} keyName='accountId' />
            </div>
        </div>
    )
})

export default SalesExpense;