import * as comops from '../common/comops'
import urls from '../constants/urls'

export const list = async () => {
    const options = {
        method: 'post',
        mode: 'cors'
    }
    const res = await comops.fetchAsync(urls.EXPENSETYPE_LIST, options);
    
    return res;
}

export const save = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.EXPENSETYPE_SAVE, options);

    return res;
}

export const deleteItem = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.EXPENSETYPE_DELETE, options);

    return res;
}