import React, { useContext, useRef } from 'react';
import ExpenseContext from '../contexts/expense-context';
import { Translate } from '../common/Translate';
import { getNumeric } from '../common/comops';
import { setQuantityInstallments } from './duck/actions';
import { useDispatch } from "react-redux";
import { unformat, formatNumber } from 'accounting';
import { selectValue } from '../common/comops';
import Installments from './Installments';

const ExpenseInstallments = props => {

    const { data, setData, expenseId, arrangement, refPrice, taxRate } = useContext(ExpenseContext);
    const adetRef = useRef();
    const dispatch = useDispatch();

    return (
        <>
            <div className="expense-general__row narrow borderless">
                <div className="expense-general__label">
                    <svg className="expense-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                        <use xlinkHref="../img/sprite.svg#icon-calendar"></use>
                    </svg>
                    <span className="expense-general__label--label">
                        <Translate>Taksitler</Translate>
                    </span>
                </div>
                <div className="expense-general__input double-flex">
                    <div className="expense-general__input double-flex">
                        <input type="text" className="expense-general__input--center" onChange={getNumeric} ref={adetRef} placeholder="Taksit Sayısı" defaultValue={data.numberOfInstallment || 1}></input>
                        <button className="btn wo-animation" onClick={() => dispatch(setQuantityInstallments(expenseId, parseInt(adetRef.current.value), unformat(refPrice.current.value), arrangement, data, setData))}>
                            <Translate>Adete Göre Taksit</Translate>
                        </button>
                    </div>
                </div>
                <div className="expense-general__buttons">

                </div>
            </div>
            <Installments />
        </>)

}


export default ExpenseInstallments;