import { getElementValue } from '../../common/comops';
import businessKeys from '../../constants/businessKeys';

export class ListPayloads {
    constructor() {
        this.accountName = getElementValue(`#${businessKeys.CREATE_ACCOUNT_ACCOUNTNAME}`);
        this.identificationNumber = getElementValue(`#${businessKeys.CREATE_ACCOUNT_IDENTIFICATION}`);
    }
}

