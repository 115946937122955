import React, { useState, useEffect } from 'react';
import FormattedDecimal from '../common/FormattedDecimal';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";


const EkoBarchart = props => {

    return (
        <div className="tci-bar-container">
            <h3 className="tci-bar__header">{props.header}</h3>
            <ResponsiveContainer>
                <BarChart
                    data={props.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                    onClick={props.fn}
                >
                    <YAxis hide={true} domain={[0, props.total]} />


                    <Bar dataKey="pv" fill={props.color} background={{ fill: "#c5c4c2" }} />
                </BarChart>
            </ResponsiveContainer>
            <FormattedDecimal text="" value={props.data[0].pv} abb='$' className="tci-bar__amount" />
            <span className="tci-bar__count">{props.count}</span>
        </div>
    )

}


export default EkoBarchart;