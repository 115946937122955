import types from "./types"
import { showConfirmation, showMessage } from '../../modal/duck/actions';
import { setGetValues } from '../../filter/duck/actions';
import { del, detail, save, groupedActivites } from '../../apis/bankingActivity';
import businessKeys from "../../constants/businessKeys";
import dictionary from "../../constants/dictionary";
import { unformat } from "accounting";
import ExchangeModel from "../model/ExchangeModel";


export const saveAll = (item) => async (dispatch, getState) => {

    const targetElement = document.querySelector(`#${businessKeys.ACTIVITY_TARGET_AMOUNT}`);
    const amountElement = document.querySelector(`#${businessKeys.ACTIVITY_AMOUNT}`);
    const descriptionElement = document.querySelector(`#${businessKeys.ACTIVITY_DESCRIPTION}`);

    if(!targetElement)
        item.exchangeRate = 1;

    item.targetAmount = (targetElement && targetElement.value) ? unformat(targetElement.value) : 0;
    item.amount = (amountElement) ? unformat(amountElement.value) : 0;
    item.activityDescription = (descriptionElement) ? descriptionElement.value : '';

    const types = getState().main.activityTypes || [];
    const selectedType = types.find(t => t.value === item.activityType) || {};

    item.type = selectedType.stringHelper;

    const result = await save(item);

    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return;
    }

    dispatch(setGetValues(true));
    dispatch(setSelected({}));
}

export const setSelectedItem = (selected) => async (dispatch, getState) => {

    // BankId boş ise hata ver
    if (!selected.bankId && !selected.loading) {
        dispatch(showMessage(dictionary.ACTIVITIY_NO_BANK));
        dispatch(setSelected({}));
        return;
    }

    // Eğer Para transferi ve relatedActivity null ise iki taraftaki hareketin de silinip tekrar girilmesini saglik vermemiz gerekiyor.
    if (selected.activityType === businessKeys.ACTIVITYTYPE_TRANSFER && !selected.loading && !selected.relatedActivityId) {
        dispatch(showMessage(dictionary.ACTIVITIY_NO_RELATED_ACTIVITY));
        dispatch(setSelected({}));
        return;
    }

    // burda eğer debitValue doluysa ve relatedActivity varsa ve activityType 63 ise
    // related activity'i getirmek lazim
    let item = selected;

    if (selected.debitAmount && selected.relatedActivityId && selected.activityType === businessKeys.ACTIVITYTYPE_TRANSFER) {
        dispatch(setSelected({ id: -1, loading: true, bankId: selected.bankId }));
        item = await detail(selected.relatedActivityId);
        item.isSource = true;
    }

    dispatch(setSelected(item));
}

export const getSummary = () => async dispatch => {
    const result = await groupedActivites();
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}

export const setSelected = (selected) => {
    return {
        type: types.ACTIVITY_SET_SELECTED,
        selected
    }
}

export const deleteItem = (item) => dispatch => {
    dispatch(showConfirmation("DoYouWantToDelete", () => dispatch(confDel(item))));
}

const confDel = (item) => async dispatch => {

    const res = await del(item.id);
    if (!res.isSuccess) {
        dispatch(showMessage(res.data.message));
        return;
    }
    dispatch(setGetValues(true));
}

export const saveExchange = (activityDate, sourceBank, targetBank) => async dispatch => {

    let result = { isSuccess: false };

    if (!activityDate) {
        dispatch(showMessage(dictionary.ACTIVITY_DATE_REQUIRED));
        return result;
    }

    if (!sourceBank || !sourceBank.label) {
        dispatch(showMessage(dictionary.SOURCE_ACCOUNT_REQUIRED));
        return result;
    }

    if (!targetBank || !targetBank.label) {
        dispatch(showMessage(dictionary.TARGET_ACCOUNT_REQUIRED));
        return result;
    }

    const activity = new ExchangeModel(activityDate, sourceBank, targetBank);


    if (!activity.amount) {
        dispatch(showMessage(dictionary.AMOUNT_REQUIRED));
        return result;
    }

    if (!activity.targetAmount) {
        dispatch(showMessage(dictionary.TARGET_AMOUNT_REQUIRED));
        return result;
    }

    result = await save(activity);
    if (!result || !result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return { isSuccess: false };
    }

    dispatch(setGetValues(true));
    return result;
}
