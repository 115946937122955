import * as comops from '../common/comops'
import urls from '../constants/urls'

export const translations = async () => {

    const options = {
        method: 'get',
        mode: 'cors'
    };

    const res = await comops.fetchAsync(urls.TRANSLATION, options);

    if (!res.isSuccess || !res.data)
        res = [];

    return res;
}