import React from 'react';

const Pagination = props => {

    const { pageCount, setPage, pageNumber } = props;
    const pageArray = Array.from(Array(pageCount).keys())

    return (
        <ul className="pagination">
            {pageArray.map(item => {

                if (pageNumber - 7 > item && item === 1)
                    return <li className={`pagination__item gap`} key={item}>
                        ...
                    </li>

                if (pageNumber - 7 > item && item !== 0)
                    return;

                if (pageNumber + 7 < item && item === pageCount - 2)
                    return <li className={`pagination__item gap`} key={item}>
                        ...
                    </li>

                if (pageNumber + 7 < item && item !== pageCount - 1)
                    return;


                const className = `pagination__item pagination__item--main${pageNumber === item ? ' active' : ''}`;
                return <li className={className} key={item} onClick={() => { setPage(item); }}>
                    {item + 1}
                </li>
            })}
        </ul>
    )
}


export default Pagination;