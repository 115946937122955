import { save } from '../../apis/invoice';
import { showMessage, showMessageWithOkFunction, toggleModal } from '../../modal/duck/actions';
import { getInitialInstallments } from './operations';
import { getInstallmentsByQuantity } from '../../common/installments';
import dictionary from '../../constants/dictionary';
import businessKeys from '../../constants/businessKeys';

export const setInsurerInstallments = (masterId, insurerId, date, data, setData,genel) => dispatch => {

    const { invoiceGrandTotal: amount } = data;

    if (!date) {
        dispatch(showMessage(dictionary.ARRANGEMENT_REQUIRED));
        return;
    }
    if (!amount || amount <= 0) {
        dispatch(showMessage(dictionary.INVOCE_DETAIL_REQUIRED));
        return;
    }
    if (!insurerId && !genel) {
        dispatch(showMessage(dictionary.POLICY_REQUIRED));
        return;
    }
    const installments = getInitialInstallments(masterId, amount, date, insurerId);

    setData({ ...data, installments });

}

export const setQuantityInstallments = (masterId, quantity, date, data, setData) => dispatch => {

    const { invoiceGrandTotal: amount } = data;

    if (!date) {
        dispatch(showMessage(dictionary.ARRANGEMENT_REQUIRED));
        return;
    }
    if (!amount || amount <= 0) {
        dispatch(showMessage(quantity.AMOUNT_REQUIRED));
        return;
    }
    if (!quantity || quantity <= 0) {
        dispatch(showMessage(dictionary.QUANTITY_REQUIRED));
        return;
    }
    const installments = getInstallmentsByQuantity(masterId, amount, date, quantity);

    setData({ ...data, installments });
}

const getExchangeRate = () => {
    const element = document.querySelector(`#${businessKeys.INVOICE_EXCHANGE_RATE}`);
    return element ? +element.value : null;
}

export const saveAll = (payload, history, setGetData) => async dispatch => {

    const grandTotal = payload.installments.reduce((total, item) => total + item.installmentAmount, 0);

  /*   if (Math.abs(payload.invoiceGrandTotal - grandTotal) > 0.01) {
        dispatch(showMessage(dictionary.UNMATCHING_TOTAL_AND_INSTALLMENT));
        return;
    } */
    const apElement = document.querySelector("#idinvoice-ap");
    const invoiceDefinitionElement = document.querySelector("#input-invoice-definition");
    const invoiceNumberElement = document.querySelector("#input-invoice-number");

    if (apElement)
    payload.isAp = apElement.checked;

    if (invoiceNumberElement)
        payload.invoiceNumber = invoiceNumberElement.value;

    if (invoiceDefinitionElement)
        payload.invoiceDefinition = invoiceDefinitionElement.value;

    payload.exchangeRate = getExchangeRate();
    

   
    const res = await save(payload);
    const message = res.data.message || 'SaveSucessful';

    if (!res.isSuccess) {
        dispatch(showMessage(message));
        return;
    }

    const forward = () => {
        history.push(`/invoice/${res.data.id}`);
        dispatch(toggleModal(false));
        setGetData(true);
    };

    dispatch(showMessageWithOkFunction(message, forward));
}

export const openPaymentPage = (history, invoiceId) => dispatch => {
    if (!invoiceId) {
        dispatch(showMessage(dictionary.INVOICE_SHOULD_BE_SAVED));
        return;
    }
    history.push(`/payment/${invoiceId}`);
}