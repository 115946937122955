import React, { useEffect, useState } from 'react';
import useStateIfMounted from '../hook/useStateIfMounted';
import PaymentContext from '../contexts/payment-context';
import { list } from '../apis/payment';
import PaymentHeader from './PaymentHeader';
import PaymentRows from './PaymentRows';
import ButtonPart from './ButtonPart';
import TotalPart from './TotalPart';
import Loading from '../common/Loading';

const Payment = props => {

    const [loading, setLoading] = useStateIfMounted(false);
    const [data, setData] = useStateIfMounted([]);
    const [active, setActive] = useStateIfMounted(-1);
    const { invoiceId } = props;
    const [getData, setGetData] = useStateIfMounted(true);
    const [showEmpty, setShowEmpty] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [total, setTotal] = useState(0);
    const [abbreviation, setAbbreviation] = useState(0);

    useEffect(() => {
        if (!getData) return;
        setLoading(true);
        list(invoiceId).then(result => {
            setData(result.data.payments);
            setAbbreviation(result.data.abbreviation);
        }).finally(() => { setGetData(false); setLoading(false); });
    }, [getData])

    return (
        <PaymentContext.Provider value={{ data, invoiceId, setData, active, setActive, showEmpty, setShowEmpty, total, setTotal, abbreviation, setGetData, loading, updating, setUpdating }}>
            {loading ? <Loading className="big" /> : <div className="payment">
                <ButtonPart />
                <PaymentHeader />
                <PaymentRows />
                <TotalPart data={data} abb={abbreviation} />
            </div>}
        </PaymentContext.Provider>
    )

}

export default Payment;