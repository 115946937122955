import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import ExpenseContext from '../contexts/expense-context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountAsync from '../selectAsync/AccountAsync';
import { openExpensePaymentPage } from './duck/actions';

const ExpenseVendorInvoice = props => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { data, vendor, setVendor, expenseId } = useContext(ExpenseContext);
    return (
        <div className="expense-general__row narrow borderless">
            <div className="expense-general__label">

            </div>
            <div className="expense-general__input double-flex">
                <input type="text" className="expense-general__input--text" id="input-expense-number" placeholder="Invoice Number" defaultValue={data.invoiceNumber}></input>
                <AccountAsync setAccount={setVendor} value={vendor} placeholder='Tedarikçi Seçiniz' />
            </div>
            <div className="expense-general__buttons">
              
            </div>
        </div>
    )
}

export default ExpenseVendorInvoice;