import React, { useEffect, useState } from 'react';
import { formatNumber } from 'accounting';
import useStateIfMounted from '../hook/useStateIfMounted';
import { getSummary } from './duck/actions'
import { useDispatch } from 'react-redux';
import Loading from "../common/Loading";
import FormattedDecimal from '../common/FormattedDecimal';
import { Translate } from '../common/Translate';

const ActivitySummary = props => {

    const [data, setData] = useStateIfMounted([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalTry, setTotalTry] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        dispatch(getSummary()).then(res => {
            setData(res);
            const tot = res.reduce((agg, num) => agg + num.balanceUsd, 0);
            const totTry = res.reduce((agg, num) => agg + num.balanceTry, 0);
            setTotal(tot);
            setTotalTry(totTry);
        }).finally(() => setLoading(false));
    }, []);

    return (

        loading ? <Loading className="medium" /> :
            <>

                <div className="activity-summary">
                    <div className="activity-summary__row"> <h1 className="activity-summary__header"><Translate>Hesap Özeti</Translate></h1></div>
                    {data.map(item => <ActivityRow item={item} key={item.bankId} />)}
                    <TotalRow total={total} totalTry={totalTry} />
                </div>
            </>
    )
}



const ActivityRow = props => {

    const { item } = props;

    return <div className="activity-summary__row">
        <span className="activity-summary__bank-name">
            {item.bankName}
        </span>
        {<FormattedDecimal value={item.balance} abb={item.abbreviation} className="activity-summary__balance-original" />}
        {<FormattedDecimal value={item.balanceUsd} abb={'$'} className="activity-summary__balance-usd" />}
        {<FormattedDecimal value={item.balanceTry} abb={'₺'} className="activity-summary__balance-tl" />}
    </div>
}

const TotalRow = props => {

    const { total, totalTry } = props;

    return <div className="activity-summary__row activity-summary__row-total">
        <span className="activity-summary__bank-name">

        </span>
        <span className="activity-summary__balance-original">
            <Translate>Toplam</Translate>
        </span>
        {<FormattedDecimal value={total} abb={'$'} className="activity-summary__balance-usd" />}
        {<FormattedDecimal value={totalTry} abb={'₺'} className="activity-summary__balance-usd" />}
    </div>
}



export default ActivitySummary;