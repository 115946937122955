import { invoiceSummary, remainings } from '../../apis/report';
import { showMessage, showMessageWithOkFunction, toggleModal } from '../../modal/duck/actions';


export const invoiceBreakdown = () => async (dispatch) => {
    var result = await invoiceSummary();
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}

export const selectLimitedItems = (column, page, sort, setLimited, setKey, setLoading, setPage) => async (dispatch) => {

    if (page > 0 && setPage) {
        setKey(column);
        setPage(0);
        return;
    }
    
    setLoading(true);
    var payload = {
        columnName: column,
        pageNumber: page,
        sort
    }

    var result = await remainings(payload);
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        setLimited([]);
    }
    setKey(column);
    setLimited(result.data);
    setLoading(false);
}