import React from 'react';
import { Translate } from '../common/Translate';

const ExpensePaymentHeader = props => {

    return (
        <div className="generic-detail__header-row">
            <div className="generic-detail__header-th generic-detail__header-th--payment-description">
                <Translate>Description</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--payment-date">
                <Translate>Date</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--payment-amount">
                <Translate>Amount</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--payment-source">
                <Translate>Kaynak</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--payment-buttons">
             
            </div>
        </div>
    )
}

export default ExpensePaymentHeader;