import React from 'react';
import urls from '../constants/urls';
import { SelectAsync } from './SelectAsync.js';

const PolicyAsync = props => {

    return <SelectAsync
        placeholder="Poliçe Seçiniz"
        message="Poliçe kaydi yok"
        url={urls.POLICY_SEARCHLIST}
        fn={props.setPolicy}
        value={props.value}
    />
}

export default PolicyAsync;