import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell, Sector, LabelList } from "recharts";
import businessKeys from "../constants/businessKeys";

const RADIAN = Math.PI / 180;

const EkoSimplePieChart = props => {
    const { setKey, keyName } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const onClick = useCallback((e, index, activeOne) => {
        setKey(e[keyName]);
        if (index === activeOne)
            setActiveIndex(null);
        else
            setActiveIndex(index);
    }, []);

    return (

        <PieChart width={200} height={200}>
            <Pie
                activeIndex={activeIndex}
                data={props.data}
                cx={100}
                cy={100}
                labelLine={false}
                activeShape={renderActiveShape}
                label={renderCustomizedLabel}
                outerRadius={85}
                fill="#8884d8"
                dataKey="amount"
                onClick={(e, index) => onClick(e, index, activeIndex)}
            >
                {props.data.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={
                       businessKeys.KNOWNCOLORS.find(item => item.name == entry.accountName) ?
                       businessKeys.KNOWNCOLORS.find(item => item.name == entry.accountName).color :
                            businessKeys.COLORS[index %  businessKeys.COLORS.length]
                    } />
                })}

            </Pie>
        </PieChart>
    );
}


export default EkoSimplePieChart;


const renderCustomizedLabel = (e) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, accountName } = e;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {accountName}
        </text>
    );
};

const renderActiveShape = props => {

    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        midAngle
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 70) * cos;
    const sy = cy + (outerRadius - 70) * sin;
    return (
        <Sector
            animationDuration={3000}
            cx={sx}
            cy={sy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={props.fill}
        />
    );
};
