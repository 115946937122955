import React from "react";
import { Translate } from "../common/Translate";
import { Link } from "react-router-dom";

export const Logout = props => {
  let json = localStorage.userData;
  let userData = json ? JSON.parse(json) : {};

  if (!userData.token) {
    return <div className="logout">

      <div className="logout__name">
        <Link
          to="/login"
        >
          <Translate>Login</Translate> {/* Translate et */}
        </Link>
      </div>
    </div>;
  }


  return (
    <div className="logout">

      <div className="logout__name">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            delete localStorage.userData;
            window.location.href = "/";
          }}
        >
          <Translate>Logout</Translate> {/* Translate et */}
        </a>
      </div>

      <div className="logout__icon">
        <svg className="icon icon-user">
          <use xlinkHref="../img/sprite.svg#icon-user"></use>
        </svg>
      </div>
    </div>
  );
};
