import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import InvoviceContext from '../contexts/invoice-context';
import { useDispatch } from 'react-redux';
import { saveAll } from './duck/actions';
import { useHistory } from 'react-router-dom';
import { selectValue } from '../common/comops';
import Loading from '../common/Loading';

const InvoiceDefinition = props => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { data, policy, arrangement, due, currency, setGetData,setAccount, account, updating, setUpdating, setActiveInstallment,genel,paymentDue } = useContext(InvoviceContext);
    const currencyId =  selectValue(currency);
    
    const payload = {
        id: data.id,
        policyId: selectValue(policy),
        accountId: selectValue(account),        
        arrangementDate: arrangement,
        paymentDueDate: paymentDue,   
        dueDate: due,
        currency: currencyId,
        invoiceDefinition: '',
        invoiceNumber: '',
        invoiceDetails: data.invoiceDetails,
        installments: data.installments,
        invoiceGrandTotal: data.invoiceGrandTotal,
        genel,
        isAp:false,
        offSet: new Date().getTimezoneOffset()
    };
    


    const save = () => {
        if (updating) return;
        setUpdating(true);     
        dispatch(saveAll(payload, history, setGetData)).then(res => {

        }).finally(() => {
            setUpdating(false);
            setActiveInstallment(-1);
            
        });
    }

    return (
        <div className="invoice-general__row">
            <div className="invoice-general__label">
                <svg className="invoice-general__label--icon icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-file-empty"></use>
                </svg>
                <span className="invoice-general__label--label">
                    <Translate>InvoiceDefinition</Translate>
                </span>
            </div>
            <div className="invoice-general__input">
                <input type="text" className="invoice-general__input--text" id="input-invoice-definition" defaultValue={data.invoiceDefinition}></input>
            </div>
            <div className="invoice-general__buttons">
                <button className="btn btn-white" onClick={() => history.push("/invoice-list")}><Translate>Back</Translate></button>
                <button className="btn" onClick={save}>{updating ? <Loading /> : <Translate>Update</Translate>}</button>
            </div>
        </div>
    )
}

export default InvoiceDefinition;