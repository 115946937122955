import { accountsales, accountInvoices, invoiceTimeline } from '../../apis/report';
import { showMessage, showMessageWithOkFunction, toggleModal } from '../../modal/duck/actions';

export const accountBreakdown = (filters, accountId) => async (dispatch) => {
    const payload = {
        accountId,
        filters
    };
    var result = await accountsales(payload);
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}

export const accountInvoicesList = (filters, accountId, sort, pageNumber) => async (dispatch) => {

    const payload = {
        accountId,
        filters,
        sort,
        pageNumber,
        monthly: true
    };

    const result = await Promise.all([dispatch(getAccInvoices(payload)), dispatch(getAccTimeline(payload))]);

    return {
        sales: result[0],
        timeline: result[1]
    }

}


const getAccInvoices = (payload) => async (dispatch) => {
    var result = await accountInvoices(payload);
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}

const getAccTimeline = (payload) => async (dispatch) => {
    var result = await invoiceTimeline(payload);
    if (!result.isSuccess) {
        dispatch(showMessage(result.data.message));
        return [];
    }
    return result.data;
}