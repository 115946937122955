import React from 'react';
import { useSelector } from 'react-redux';
import { users } from '../apis/parameter';
import SelectSync from './SelectSync';

const UsersSelect = props => {

    const options = useSelector(state => state.main.users);

    return (
        <SelectSync
            changeFn={props.setValue}
            optionsFn={users}
            value={props.value}
            placeholder='Kullanici'
            options = {options}
        />
    )
}

export default UsersSelect;