import React, { useContext, useState, useRef, memo } from 'react';
import { formatNumber, unformat } from 'accounting';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import ExpenseContext from '../contexts/expense-context';
import TciSimpleDate from '../datePicker/TciSimpleDate';
import ExPaySourceSelect from '../select/ExPaySourceSelect';
import DecimalNumber from '../common/DecimalNumber';
import { Translate } from '../common/Translate';
import { Checkbox } from '../checkbox/Checkbox';
import { showMessage } from '../modal/duck/actions';
import { selectValue } from '../common/comops';
import dictionary from '../constants/dictionary';
import businessKeys from '../constants/businessKeys';

const Installments = props => {
    const { data, activeInstallment, setActiveInstallment, setData } = useContext(ExpenseContext);
    const [date, setDate] = useState(new Date());
    const [ispaid, setIspaid] = useState(false);
    const [sourceId, setSourceId] = useState(0);
    const expensePaymentSource = useSelector(state => state.main.expensePaymentSource);
    const dispatch = useDispatch();
    const showModal = (message) => dispatch(showMessage(message));

    return (
        <div className="expense-general__row center narrow borderless">
            <div className="generic-installment">
                <div className="generic-installment__row installment-header">
                    <div className="generic-installment__no">
                        <span className="generic-installment__span">{<Translate>No</Translate>}</span>
                    </div>
                    <div className="generic-installment__date">
                        <span className="generic-installment__span">{<Translate>Tarih</Translate>}</span>
                    </div>
                    <div className="generic-installment__amount">
                        <span className="generic-installment__span">{<Translate>Tutar</Translate>}</span>
                    </div>
                    <div className="generic-installment__source">
                        <span className="generic-installment__span">{<Translate>Kaynak</Translate>}</span>
                    </div>
                    <div className="generic-installment__rate">
                        <span className="generic-installment__span">{<Translate>Kur</Translate>}</span>
                    </div>
                    <div className="generic-installment__buttons">

                    </div>
                </div>
                {

                    data.installments.map((item, index) => {
                        return item.id === activeInstallment ?
                            <ActiveInstallment data={data} setData={setData} item={item} activeInstallment={activeInstallment}
                                setActiveInstallment={setActiveInstallment} date={date} setDate={setDate} key={item.installmentNo}
                                ispaid={ispaid} setIspaid={setIspaid} sourceId={sourceId} setSourceId={setSourceId} showModal={showModal} expensePaymentSource={expensePaymentSource} /> :

                            <PasiveInstallment data={data} setData={setData} item={item} activeInstallment={activeInstallment}
                                setActiveInstallment={setActiveInstallment} key={item.installmentNo} setIspaid={setIspaid} expensePaymentSource={expensePaymentSource}
                                setSourceId={setSourceId} setDate={setDate} />
                    })
                }
            </div>
        </div>
    )

}

const ActiveInstallment = memo(props => {

    const refAmount = useRef();
    const refRate = useRef();
    const { item, date, setDate, setActiveInstallment, data, setData, ispaid, setIspaid, sourceId, setSourceId, showModal, expensePaymentSource } = props;

    const getItem = () => {
        const sourceItemId = selectValue(sourceId);
        const bankItem = expensePaymentSource.find(t => t.value === sourceItemId);
        return {
            ...item,
            installmentDate: date,
            installmentAmount: unformat(refAmount.current.value),
            ispaid,
            exchangeRate: unformat(refRate.current.value),
            sourceId: sourceItemId,
            bankCurrency: bankItem ? bankItem.stringHelper2 : null
        }
    }

    return (
        <div className="generic-installment__row">
            <div className="generic-installment__no">
                <span className="generic-installment__span">{item.installmentNo}</span>
            </div>
            <div className="generic-installment__date">
                <TciSimpleDate startDate={date} setStartDate={setDate} />
            </div>
            <div className="generic-installment__amount">
                <DecimalNumber className="generic-installment--amount" ref={refAmount} pre={2} defaultValue={item.installmentAmount || 0} />
            </div>
            <div className="generic-installment__source w-select">
                <ExPaySourceSelect value={sourceId} setValue={value => {
                    if (value && !ispaid)
                        setIspaid(true);
                    setSourceId(value);
                }
                } />
            </div>
            <div className="generic-installment__rate">
                <DecimalNumber className="generic-installment--rate" ref={refRate} pre={4} id={businessKeys.EXCHANGE_INSTALLMENT_RATE} defaultValue={item.exchangeRate || 1} />
            </div>
            <div className="generic-installment__buttons">
                <div className="generic-installment__button generic-installment__button--checkmark"
                    onClick={() => {
                        if (sourceId && !ispaid) {
                            showModal(dictionary.EXPENSE_PAYMENT_AWAITING_NO_SOURCE);
                            return;
                        }

                        if (!sourceId && ispaid) {
                            showModal(dictionary.EXPENSE_PAYMENT_SOURCE_RERQUIRED);
                            return;
                        }
                        changeInstallments(getItem(), data, setData, setActiveInstallment)
                    }
                    }>
                    <svg className="icon icon-checkmark">
                        <use xlinkHref="../img/sprite.svg#icon-checkmark"></use>
                    </svg>
                </div>
                <div className="generic-installment__button generic-installment__button--cross"
                    onClick={() => setActiveInstallment(-1)}>
                    <svg className="icon icon-cross">
                        <use xlinkHref="../img/sprite.svg#icon-cross"></use>
                    </svg>
                </div>
                <div className={`generic-installment__button generic-installment__checkbox generic-installment__checkbox--${ispaid ? 'green' : 'red'}`}>
                    <Checkbox id="ex-installment" name="ex-installment" title="Ödeme Bekliyor" alternateTitle="Ödendi" isChecked={ispaid} checkChange={() => setIspaid(!ispaid)} />
                </div>
            </div>
        </div >
    )
});


const PasiveInstallment = memo(props => {

    const { item, setActiveInstallment, setIspaid, expensePaymentSource, setSourceId, setDate } = props;
    const exPaySource = expensePaymentSource.find(src => src.value == item.sourceId);
    const exPaySourceText = exPaySource ? exPaySource.label : '';

    return (
        <div className="generic-installment__row">
            <div className="generic-installment__no">
                <span className="generic-installment__span">{item.installmentNo}</span>
            </div>
            <div className="generic-installment__date">
                <span className="generic-installment__span">{moment(item.installmentDate).format('DD/MM/YYYY')}</span>
            </div>
            <div className="generic-installment__amount">
                <span className="generic-installment__span">{formatNumber(item.installmentAmount, 2)}</span>
            </div>
            <div className="generic-installment__source">
                <span className="generic-installment__span">{exPaySourceText}</span>
            </div>
            <div className="generic-installment__rate">
                <span className="generic-installment__span">{formatNumber(item.exchangeRate || 1, 4)}</span>
            </div>
            <div className="generic-installment__buttons">
                <div className="generic-installment__button generic-installment__button--plus" onClick={() => {
                    setSourceId(item.sourceId);
                    setIspaid(item.ispaid);
                    setDate(item.installmentDate);
                    setActiveInstallment(item.id);
                }}>
                    <svg className="icon icon-plus">
                        <use xlinkHref="../img/sprite.svg#icon-plus"></use>
                    </svg>
                </div>
                <div className={`generic-installment__button generic-installment__checkbox--${item.ispaid ? 'green' : 'red'}`}>
                    <span className="generic-installment__span">{item.ispaid ? <Translate>Ödendi</Translate> : <Translate>Ödeme Bekleniyor</Translate>}</span>
                </div>
            </div>

        </div>
    )

});



const changeInstallments = (item, data, setData, setActiveInstallment) => {
    const installments = data.installments.slice();
    const index = installments.findIndex(el => el.id === item.id);
    installments[index] = item;
    setData({ ...data, installments });
    setActiveInstallment(-1);
}

export default Installments;