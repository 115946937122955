import React from 'react';
import { Translate } from '../common/Translate';
import dictionary from '../constants/dictionary';

const ListComponentHeader = props => {
    return (
        <div className="list-account__row list-account__header">
            <div className="list-account__header-cell list-account__header-cell--name">
                <span className="list-account__span"><Translate>{dictionary.CREATE_ACCOUNT_ACCOUNTNAME}</Translate></span>
            </div>
            <div className="list-account__header-cell list-account__header-cell--identification">
                <span className="list-account__span"><Translate>{dictionary.CREATE_ACCOUNT_IDENTIFICATION}</Translate></span>
            </div>
            <div className="list-account__header-cell list-account__header-cell--account-manager">
                <span className="list-account__span"><Translate>{dictionary.ACCOUNT_MANAGER}</Translate></span>
            </div>
            <div className="list-account__header-cell list-account__header-cell--city">
                <span className="list-account__span"><Translate>{dictionary.CITY}</Translate></span>
            </div>
        </div>
    )
}

export default ListComponentHeader;