import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Translate } from '../common/Translate';
import FormattedDecimal from '../common/FormattedDecimal';
import { deleteExpense } from './duck/actions';

const Expense = props => {
    const { item } = props;
    const dispatch = useDispatch();
    const lang = useSelector(state => state.main.culture);
    const del = e => {
        e.preventDefault();
        dispatch(deleteExpense(item.id))
    };



    const isOverdued = item.overdued  ? ' overdued' : (item.notOverdueddaysUsd > 0 ? ' waiting' : '');

    return (
        <Link to={`/expense/${item.id}`} className={`expenses__item${isOverdued}`}>
            <div className={`expenses__item-image`}>
                <svg className="icon expenses__item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-file-empty"></use>
                </svg>
            </div>
            <div className="expenses__item-definition">
                <div className="expenses__item-definition--top">
                    <span className="nvoices__item-definition--top expenses__item-definition--invoice-definition">
                        {item.expenseDescription}
                    </span>
                </div>
                <div className="expenses__item-definition--bottom">
                    <span className={`expenses__item-definition--bottom expenses__item-definition--expense-type`} style={{ color: item.expenseColor, backgroundColor: item.expenseBackgroundColor, borderColor: item.expenseBackgroundColor }}>
                        {item.expenseType}
                    </span>
                    <span className="expenses__item-definition--bottom expenses__item-definition--account-manager">
                        {item.accountManager}
                    </span>

                    <span className="expenses__item-definition--bottom expenses__item-definition--vendor">
                        {item.vendor}
                    </span>
                    {(item.policy || item.account) &&
                        <span className="expenses__item-definition--bottom expenses__item-definition--account-policy">
                            {item.policy || item.account}
                        </span>
                    }
                </div>
            </div>
            <div className="expenses__item-date expenses__item-arrangement">
                <span className="expenses__item-date--date">
                    {moment(item.arrangementDate).locale(lang).format('DD MMMM YYYY')}
                </span>
            </div>
            <div className="expenses__item-amount">
                <FormattedDecimal text="" value={item.remaining} abb={item.abbreviation} className="expenses__item-amount--main" />
                <FormattedDecimal text="Toplam: " value={item.amount} abb={item.abbreviation} className="expenses__item-amount--total" />
            </div>
            <div className="expenses__item-button">
                <svg className="icon icon-bin red" onClick={del}>
                    <use xlinkHref="../img/sprite.svg#icon-bin"></use>
                </svg>
            </div>
        </Link>
    )

}

export default Expense;