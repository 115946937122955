import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EkoBarchart from "../charts/EkoBarchart";
import useStateIfMounted from "../hook/useStateIfMounted";
import { invoiceBreakdown, selectLimitedItems } from "./duck/actions";
import { Invoices } from "../invoiceList/Invoices";
import Loading from "../common/Loading";
import Pagination from "../common/Pagination";
import urls from "../constants/urls";

const InvoiceReport = (props) => {
  const [data, setData] = useStateIfMounted([]);
  const [filterName, setFilterName] = useStateIfMounted([]);
  const [loading, setLoading] = useState(false);
  const [limited, setLimited] = useStateIfMounted({ list: [] });
  const [key, setKey] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const sort = useSelector((state) => state.filter.sort);
  const sortPayload = sort.screen === "Invoice" ? sort : {};

  useEffect(() => {
    setLoading(true);
    dispatch(invoiceBreakdown())
      .then((res) => {
        setData(res);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setPageCount(limited.pageCount || 0);
  }, [limited]);

  useEffect(() => {
    if (key) getLimited(key);
  }, [page, sortPayload.column, sortPayload.direction]);

  const getLimited = (columnName, setPageNumber) => {
    dispatch(
      selectLimitedItems(
        columnName,
        page,
        sortPayload,
        setLimited,
        setKey,
        setTableLoading,
        setPageNumber
      )
    );
  };

  const getLimitiedWithPage = (columnName) => {
    setFilterName(columnName);
    getLimited(columnName, setPage);
  };

  return (
    <div className="invoice-report">
      <div className="invoice-report__bars">
        {loading ? (
          <Loading className="medium" />
        ) : (
          <>
            <EkoBarchart
              header="TOPLAM"
              color="#3ca6bc"
              fn={() => getLimitiedWithPage("RemainingAmountUsd")}
              count={data.remainingCount}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.remaining }]}
            />
            <EkoBarchart
              header="VADESI GELMEMIS"
              color="#3cb043"
              fn={() => getLimitiedWithPage("NotOverdueddaysUsd")}
              count={data.notOverdueddaysCount}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.notOverdueddays }]}
            />
            <EkoBarchart
              header="1-30 GUN GECIKMIS"
              color="#ffdb58"
              fn={() => getLimitiedWithPage("OverduedAmount30daysUsd")}
              count={data.days30Count}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.days30 }]}
            />
            <EkoBarchart
              header="30-60 GUN GECIKMIS"
              color="#ffd017"
              fn={() => getLimitiedWithPage("OverduedAmount60daysUsd")}
              count={data.days60Count}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.days60 }]}
            />
            <EkoBarchart
              header="60-90 GUN GECIKMIS"
              color="#fbb117"
              fn={() => getLimitiedWithPage("OverduedAmount90daysUsd")}
              count={data.days90Count}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.days90 }]}
            />
            <EkoBarchart
              header="90-120 GUN GECIKMIS"
              color="#f37220"
              fn={() => getLimitiedWithPage("OverduedAmount120daysUsd")}
              count={data.days120Count}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.days120 }]}
            />
            <EkoBarchart
              header="120 GUNDEN FAZLA GECIKMIS"
              color="#df1e26"
              fn={() => getLimitiedWithPage("OverduedAmountOlderdaysUsd")}
              count={data.daysOlderCount}
              total={data.remaining}
              data={[{ name: "Toplam", pv: data.daysOlder }]}
            />
          </>
        )}
      </div>
      <></>
      <div className="invoice-report__invoices">
        {}

        {tableLoading ? (
          <Loading className="medium" />
        ) : (
          <>
            <Invoices
              data={limited.list}
              loading={tableLoading}
              excelUrl ={urls.INVOICE_EXCELSUMMARY}
              excelPayload={{
                columnName: filterName,
                pageNumber: 0,
                sort: {
                  screen: "Invoice",
                  column: "",
                  direction: "",
                },
              }}
            />

            <div className="invoice-footer invoice-paginition">
              <Pagination
                pageCount={pageCount}
                pageNumber={page}
                setPage={(pageNumber) => {
                  setPage(pageNumber);
                }}
              />
              {/* <Pagination pageCount={20} pageNumber={7} setPage={pageNumber => dispatch(setPageNumber(SCREENNAME, pageNumber))} /> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InvoiceReport;
