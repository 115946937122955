import React from 'react';
import { Translate } from '../common/Translate';
import { Link } from 'react-router-dom';
import dictionary from '../constants/dictionary';

const ActivityHeader = props => {

    return (
        <div className="activities__header">
            <div className="activities__header-type">
                <Link to="#" className="activities__header-link">
                    <Translate>{dictionary.ACTIVITY_HEADER_ACTIVITYTYPE}</Translate>
                </Link>
            </div>
            <div className="activities__header-date activities__header-activitydate middle">
                <Link to="#" className="activities__header-link">
                    <Translate>{dictionary.ACTIVITY_HEADER_ACTIVITYDATE}</Translate>
                </Link>
            </div>
            <div className="activities__header-account">
                <Link to="#" className="activities__header-link">
                    <Translate>{dictionary.ACTIVITY_HEADER_RELATEDACCOUNT}</Translate>                
                </Link>
            </div>
            <div className="activities__header-description">
                <Link to="#" className="activities__header-link">
                    <Translate>{dictionary.ACTIVITY_HEADER_DESCRIPTION}</Translate>                
                </Link>
            </div>
            <div className="activities__header-amount  middle">
                <Link to="#" className="activities__header-link">
                    <Translate>{dictionary.ACTIVITY_HEADER_AMOUNT}</Translate>                
                </Link>
            </div>
            <div className="activities__header-balance  middle">
                <Link to="#" className="activities__header-link">
                    <Translate>{dictionary.ACTIVITY_HEADER_BALANCE}</Translate>                
                </Link>
            </div>
            <div className="activities__header-button  middle">
            </div>
        </div>
    )
}

export default ActivityHeader;