import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';
import AppRoutes from './Routes';
import 'react-datepicker/dist/react-datepicker.css';

const middleware = applyMiddleware(thunk, logger);
const store = createStore(rootReducer, middleware);
const mainApp = document.querySelector('#tci-finance');


if (mainApp) {
    ReactDOM.render(
        <Provider store={store}>
                <AppRoutes />
        </Provider>, mainApp
    );
}