import { save } from '../../apis/expense';
import { showMessage, showMessageWithOkFunction, toggleModal } from '../../modal/duck/actions';
import { getInstallmentsByQuantity } from '../../common/installments';
import dictionary from '../../constants/dictionary';
import businessKeys from '../../constants/businessKeys';
import { getElementValue } from '../../common/comops';
import { unformat } from 'accounting';

const getExchangeRate = () => {
    const element = document.querySelector(`#${businessKeys.EXPENSE_EXCHANGE_RATE}`);
    return element ? +element.value : null;
}


export const saveAll = (payload, history, setGetData) => async dispatch => {

    payload.expenseDescription = getElementValue("#input-expense-definition");
    const returnElement = document.querySelector("#idexpense");
    const apElement = document.querySelector("#idexpense-ap");
    payload.invoiceNumber = getElementValue("#input-expense-number");
    payload.amount = unformat(getElementValue("#expense-amount"));

    if (returnElement)
        payload.isReturn = returnElement.checked;

        if (apElement)
        payload.isAp = apElement.checked;

    payload.exchangeRate = getExchangeRate();

    setGetData(false);
    const res = await save(payload);
    const message = res.data.message || 'SaveSucessful';

    if (!res.isSuccess) {
        dispatch(showMessage(message));
        return;
    }

    const forward = () => {
        history.push(`/expense/${res.data.id}`);
        dispatch(toggleModal(false));
        setGetData(true);
    }

    dispatch(showMessageWithOkFunction(message, forward));
}

export const openExpensePaymentPage = (history, expenseId) => dispatch => {
    if (!expenseId) {
        dispatch(showMessage(dictionary.EXPENSE_SHOULD_BE_SAVED));
        return;
    }
    history.push(`/expense-payment/${expenseId}`);
}

export const setQuantityInstallments = (masterId, quantity, amount, date, data, setData) => dispatch => {
    if (!date) {
        dispatch(showMessage(dictionary.ARRANGEMENT_REQUIRED));
        return;
    }
    if (!amount || amount <= 0) {
        dispatch(showMessage(dictionary.AMOUNT_REQUIRED));
        return;
    }
    if (!quantity || quantity <= 0) {
        dispatch(showMessage(dictionary.QUANTITY_REQUIRED));
        return;
    }
    const installments = getInstallmentsByQuantity(masterId, amount, date, quantity);

    setData({ ...data, installments });
}