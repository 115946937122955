import React, { useContext, useRef } from 'react';
import CurrencySelect from '../select/CurrencySelect';
import InvoviceContext from '../contexts/invoice-context';
import DecimalNumber from '../common/DecimalNumber';
import { useSelector } from 'react-redux';
import businessKeys from '../constants/businessKeys';

const InvoiceNumberCurrency = props => {

    const { currency, setCurrency, data } = useContext(InvoviceContext);
    const refExchangeRate = useRef();
    const currencies = useSelector(state => state.main.currencies);
    let rate = data.exchangeRate;
    if (!rate && typeof currency === 'number') {
        const curr = currencies.find(t => t.value == currency);
        rate = curr && curr.decimalHelper;
    } else if (!rate && currency) {
        rate = currency.decimalHelper;
    }

    return (
        <div className="invoice-general__row narrow borderless">
            <div className="invoice-general__label">

            </div>
            <div className="invoice-general__input double-flex">
                <input type="text" className="invoice-general__input--text" id="input-invoice-number" placeholder="Invoice Number" defaultValue={data.invoiceNumber}></input>
                <CurrencySelect value={currency} setValue={value => setCurrency(value)} />
                <DecimalNumber id={businessKeys.INVOICE_EXCHANGE_RATE}
                    className="generic-detail__exchange-rate middle"
                    ref={refExchangeRate} pre={4}
                    defaultValue={(rate ? rate.toString() : '1.0000')}
                />
            </div>
            <div className="invoice-general__buttons">

            </div>
        </div>
    )
}

export default InvoiceNumberCurrency;