import { getNextWeekDay } from '../../common/installments';

export const getInitialInstallments = (masterId, amount, date, insurerId) => {

    return insurerId === 1 ? getAtradiusInstallments(masterId, amount, date) : (
        insurerId === 3 ? getHermesInstallments(masterId, amount, date) : getCofaceInstallments(masterId, amount, date)
    );
}


const getCofaceInstallments = (masterId, amount, date) => {
    const dayOfMonth = date.getDate();
    const addToMonth = dayOfMonth > 15 ? 1 : 0;
    const dayOfInstallment = getNextWeekDay(new Date(date.getFullYear(), date.getMonth() + addToMonth, 25));
    const result = new Array(1).fill({
        id: -10,
        masterId,
        installmentNo: 1,
        installmentAmount: amount,
        installmentDate: new Date(dayOfInstallment),
        ispaid: false
    });
    return result;
}

const getAtradiusInstallments = (masterId, amount, date) => {

    const firstInstallment = new Date(date.getFullYear(), date.getMonth() + 1, 10);
    const tutar = Math.round(amount * 25) / 100;
    const artik = amount - (tutar * 3)

    const list = new Array(4).fill().map((el, index) => {
        return {
            id: -40 + index * 10,
            masterId,
            installmentNo: index + 1,
            installmentAmount: index === 3 ? artik : tutar,
            installmentDate: new Date(getNextWeekDay(new Date(firstInstallment).setMonth(firstInstallment.getMonth() + index * 3))),
            ispaid: false
        }
    })


    return list;

}

const getHermesInstallments = (masterId, amount, date) => {

    const firstInstallment = new Date(date.getFullYear(), date.getMonth() + 1, 10);
    const tutar = Math.round(amount * 80) / 100;
    const artik = amount - tutar
    const list = new Array(2).fill().map((el, index) => {
        return {
            id: -20 + index * 10,
            masterId,
            installmentNo: index + 1,
            installmentAmount: index === 1 ? artik : tutar,
            installmentDate: new Date(getNextWeekDay(new Date(firstInstallment).setMonth(firstInstallment.getMonth() + index * 9))),
            ispaid: false
        }
    })
    return list;
}

