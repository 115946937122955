import * as comops from '../common/comops'
import urls from '../constants/urls'

export const invoiceSummary = async () => {
    const options = {
        method: 'POST',
        mode: 'cors'
    };

    const res = await comops.fetchAsync(urls.REPORT_INVOICESUMMARY, options);

    return res;
}

export const remainings = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.REPORT_REMAININGLIST, options);

    return res;
}

export const accountsales = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.REPORT_ACCOUNTSALES, options);

    return res;
}

export const accountInvoices = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.REPORT_ACCOUNTINVOICES, options);

    return res;
}

export const invoiceTimeline = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.REPORT_ACCOUNTIMELINE, options);

    return res;
}

export const expensesByType = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.REPORT_BYTYPEEXPENSES, options);

    return res;
}