import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { setPickerActive, setPickerDataName, setFromPicker } from './duck/actions';
import { TciDatePicker } from '../datePicker/TciDatePicker';
import { SimplePick } from '../datePicker/SimplePick';
import { setDates } from '../filter/duck/actions';

const ReportDate = props => {

    const dispatch = useDispatch();
    const culture = useSelector(state => state.main.culture) || 'tr-Tr';
    const firstDate = useSelector(state => state.filter.firstDate);
    const secondDate = useSelector(state => state.filter.secondDate);
    const pickerIsActive = useSelector(state => state.filter.pickerIsActive);
    const pickerDataName = useSelector(state => state.filter.pickerDataName);
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const setFirstDate = (value) => dispatch(setDates(value, secondDate));
    const setSecondDate = (value) => dispatch(setDates(firstDate, value));
    const setBothDates = (first, second) => dispatch(setDates(first, second));

    const pickerClass = `filter__date-picker${pickerIsActive ? ' active' : ''}`;


    useEffect(() => {
        dispatch(setPickerDataName(props.pickerName))
        dispatch(setDates(new Date(new Date().getFullYear(), 0, 1), new Date()));
        dispatch(setFromPicker(new Date(new Date().getFullYear(), 0, 1), new Date(), props.pickerName, selectedFilterValues))
    }, [])

    return (
        <div className="report-picker">
            <button className="report-picker__button" onClick={() => dispatch(setPickerActive(!pickerIsActive))}>
                <svg className="icon icon-calendar">
                    <use xlinkHref="../img/sprite.svg#icon-calendar"></use>
                </svg>
                {
                    `${moment(firstDate).locale(culture).format('DD MMMM YYYY')} - ${moment(secondDate).locale(culture).format('DD MMMM YYYY')}`}</button>
            <div className={pickerClass}>
                {pickerIsActive && <TciDatePicker isSecond={false} startDate={firstDate} setStartDate={setFirstDate} />}
                {pickerIsActive && <TciDatePicker isSecond={true} startDate={secondDate} setStartDate={setSecondDate} baseDate={firstDate} header="EndDate" />}
                {pickerIsActive && <SimplePick setBothDates={setBothDates} dataName={pickerDataName} />}
            </div>
        </div>)

}

export default ReportDate;