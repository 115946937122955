import { deleteItem } from '../../apis/expense';
import { showMessage, showConfirmation } from '../../modal/duck/actions';
import { setGetValues } from '../../filter/duck/actions';

export const deleteExpense = (id) => dispatch => {

    var message = "Gideri silmek istediğinize eminmisiniz?";
    dispatch(showConfirmation(message, () => dispatch(deleteExpenseNative(id))));
}


const deleteExpenseNative = (id) => async dispatch => {

    var result = await deleteItem(id);

    dispatch(showMessage(result.data.message));

    if (result.isSuccess)
        dispatch(setGetValues(true));

} 