import React from 'react';
import { useSelector } from 'react-redux';
import { expenseMainTypes } from '../apis/parameter';
import SelectSync from './SelectSync';

const ExpenseMainTypeSelect = props => {

    const options = useSelector(state => state.main.expenseMainTypes);

    return (
        <SelectSync
            changeFn={props.setValue}
            optionsFn={expenseMainTypes}
            value={props.value}
            placeholder='Gider Ana Türü'
            options = {options}
        />
    )
}

export default ExpenseMainTypeSelect;