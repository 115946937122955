import React, { useEffect, useState } from 'react';
import { monthArray, monthOffsets } from '../common/comops';
import { SelectedCombo } from './SelectedCombo';
import { DateElement } from './DateElement';
import { Translate } from '../common/Translate';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setPickerActive, setPickerDataName, changeActive } from './duck/actions';
import useStateIfMounted from '../hook/useStateIfMounted';

export const DateTimeFilter = props => {

    const { item, index } = props;
    item.selectList = monthArray(new Date());
    const dispatch = useDispatch();
    const [less, setLess] = useState(0);
    const [more, setMore] = useState(0);
    const [dateArray, setDateArray] = useStateIfMounted([]);
    const [active, setActive] = useState(false);
    const activeFilter = useSelector(state => state.filter.activeFilter);

    useEffect(() => {
        setDateArray(monthArray(new Date()));
    }, [])

    useEffect(() => {
        setDateArray(monthOffsets(new Date(), less, more));
    }, [more, less])

    useEffect(() => {
        if (active) {
            dispatch(changeActive(item.dataName));
        }

    }, [active]);

    useEffect(() => {
        if (activeFilter !== item.dataName && active) {
            setActive(false);
        }

    }, [activeFilter])

    item.selectList = dateArray;
    const mainClass = `selected-filter${active ? ' active' : ''}`

    return <div className={mainClass}>
        <div className="selected-filter__combo">
            <SelectedCombo item={item} setActive={() => {
                if (!active) {
                    dispatch(setPickerActive(false));
                    dispatch(setPickerDataName(''));
                }
                setActive(!active);
            }} />
        </div>
        <div className="selected-filter__list-container" style={{ zIndex: index }}>
            <ul className="selected-filter__list datetime" >
                <li className="selected-filter__item datetime less" onClick={() => setLess(less - 1)}>
                    <span className="selected-filter__text"><Translate>Less</Translate> ...</span>
                </li>
                {item.selectList.map(element => <DateElement key={`el_${element.year}_${element.month}`} element={element} dataName={item.dataName} setActive={() => setActive(!active)} />)}
                <li className="selected-filter__item datetime more" onClick={() => setMore(more + 1)}>
                    <span className="selected-filter__text"><Translate>More</Translate> ...</span>
                </li>
            </ul>
            <div className="selected-filter__bottom" onClick={() => {
                setActive(false);
                dispatch(setPickerDataName(item.dataName));
                dispatch(setPickerActive(true));
            }}>
                <Link to="#" className="selected-filter__bottom-button"><Translate>DateTimeInterval</Translate></Link>
            </div>
        </div>
    </div >
}