import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { list,excelExportExpense } from '../apis/expense';
import { Filter } from '../filter/Filter';
import { expense } from '../apis/filter';
import { setGetValues, setPageNumber, initializeFilter } from '../filter/duck/actions';
import Pagination from '../common/Pagination';
import { Translate } from '../common/Translate';
import { Link } from 'react-router-dom';
import useStateIfMounted from '../hook/useStateIfMounted';
import Expenses from './Expenses';
import Loading from '../common/Loading';

const SCREENNAME = 'Expense';

const ExpenseButtons = props => {

    const selectedFilterValuess = useSelector(state => state.filter.selectedFilterValues);

    return (

           <> 
        
        <div className="filter-button">
            <Link to="/expense/0" className="btn btn-filter btn-link">
                <Translate>CreateNewExpense</Translate>
            </Link>
        </div>
        <div className="filter-button padded">
        <svg
          className="icon-inner"
          onClick={() => {
            excelExportExpense(selectedFilterValuess);
          }}
        >
          <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
        </svg>
      </div>

        </>
    )
}

const ExpenseList = props => {

    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const sort = useSelector(state => state.filter.sort);
    const page = useSelector(state => state.filter.page);
    const sortPayload = sort.screen === SCREENNAME ? sort : {};
    const [data, setData] = useStateIfMounted([]);
    const getValues = useSelector(state => state.filter.getValues);
    const [pageCount, setPageCount] = useStateIfMounted(1);
    const [loading, setLoading] = useStateIfMounted(false);
    const dispatch = useDispatch();

    const loadData = () => {
        dispatch(setGetValues(false));
        setLoading(true);
        list(selectedFilterValues, sortPayload, page.pageNumber || 0).then(result => {
            setData(result.data.list);
            setPageCount(result.data.pageCount);
        }).finally(() => setLoading(false))
    };

    useEffect(() => {
        if (getValues) {
            loadData();
        }
    }, [getValues])

    useEffect(() => {
        const currentPage = (page && SCREENNAME === page.screen) ? page : null;
        dispatch(initializeFilter(currentPage, SCREENNAME));
    }, [])


    return (<div className="expense-list">
        <Filter fn={expense} buttons={<ExpenseButtons />} textFieldName="ExpenseDescription" />
        {loading ? <Loading className="big" /> :
            <>
                <Expenses data={data} loading={loading} />
                <div className="expense-footer expense-paginition">
                    <Pagination pageCount={pageCount} pageNumber={page.pageNumber} setPage={pageNumber => dispatch(setPageNumber(SCREENNAME, pageNumber))} />
                    {/* <Pagination pageCount={20} pageNumber={7} setPage={pageNumber => dispatch(setPageNumber(SCREENNAME, pageNumber))} /> */}
                </div>
            </>
        }
    </div>)
}


export default ExpenseList;