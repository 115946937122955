import types from './types';

export const errorModal = result => {
    const modalInput = {
        message: result.data.message,
        confirmation: false,
        isOpen: true,
        functions: {}
    }

    return {
        type: types.MODAL_OPEN,
        modalInput
    }

}


export const toggleModal = status => {
    return {
        type: types.MODAL_TOGGLE,
        status
    }
}


export const showMessage = message => {
    let modalInput = {
        message,
        confirmation: false,
        isOpen: true,
        functions: {}
    }

    return {
        type: types.MODAL_OPEN,
        modalInput
    }
}

export const showMessageWithOkFunction = (message, okFn) => {
    let modalInput = {
        message,
        confirmation: false,
        isOpen: true,
        functions: {
            okClicked: okFn,
            closeClicked: okFn
        }
    }

    return {
        type: types.MODAL_OPEN,
        modalInput
    }
}

export const showConfirmation = (message, okFn, cancelFn) => {

    let modalInput = {
        message,
        confirmation: true,
        isOpen: true,
        functions: {
            okClicked: okFn,
            cancelClicked: cancelFn
        }
    }

    return {
        type: types.MODAL_OPEN,
        modalInput
    }
}

export const openModal = modalInput => {
    modalInput.isOpen = true;
    return {
        type: types.MODAL_OPEN,
        modalInput
    }
}