import types from './types'

const INITIAL_STATE = {
    tciDictionary: [],
    currencies: [],
    taxRates: [],
    expenseTypes: [],
    vendors: [],
    expensePaymentSource: [],
    activityTypes: [],
    periods: [],
    expenseMainTypes: [],
    culture: null
}

const mainReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case types.MAIN_SETDICTIONARY:
            {
                const { tciDictionary } = action;
                return { ...state, tciDictionary }
            }
        case types.MAIN_SETTAXRATES:
            {
                const { taxRates } = action;
                return { ...state, taxRates }
            }
        case types.MAIN_SETCURRENCIES:
            {
                const { currencies } = action;
                return { ...state, currencies }
            }
        case types.MAIN_SETEXPENSETYPES:
            {
                const { expenseTypes } = action;
                return { ...state, expenseTypes }
            }
        case types.MAIN_SETVENDORS:
            {
                const { vendors } = action;
                return { ...state, vendors }
            }
        case types.MAIN_SETUSERS:
            {
                const { users } = action;
                return { ...state, users }
            }
        case types.MAIN_SETCULTURE:
            {
                const { culture } = action;
                return { ...state, culture }
            }
        case types.MAIN_SETEXPENSEPAYMENTSOURCE:
            {
                const { expensePaymentSource } = action;
                return { ...state, expensePaymentSource }
            }
        case types.MAIN_ACTIVITYTYPES:
            {
                const { activityTypes } = action;
                return { ...state, activityTypes }
            }
        case types.MAIN_PERIODS:
            {
                const { periods } = action;
                return { ...state, periods }
            }
        case types.MAIN_SETEXPENSEMAINTYPES:
            {
                const { expenseMainTypes } = action;
                return { ...state, expenseMainTypes }
            }
        default:
            return state
    }
}
export default mainReducer;