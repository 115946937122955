import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import AccountAsync from '../selectAsync/AccountAsync';
import InvoiceContext from '../contexts/invoice-context';

const InvoiceAccount = props => {

    const { setAccount, account } = useContext(InvoiceContext);
  
    return (
        <div className="invoice-general__row">
            <div className="invoice-general__label">
                <svg className="invoice-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-copy"></use>
                </svg>
                <span className="invoice-general__label--label">
                    <Translate>Account</Translate>
                </span>
            </div>
            <div className="invoice-general__input">
                <AccountAsync  setAccount={setAccount} value={account} />
               
            </div>
            <div className="invoice-general__buttons">
              
            </div>
        </div>
    )
}

export default InvoiceAccount;