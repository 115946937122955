import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from './duck/actions';
import {Translate} from '../common/Translate';


export const Modal = props => {

  const isOpen = useSelector(state => state.modal.isOpen);
  const message = useSelector(state => state.modal.message);
  const confirmation = useSelector(state => state.modal.confirmation);
  const functions = useSelector(state => state.modal.functions);

  const dispatch = useDispatch();
  let closeFunction = functions.closeClicked || (() => dispatch(actions.toggleModal(false)));
  let okFunction = functions.okClicked || (() => dispatch(actions.toggleModal(false)));
  let cancelFunction = functions.cancelClicked || (() => dispatch(actions.toggleModal(false)));

  if (!isOpen) return <></>;

  return (
    <div className="modal">
      <div className="modal__content">
        <span className="modal__close" onClick={closeFunction}>&times;</span>
        <div className="modal__left">
          <svg className="icon icon-cross">
            <use xlinkHref="../img/sprite.svg#icon-cross"></use>
          </svg>
        </div>
        <div className="modal__right">
          <p className="modal__text">
            <Translate>{message}</Translate>
          </p>
          <div className="modal__buttons">
            <button className="btn btn--green" onClick={() => { okFunction(); closeFunction(); }}> Ok </button>
            {
              confirmation && <button className="btn btn--red" onClick={() => { cancelFunction(); closeFunction(); }}>İptal</button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
