import * as comops from '../common/comops';
import urls from '../constants/urls';

export const list = async (filters, bankId, pageNumber) => {

    if (!bankId)
        return [];

    const payload = {
        filters,
        sort: {},
        pageNumber,
        bankId
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.BANKING_ACTIVITY_LIST, options);

    if (!res.isSuccess || !res.data)
        res = [];

    return res;
}


export const detail = async (id) => {

    const payload = {
        id
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.BANKING_ACTIVITY_DETAIL, options);

    if (!res.isSuccess || !res.data)
        res = {};

    return res.data;
}

export const save = async (payload) => {

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.BANKING_ACTIVITY_SAVE, options);

    return res;
}

export const del = async (id) => {

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ id })
    };

    let res = await comops.fetchAsync(urls.BANKING_ACTIVITY_DELETE, options);

    return res;
}

export const groupedActivites = async () => {

    const options = {
        method: 'POST'
    };

    let res = await comops.fetchAsync(urls.BANKING_GROUPEDACTIVITIES, options);

    return res;
}
