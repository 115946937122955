import React, { useState } from 'react';
import { Translate } from '../common/Translate';
import { useSelector } from 'react-redux';

export const Combo = props => {
 
    const selectedFilters = useSelector(state => state.filter.selectedFilters);
    return (<div className="filter-combo" onClick={e => {
        props.fn();
    }}>
        <svg className="icon icon-user filter-combo__icon">
            <use xlinkHref="../img/sprite.svg#icon-filter"></use>
        </svg>
        {
            selectedFilters.length === 0 &&
            <span className="filter-combo__text"><Translate>Filter</Translate></span>
        }
        <svg className="icon icon-user filter-combo__icon">
            <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
        </svg>
    </div>)
}