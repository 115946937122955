import React from 'react';
import { Main } from "../app/main/Main";
import ActivitySummary from '../app/bank/ActivitySummary';

const ActivitySummaryPage = props => {
    return (<Main>
     <ActivitySummary />
    </Main>
    )
}

export default ActivitySummaryPage;