import React from 'react';
import { useSelector } from 'react-redux';
import SelectSync from './SelectSync';

const PeriodSelect = props => {

    const options = useSelector(state => state.main.periods);

    return (
        <SelectSync
            changeFn={props.setValue}
            optionsFn={() => { }}
            value={props.value}
            placeholder='Dönem'
            options={options}
        />
    )
}

export default PeriodSelect;