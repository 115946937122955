import { getElementValue } from '../../common/comops';
import { unformat } from 'accounting';
import businessKeys from '../../constants/businessKeys';
import { selectValue } from '../../common/comops';

class ExchangeModel {
    constructor(activityDate, sourceBank, targetBank) {
        this.amount = unformat(getElementValue(`#${businessKeys.EXCHANGE_AMOUNT}`));
        this.targetAmount = unformat(getElementValue(`#${businessKeys.EXCHANGE_TARGET_AMOUNT}`));
        this.relatedBankId = selectValue(targetBank);
        this.bankId = selectValue(sourceBank);
        this.type = businessKeys.DEBIT_CREDIT;
        this.activityType = businessKeys.ACTIVITYTYPE_TRANSFER;
        this.activityDate = activityDate;
        this.activityDescription = `Para Transferi ${sourceBank.label} => ${targetBank.label}`;
    }
}

export default ExchangeModel;