import React from 'react';
import AccountContainer from '../app/account/AccountContainer';
import { Main } from "../app/main/Main";

const AccountPage = props => {

    return (<Main>
        <AccountContainer />
    </Main>
    )
}

export default AccountPage;