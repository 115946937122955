import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import * as comops from '../common/comops';

const waiting = {}

const promiseOptions = async (newValue, url, useInput, oldValue, setOldValue, length, setLength) => {

    if (newValue.value){
        return [newValue];
    }

    if (!newValue.length || newValue.length < 3)
        return []

    let options = {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
            searchedText: newValue
        })
    };

    const isSame = newValue.length >= 3 &&
        newValue.length > oldValue.length &&
        newValue.substring(0, oldValue.length) === oldValue &&
        length === 0 && oldValue !== "";

    if (waiting.timeout) {
        clearTimeout(waiting.timeout);
        waiting.timeout = null;
    }


    return await new Promise(resolve => {
        waiting.timeout = setTimeout(() => {
            comops.fetchAsync(url, options).then(result => {
                let final = result.data;
                if (!isSame) {
                    if (result.data.length === 0)
                        setOldValue(newValue);
                    else setOldValue('');
                    setLength(result.data.length)
                }

                if (useInput && final.length === 0) {

                    final.push({ value: newValue.toString(), label: newValue.toString() })
                    resolve(final);
                }

                if (result.isSuccess)
                    resolve(final);
                else // hata ver.
                    resolve([])

            });
        }, 500)
    });
}


export const SelectAsync = props => {

    const [oldValue, setOldValue] = useState('');
    const [length, setLength] = useState(0);

    return <AsyncSelect
        loadOptions={(value) => promiseOptions(value || props.value, props.url, props.useInput, oldValue, setOldValue, length, setLength)}
        defaultOptions
        onChange={props.fn}
        isClearable={true}
        placeholder={props.placeholder}
        noOptionsMessage={() => props.message || 'Firma kaydi yok'}
        loadingMessage={() => 'Yükleniyor...'}
        className="tci-select-container"
        classNamePrefix="tci-select"
        value={props.value}
    />
}