import React from "react";
import Language from "./Language";
import { Logout } from "./Logout";

export const Header = () => {
    return (
        <section id="header" className="header">
            <Language />
            <Logout />
        </section>
    );
};
