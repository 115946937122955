import * as comops from "../common/comops";
import urls from "../constants/urls";

export const list = async (filters, sort, pageNumber) => {
  const payload = {
    filters,
    sort,
    pageNumber,
  };

  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  };

  const res = await comops.fetchAsync(urls.INVOICE_LIST, options);

  if (!res.isSuccess || !res.data) res = [];

  return res;
};

export const excelExport = (filters) => {
  const payload = {
    filters
  };

  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  };

  comops.postExcelApiResult(urls.INVOICE_EXCEL, options);
};
export const excelSummaryExport = (url, payload) => {

  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  };

  comops.postExcelApiResult(url, options);
};

export const detail = async (id) => {
  const payload = {
    id,
  };

  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  };

  const res = await comops.fetchAsync(urls.INVOICE_DETAIL, options);

  if (!res.isSuccess || !res.data) res = { data: { invoiceDetails: [] } };

  return res;
};

export const deleteItem = async (id) => {
  const payload = {
    id,
  };

  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  };

  const res = await comops.fetchAsync(urls.INVOICE_DELETE, options);

  return res;
};

export const save = async (payload) => {
  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  };

  const res = await comops.fetchAsync(urls.INVOICE_SAVE, options);

  return res;
};

export const future = async () => {
  const options = {
    method: "POST",
    mode: "cors",
  };

  let res = await comops.fetchAsync(urls.INVOICE_FUTURE, options);

  if (!res.isSuccess || !res.data) res = { data: [] };

  return res;
};

export const futureByAcma = async () => {
  const options = {
    method: "POST",
    mode: "cors",
  };

  let res = await comops.fetchAsync(urls.INVOICE_FUTUREBYACMA, options);

  if (!res.isSuccess || !res.data) res = { data: [] };

  return res;
};

export const futureByInsurer = async () => {
  const options = {
    method: "POST",
    mode: "cors",
  };

  let res = await comops.fetchAsync(urls.INVOICE_FUTUREBYEINSURER, options);

  if (!res.isSuccess || !res.data) res = { data: [] };

  return res;
};

export const detailDescription = async (searchQuery, id) => {
  const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({ Id: id }), // Yalnızca Id'yi body'ye gönderiyoruz
  };

  // URL'ye searchQuery'yi ekliyoruz
  const response = await comops.fetchAsync(`${urls.INVOICE_DETAILDESC}?query=${encodeURIComponent(searchQuery)}`, options);

  if (!response.isSuccess || !response.data) {
      response = { data: [] }; // Eğer veri yoksa boş bir array döndürüyoruz
  }

  return response;
};


export const deleteCache = async () => {
  const options = {
    method: "POST",
    mode: "cors",
  };

  let res = await comops.fetchAsync(urls.INVOICE_CLEARCACHE, options);

  if (!res.isSuccess || !res.data) res = { data: [] };

  return res;
};
