import React from 'react';
import { Translate } from '../common/Translate';

export const Checkbox = props => {

    const { checkChange, title, id, name, isChecked, alternateTitle } = props;
    const final = (alternateTitle && isChecked) ? alternateTitle : title;
    const fn = checkChange || (() => { });
    return (
        <div className="check-group" key={props.id}>
            <input
                type="checkbox"
                className="check-group__input"
                id={`id${id}`}
                data-id={id}
                data-group={`${name || 'onay-group'}`}
                name={`${name || 'check-generic-name'}`}
                onChange={fn}
                checked={isChecked}
            />
            <label htmlFor={`id${id}`} className="check-group__label">
                <span className="check-group__radio-button"></span>
                <span className="check-group__text"><Translate>{final || ''}</Translate></span>
            </label>
        </div>
    )
}