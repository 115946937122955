import * as comops from '../common/comops';
import urls from '../constants/urls';

export const currencies = async () => {

    const options = {
        method: 'POST'
    }
    const result = await comops.fetchAsync(urls.PARAMETER_CURRENCY, options);

    return result;
}


export const taxRates = async () => {

    const options = {
        method: 'POST'
    }
    const result = await comops.fetchAsync(urls.PARAMETER_TAXRATES, options);

    return result;
}

export const expenseTypes = async () => {

    const options = {
        method: 'POST'
    }
    let result = await comops.fetchAsync(urls.PARAMETER_EXPENSETYPES, options);
    if (!result.isSuccess || !result.data)
        result.data = [];
    return result;
}

export const expenseMainTypes = async () => {

    const options = {
        method: 'POST'
    }
    let result = await comops.fetchAsync(urls.PARAMETER_MAIN_EXPENSETYPES, options);
    if (!result.isSuccess || !result.data)
        result.data = [];
    return result;
}


export const vendors = async () => {

    const options = {
        method: 'POST'
    }

    let result = await comops.fetchAsync(urls.PARAMETER_VENDORS, options);
    if (!result.isSuccess || !result.data)
        result.data = [];

    return result;
}

export const users = async () => {

    const options = {
        method: 'POST'
    }

    let result = await comops.fetchAsync(urls.PARAMETER_USERS, options);
    if (!result.isSuccess || !result.data)
        result.data = [];

    return result;
}

export const expensePaymentSource = async () => {

    const options = {
        method: 'POST'
    }

    let result = await comops.fetchAsync(urls.PARAMETER_EXPENSEPAYMENTSOURCE, options);
    if (!result.isSuccess || !result.data)
        result.data = [];

    return result;
}

export const activityTypes = async () => {

    const options = {
        method: 'POST'
    }

    let result = await comops.fetchAsync(urls.PARAMETER_ACTIVITYTYPES, options);
    if (!result.isSuccess || !result.data)
        result.data = [];

    return result;
}





