import React from 'react';
import { Translate } from '../common/Translate';

const DetailHeader = props => {

    return (
        <div className="generic-detail__header-row">
            <div className="generic-detail__header-th generic-detail__header-th--name">
                <Translate>Product</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--quantity">
                <Translate>Quantity</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--price">
                <Translate>Price</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--tax">
                <Translate>Tax</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--total">
                <Translate>Total</Translate>
            </div>
            <div className="generic-detail__header-th generic-detail__header-th--buttons">
             
            </div>
        </div>
    )

}

export default DetailHeader;