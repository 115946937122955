import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import  businessKeys  from '../constants/businessKeys';

const useTranslation = (key) => {

    const [result, setResult] = useState(null);
    const dictionary = useSelector(state => state.main.tciDictionary);
    const culture = useSelector(state => state.main.culture);

    useEffect(() => {
        const item = dictionary.find(t => t.tciKey === key) || {};
        const result = culture === businessKeys.enUs ? item.english : item.turkish;
        setResult(result || key);
    }, [key, dictionary]);

    return result;
}

export default useTranslation;