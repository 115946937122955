import React from 'react';
import { formatNumber } from 'accounting';
import { Translate } from '../common/Translate';

const FormattedDecimal = props => {

    const { value, abb, text, className, firstText } = props

    return (
        <span className={className}>
            {firstText && <Translate>{firstText}</Translate>}{text && text}{formatNumber(Math.trunc(value), {
                decimal: ".",
                thousand: ",",
                precision: 0
            })}.<small>{formatNumber(Math.abs(value - Math.trunc(value)) * 100).toString().padStart(2, '0')}</small> {abb}
        </span>
    )
}

export default FormattedDecimal;