import React from 'react';
import { useSelector } from 'react-redux';
import { activityTypes } from '../apis/parameter';
import SelectSync from './SelectSync';

const ActivityTypeSelect = props => {

    const options = useSelector(state => state.main.activityTypes);

    return (
        <SelectSync
            className={props.className}
            changeFn={props.setValue}
            optionsFn={activityTypes}
            value={props.value}
            placeholder='Hareket Tur'
            options = {options}
        />
    )
}

export default ActivityTypeSelect;