import React from "react";
import { Invoice } from "./Invoice";
import { InvoiceHeader } from "./InvoiceHeader";
import InvoiceContext from '../contexts/invoice-context';

export const Invoices = (props) => {
  const { data, excelPayload, excelUrl } = props;


  return (
    <>
      <InvoiceHeader excelPayload={excelPayload} excelUrl={excelUrl} />
      <div className="invoices">
        {data.map((item) => {
          return <Invoice item={item} key={`key_${item.id}`} />;
        })}       
        
      </div>
    </>
  );
};
