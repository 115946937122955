import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import { useHistory } from 'react-router-dom';
import ExpensePaymentContext from '../contexts/expense-payment-context';
import { saveAll } from './duck/actions';
import { useDispatch } from 'react-redux';
import Loading from '../common/Loading';

const ExpenseButton = props => {

    const history = useHistory();
    const { data, expenseId, setGetData, updating, setUpdating, setActive } = useContext(ExpensePaymentContext);
    const dispatch = useDispatch();

    const savePayment = () => {
        if (updating) return;
        const payload = {
            expenseId,
            expensePaymentPayloads: data
        }
        setUpdating(true);
        dispatch(saveAll(payload)).then(res => {
            setGetData(true);
            setActive(-1);
        }).finally(() => setUpdating(false));
    }

    return (
        <div className="payment-general__row">
            <div className="payment-general__buttons">
                <button className="btn btn-white" onClick={() => history.goBack()}><Translate>Back</Translate></button>
                <button className="btn" onClick={savePayment}>{updating ? <Loading /> : <Translate>Update</Translate>}</button>
            </div>
        </div>
    )
}

export default ExpenseButton;