
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Translate } from '../common/Translate';
import FormattedDecimal from '../common/FormattedDecimal';
import { deleteInvoice } from './duck/actions';

import React, { useState, useEffect, useCallback } from 'react';

export const Invoice = props => {

    const { item } = props;
    const dispatch = useDispatch();
    const lang = useSelector(state => state.main.culture)
    const del = e => {
        e.preventDefault();
        dispatch(deleteInvoice(item.id))
    };
    const isOverdued = item.overdued && item.collectionTotal < item.invoiceTotal ? ' overdued' : (item.notOverdueddaysUsd > 0 ? ' waiting' : '');;

    const remaining = item.invoiceGrandTotal - item.collectionTotal;

    const filters = useSelector(state => state.filter)

    const [localFilters, setLocalFilters] = useState(filters);
    useEffect(() => {
        setLocalFilters(filters);
      }, [filters]);

    // console.log(filters,"filters");

    return (
        <Link to={`/invoice/${item.id}`} className={`invoices__item${isOverdued}`}>
            <div className={`invoices__item-image`}>
                <svg className="icon invoices__item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-file-text"></use>
                </svg>
            </div>
            <div className="invoices__item-definition">
                <div className="invoices__item-definition--top">
                    <span className="nvoices__item-definition--top invoices__item-definition--invoice-definition">
                        {item.invoiceDefinition}
                    </span>
                </div>
                <div className="invoices__item-definition--bottom">
                    <span className={`invoices__item-definition--bottom invoices__item-definition--insurer ${item.insurer?.toLowerCase().replace(/\s/g, '-')}`} >
                        {item.insurer}
                    </span>
                    <span className="invoices__item-definition--bottom invoices__item-definition--acma">
                        {item.policyOwner}
                    </span>

                    <span className="invoices__item-definition--bottom invoices__item-definition---">
                        {item.accountName}
                    </span>
                    {item.subAgent &&
                        <span className="invoices__item-definition--bottom invoices__item-definition--subagent">
                            {item.subAgent}
                        </span>
                    }
                    {item.source &&
                        <span className="invoices__item-definition--bottom invoices__item-definition--source">
                            {item.source}
                        </span>
                    }
                </div>
            </div>
            <div className="invoices__item-date invoices__item-arrangement">
                <span className="invoices__item-date--date">
                    {moment(item.arrangementDate).locale(lang).format('DD MMMM YYYY')}
                </span>
            </div>
            { <div className="invoices__item-date invoices__item-paymentDueDate">
                <span className="invoices__item-date--date">
                    {item.paymentDueDate ? moment(item.paymentDueDate).locale(lang).format('DD MMMM YYYY') : ""}
                </span>
            </div> }
            <div className="invoices__item-date invoices__item-due">
                <span className="invoices__item-date--date">
                    {moment(item.dueDate).locale(lang).format('DD MMMM YYYY')}
                </span>
                <span className="invoices__item-date--text">
                    ({item.dayDifference} <Translate>{item.overdued ? 'InvoiceOverdued' : 'InvoiceNotOverdued'}</Translate>)
                </span>
            </div>
            <div className="invoices__item-amount">
                <FormattedDecimal text="" value={remaining} abb={item.currencyAbbreviation} className="invoices__item-amount--main" />
                <FormattedDecimal text=": " value={item.invoiceGrandTotal} abb={item.currencyAbbreviation} className="invoices__item-amount--total" firstText='Toplam' />
            </div>
            <div className="invoices__item-button middle">
                <svg className="icon icon-bin red" onClick={del}>
                    <use xlinkHref="../img/sprite.svg#icon-bin"></use>
                </svg>
            </div>
        </Link>
    )
}