import * as comops from '../common/comops'
import urls from '../constants/urls'

export const save = async (payload) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.PAYMENT_SAVE, options);

    return res;
}


export const list = async (invoiceId) => {

    const payload = {
        invoiceId
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.PAYMENT_LIST, options);

    if (!res.isSuccess || !res.data)
        res = [];

    return res;
}