import React, { useContext } from 'react';
import ExpenseContext from '../contexts/expense-context';
import DecimalNumber from '../common/DecimalNumber';
import TaxSelect from '../select/TaxSelect';
import { Translate } from '../common/Translate';
import { unformat, formatNumber } from 'accounting';
import { selectValue } from '../common/comops';
import dictionary from '../constants/dictionary';

const ExpenseAmount = (props, ref) => {

    const { taxRate, setTaxRate, data } = useContext(ExpenseContext);

    const amount = ((ref.current && unformat(ref.current.value)) ? unformat(ref.current.value) : (data.amount || 0));

    return (
        <div className="expense-general__row narrow">
            <div className="expense-general__label">
                <svg className="expense-general__label--icon icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-credit-card"></use>
                </svg>
                <span className="expense-general__label--label">
                    <Translate>{dictionary.KDV_INCLUDED_AMOUNT}</Translate>
                </span>
            </div>
            <div className="expense-general__input double-flex">
                <DecimalNumber className="generic-detail__price" id="expense-amount" ref={ref} pre={2} defaultValue={formatNumber(data.amount, 2) || 0} />
                <TaxSelect value={taxRate} setValue={setTaxRate} />
            </div>
            <div className="expense-general__extra">
                <span>= {formatNumber(amount / (1 + (selectValue(taxRate) * 0.01)), 2)} {data.abbreviation}</span>
            </div>
        </div>
    )
}

export default React.forwardRef(ExpenseAmount);