import React, { memo } from 'react';

const ListItem = memo(props => {

    const { item } = props;

    return (
        <div className="list-account__row">
            <div className="list-account__cell list-account__cell--name">
                <span className="list-account__span">{item.accountName}</span>
            </div>
            <div className="list-account__cell list-account__cell--identification">
                <span className="list-account__span">{item.identificationNumber}</span>
            </div>
            <div className="list-account__cell list-account__cell--account-manager">
                <span className="list-account__span">{item.accountOwner}</span>
            </div>
            <div className="list-account__cell list-account__cell--city">
                <span className="list-account__span">{item.city}</span>
            </div>
        </div>
    )
})

export default ListItem;