import React, { useEffect, useState } from 'react';
import { futureByAcma } from '../apis/invoice';
import useStateIfMounted from '../hook/useStateIfMounted';
import Loading from "../common/Loading";
import StatusTable from "./StatusTable";

const AcmaCollections = props => {

    const [data, setData] = useStateIfMounted([]);
    const [loading, setLoading] = useStateIfMounted(true);

    useEffect(() => {
        futureByAcma().then(result => setData(result.data)).finally(() => setLoading(false));
    }, []);

    return (
        loading ? <Loading className="medium" /> : <StatusTable data={data} datakey="acma" header="Acma Kirilimi" />
    )

}

export default AcmaCollections;