import types from './types';

export const setAktif = aktif => {
    return {
        type: types.LEFTMENU_SETAKTIF,
        aktif
    }
} 

export const setSubAktif = subAktif => {
    return {
        type: types.LEFTMENU_SETSUBAKTIF,
        subAktif
    }
} 