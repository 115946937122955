import React from 'react';
import { useSelector } from 'react-redux';

export const SortObject = props => {

    const sort = useSelector(state => state.filter.sort);

    const { column, screen } = props;

    if (sort.column !== column || sort.screen !== screen) {
        return <></>
    }

    const iconName = `../img/sprite.svg#icon-circle-${sort.direction === 'ASC' ? 'down' : 'up'}`

    return (<svg className="icon sort-icon">
        <use xlinkHref={iconName}></use>
    </svg>)
}