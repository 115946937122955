import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import ExpenseContext from '../contexts/expense-context';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ExpenseTypeSelect from '../select/ExpenseTypeSelect';
import PeriodSelect from '../select/PeriodSelect';
import { openExpensePaymentPage } from './duck/actions';

const ExpenseTypeAndPeriod = props => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { expenseType, singlePeriod, setExpenseType, setSinglePeriod, expenseId } = useContext(ExpenseContext);
    return (
        <div className="expense-general__row narrow borderless">
            <div className="expense-general__label">

            </div>
            <div className="expense-general__input double-flex">
                <ExpenseTypeSelect value={expenseType} setValue={setExpenseType} />
                <PeriodSelect value={singlePeriod} setValue={setSinglePeriod} />
            </div>
            <div className="expense-general__buttons">
                <button className="btn" onClick={() => dispatch(openExpensePaymentPage(history, expenseId))}><Translate>ExpensePayment</Translate></button>
            </div>
        </div>
    )
}

export default ExpenseTypeAndPeriod;