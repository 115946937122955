import React from 'react';
import { Main } from "../app/main/Main";
import ExpensePayment from '../app/expensePayment/ExpensePayment';

const ExpensePaymentPage = props => {

    const expenseId = parseInt(props.match.params.expenseId);
    return (<Main>
        <ExpensePayment expenseId={expenseId} />
    </Main>
    )
}

export default ExpensePaymentPage;