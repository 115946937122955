import React from 'react';
import { Main } from "../app/main/Main";
import {Login} from '../app/login/Login';

export const LoginPage = props => {

    return (<Main>
        <Login/>
    </Main>
    )
}