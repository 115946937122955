import React from 'react';
import { Main } from "../app/main/Main";
import CreateInvoice from '../app/invoice/CreateInvoice';

const InvoicePage = props => {

    const invoiceId = parseInt(props.match.params.invoiceId);
    return (<Main>
     <CreateInvoice invoiceId={invoiceId}/>
    </Main>
    )
}

export default InvoicePage;