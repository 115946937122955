import React from 'react';
import Collections from './Collections';
import InsurerCollection from './InsurerCollections';
import AcmaCollections from './AcmaCollections';
import Payments from './Payments';
import AcmaPayments from './AcmaPayments';
import ExpenseTypePayments from './ExpenseTypePayments';

const CurrentStatus = props => {
    return (
        <div className="current-status">
            <div className="current-status__row current-status__collections" >
                <Collections />
                <InsurerCollection />
                <AcmaCollections />
            </div>
            <div className="current-status__row current-status__collections" >
                <Payments />
                <ExpenseTypePayments />
                <AcmaPayments />
            </div>
        </div>)
}

export default CurrentStatus;