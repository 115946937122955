import types from './types'

const INITIAL_STATE = {
    selectedFilters: [],// [{ "dataName": "Collection", "displayName": "Collection", "type": "DropDown", "selectList": [{ "value": 1, "label": "VadesiGelmemis" }, { "value": 2, "label": "VadesiGecmis" }, { "value": 3, "label": "VadesiYok" }, { "value": 4, "label": "TahsilEdilmis" }] }],
    selectedFilterValues: [],
    filterActive: false,
    firstDate: new Date(),
    secondDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    pickerIsActive: false,
    pickerDataName: '',
    activeFilter: '',
    getValues: false,
    sort: {
        screen: '',
        column: '',
        direction: ''
    },
    page: {
        screen: '',
        pageNumber: 0
    }

}

const filterReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case types.FILTER_INITIALIZE:
            {
                const { currentPage, screen } = action;

                const sortObject = {
                    screen: screen,
                    column: '',
                    direction: ''
                };
                const pageObject = (currentPage && currentPage.screen) ? currentPage : {
                    screen: screen,
                    pageNumber: 0
                };

                return { ...state, sort: sortObject, page: pageObject, selectedFilters: [], selectedFilterValues: [], getValues: true }
            }
        case types.FILTER_SETSELECTEDFILTER:
            {

                const selectedCount = state.selectedFilterValues.length;
                const { selectedFilters } = action;
                const dataNames = selectedFilters.map(item => item.dataName);
                const selectedFilterValues = [...state.selectedFilterValues].filter(item => dataNames.includes(item.dataName));
                const getValues = selectedCount !== selectedFilterValues.length ? true : state.getValues;
                return { ...state, selectedFilters, selectedFilterValues, getValues }
            }
        case types.FILTER_SETSELECTEDFILTERANDVALUE:
            {
                const { selectedFilters, getValues, selectedValues, status } = action;
                const pickerIsActive = status ? false : state.pickerIsActive;
                const pickerDataName = status ? '' : state.pickerDataName;
                const page = {...state.page};
                page.pageNumber = 0;

                return { ...state, selectedFilters, page, selectedFilterValues: selectedValues, getValues, filterActive: status, pickerIsActive, pickerDataName }
            }
        case types.FILTER_TOGGLEACTIVE:
            {
                const { status } = action;
                const pickerIsActive = status ? false : state.pickerIsActive;
                const pickerDataName = status ? '' : state.pickerDataName;
                return { ...state, filterActive: status, pickerIsActive, pickerDataName }
            }
        case types.FILTER_CHANGESELECTEDVALUES:
            {
                const { selectedFilterValues, getValues } = action;
                return { ...state, selectedFilterValues, getValues }
            }
        case types.FILTER_SETDATES:
            {
                const { firstDate, secondDate } = action;
                return { ...state, firstDate, secondDate }
            }
        case types.FILTER_PICKERACTIVE:
            {
                const { active } = action;
                const pickerName = !active ? '' : state.pickerDataName;
                return { ...state, pickerIsActive: active, pickerDataName: pickerName }
            }
        case types.FILTER_PICKERDATANAME:
            {
                const { dataName } = action;
                return { ...state, pickerDataName: dataName }
            }
        case types.FILTER_RESETPICKER:
            {
                const { dataName, active } = action;
                return { ...state, pickerDataName: dataName, pickerIsActive: active }
            }
        case types.FILTER_CHANGEACTIVE:
            {
                const { dataName } = action;
                return { ...state, activeFilter: dataName }
            }
        case types.FILTER_GETVALUE:
            {
                const { getValues } = action;
                return { ...state, getValues }
            }
        case types.FILTER_SETORDER:
            {
                const { sortScreen, sortColumn } = action;

                const sortDirection = state.sort.screen === sortScreen
                    && sortColumn === state.sort.column
                    && state.sort.direction === 'OrderBy' ? 'OrderByDescending' : 'OrderBy';

                const sort = {
                    screen: sortScreen,
                    column: sortColumn,
                    direction: sortDirection
                };

                return { ...state, sort, getValues: true }
            }
        case types.FILTER_PAGENUMBER:
            {
                const { screen, pageNumber } = action;
                const page = {
                    screen,
                    pageNumber
                };
                return { ...state, page, getValues: true }
            }
        default:
            return state
    }
}
export default filterReducer;