import React, { useContext } from 'react';
import ExpenseContext from '../contexts/expense-context';
import { Translate } from '../common/Translate';
import { useDispatch } from 'react-redux';
import { saveAll } from './duck/actions';
import { useHistory } from 'react-router-dom';
import { unformat } from 'accounting';
import { selectValue } from '../common/comops';
import Loading from '../common/Loading';

const ExpenseDescription = props => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { data, policy, arrangement, account, currency, refPrice, expenseType, dueDate, vendor, taxRate,
        updating, setUpdating, setGetData, setActiveInstallment, genel, user, expenseId, singlePeriod } = useContext(ExpenseContext);

    const payload = {
        id: expenseId,
        expenseTypeId: selectValue(expenseType),
        expenseDescription: '',
        amount: refPrice.current ? unformat(refPrice.current.value) : 0,
        currencyId: selectValue(currency),
        policyId: (policy || {}).value,
        isReturn: false,
        isAp:false,
        invoiceNumber: '',
        accountId: (account || {}).value,
        dueDate: dueDate,
        arrangementDate: arrangement,
        vendorId: selectValue(vendor),
        taxRate: selectValue(taxRate),
        installments: data.installments,
        offSet: new Date().getTimezoneOffset(),
        genel,
        accountManagerId: selectValue(user),
        expensePeriod: selectValue(singlePeriod)
    };

    const save = () => {
        if (updating) return;
        setUpdating(true);
        dispatch(saveAll(payload, history, setGetData)).then(res => { setActiveInstallment(-1); }).finally(() => setUpdating(false));
    }

    return (
        <div className="expense-general__row">
            <div className="expense-general__label">
                <svg className="expense-general__label--icon icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-file-empty"></use>
                </svg>
                <span className="expense-general__label--label">
                    <Translate>ExpenseDescription</Translate>
                </span>
            </div>
            <div className="expense-general__input">
                <input type="text" className="expense-general__input--text" id="input-expense-definition" defaultValue={data.expenseDescription}></input>
            </div>
            <div className="expense-general__buttons">
                <button className="btn btn-white" onClick={() => history.push("/expense-list")}><Translate>Back</Translate></button>
                <button className="btn" onClick={save}>{updating ? <Loading /> : <Translate>Update</Translate>}</button>
            </div>
        </div>
    )
}

export default ExpenseDescription;