import React, { useEffect } from 'react';
import { Combo } from "./Combo";
import { FilterText } from "./FilterText";
import { FilterList } from './FilterList';
import { useSelector, useDispatch } from 'react-redux';
import { setDates, setFilterActive, changeActive, setPickerActive, setPickerDataName } from './duck/actions';
import { GenericFilter } from './GenericFilter';
import { TciDatePicker } from '../datePicker/TciDatePicker';
import { SimplePick } from '../datePicker/SimplePick';
import useStateIfMounted from '../hook/useStateIfMounted';

export const Filter = props => {

    const [filters, setFilters] = useStateIfMounted([]);
    const [loading, setLoading] = useStateIfMounted(false);
    const filterActive = useSelector(state => state.filter.filterActive);
    const firstDate = useSelector(state => state.filter.firstDate);
    const secondDate = useSelector(state => state.filter.secondDate);
    const pickerIsActive = useSelector(state => state.filter.pickerIsActive);
    const pickerDataName = useSelector(state => state.filter.pickerDataName);


    const setFirstDate = (value) => dispatch(setDates(value, secondDate));
    const setSecondDate = (value) => dispatch(setDates(firstDate, value));
    const setBothDates = (first, second) => dispatch(setDates(first, second));

    const dispatch = useDispatch();
    const setActive = () => {
        if (!filterActive)
            dispatch(changeActive('main-filter'));
        dispatch(setFilterActive(!filterActive));
    }

    useEffect(() => {
        setLoading(true);
        props.fn().then(res => {
            setFilters(res.data);
        }).finally(() => {
            setLoading(false);
        });

    }, [])

    const pickerClass = `filter__date-picker${pickerIsActive ? ' active' : ''}`;

    return (
        <div className="filter-container">
            <div className="filter">
                <Combo fn={setActive} />
                <GenericFilter />
                <FilterText {...props} />
                {props.buttons || <></>}
            </div>
            {filterActive && <FilterList active={filterActive} filters={filters} setActive={setActive} textFieldName={props.textFieldName} />}
            <div className={pickerClass}>
                {pickerIsActive && <TciDatePicker isSecond={false} startDate={firstDate} setStartDate={setFirstDate} />}
                {pickerIsActive && <TciDatePicker isSecond={true} startDate={secondDate} setStartDate={setSecondDate} baseDate={firstDate} header="EndDate" />}
                {pickerIsActive && <SimplePick setBothDates={setBothDates} dataName={pickerDataName} />}
            </div>
        </div>
    )
}