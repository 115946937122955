import React, { useEffect, useState } from 'react';
import { future } from '../apis/invoice';
import EkoPieChart from '../charts/EkoPieChart';
import useStateIfMounted from '../hook/useStateIfMounted';
import Loading from '../common/Loading';


const Collections = props => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useStateIfMounted([]);
    const [data2, setData2] = useStateIfMounted([]);
    const [mainLabel, setMainLabel] = useStateIfMounted({ name: 'noname', value: 300, color: '#999593' });

    useEffect(() => {
        future().then(result => {
            const final = [...result.data];
            setData(final);
            if (final.length > 1) {
                const final2 = [...result.data];
                final2[1] = { name: 'noname', value: final[1].value, valueUsd: final[1].valueUsd, color: '#999593' }
                setData2(final2);
                setMainLabel({ name: '', value: final2[0].value, color: final2[0].color });
            }

        }).finally(() => setLoading(false));
    }, [])

    return (
        loading ? <Loading className="medium" /> :
            <>
                <EkoPieChart data={data} header="Toplam Tahsilat" />
                <EkoPieChart data={data2} header="Gecikmis Tahsilat" mainLabel={mainLabel} />
            </>
    )
}


export default Collections;