import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Translate } from '../common/Translate';
import { setDateValue } from './duck/actions';

export const DateElement = props => {

    const { element, dataName, setActive } = props;
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const dispatch = useDispatch();
    const value = `${moment(element.firstDate).format('YYYYMMDD')}-${moment(element.lastDate).format('YYYYMMDD')}`;
    const onItemClick = () => { dispatch(setDateValue(selectedFilterValues, value, dataName)); setActive();};
    const isCurrent = element.year === new Date().getFullYear() && element.month === (new Date().getMonth() + 1)

    const selectedFilter = selectedFilterValues.find(item => item.dataName === dataName);
    let selectedValue = '';
    if (selectedFilter) {
        selectedValue = selectedFilter.selectedValues[0];
    }

    const additionalClass = value === selectedValue ? ' selected' : '';

    const year = new Date().getFullYear() !== element.year ? element.year.toString() : '';

    // value olarak baslangic ve bitis tarihi gondermek gerekiyor 'yyyymmdd' seklinde gondermek gerekiyor.
    // Burda onClick koymak gerekiyor..
    // Ayrica daha fazla ve daha az'a basma olaylarini belirlemek lazim.s

    return (
        
        <li className={`selected-filter__item datetime${additionalClass}`} onClick={onItemClick.bind(this)}>
            <span className="selected-filter__text">{year} <Translate>{element.displayName}</Translate> {isCurrent && <Translate>CurrentMonth</Translate>}</span>
        </li>
    )

}
