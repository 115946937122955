import React from 'react';
import { Main } from "../app/main/Main";
import SalesExpenseContainer from '../app/salesExpense/SalesExpenseContainer';

const SalesExpensePage = props => {

    return (<Main>
        <SalesExpenseContainer />
    </Main>
    )
}

export default SalesExpensePage;