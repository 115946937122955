import types from './types';
const INITIAL_STATE = {
    aktif: 'guncel-durum',
    subAktif: ''
}


const leftMenuReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case types.LEFTMENU_SETAKTIF: {
            const { aktif } = action;
            return { ...state, aktif }
        }
        case types.LEFTMENU_SETSUBAKTIF: {
            const { subAktif } = action;
            return { ...state, subAktif }
        }
        default:
            return state;
    }

}


export default leftMenuReducer;