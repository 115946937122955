import React, { useEffect, useState } from 'react';
import DatetPicker from 'react-datepicker';
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";

/* const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz'];

const locale = {
    localize: {
        month: n => months[n],
        day: n => days[n]
    },
    formatLong: {},
    match: {}
} */


const culture = localStorage.culture === 'tr-Tr' ? tr : en; 

const TciSimpleDate = props => {

    const { startDate, setStartDate } = props;
    const dateClass = props.className ? ` ${props.className}` : '';

    return (


        <div className={`tci-simple-date${dateClass}`}>
            <DatetPicker
                selected={new Date(startDate)}
                onChange={date => setStartDate(date)}
                locale={culture}
                dateFormat="dd/MM/yyyy"
            />
            <svg className="tci-simple-date__icon icon">
                <use xlinkHref="../img/sprite.svg#icon-calendar"></use>
            </svg>
        </div>)

}

export default TciSimpleDate;