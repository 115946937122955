import React from 'react';
import { Main } from "../app/main/Main";

export const PageNotFoundPage = props => {

    return (<Main>
        <div>
            <h2>Sayfa bulunamadi</h2>
        </div>
    </Main>
    )
}