import React from 'react';
import { Translate } from '../common/Translate';
import { useDispatch , useSelector} from 'react-redux';
import { removeFilter } from './duck/actions';

export const SelectedCombo = props => {

    const selectedFilters = useSelector(state => state.filter.selectedFilters);
    const { item, setActive } = props;
    const dispatch = useDispatch();
    const filterRemove = () => dispatch(removeFilter(selectedFilters, item));

    return (<div className="selected-filter__combo-item" onClick={setActive}>
        {
            <span className="selected-filter__combo-item-text"><Translate>{item.displayName}</Translate></span>
        }
        <svg className="icon icon-user selected-filter__combo-item-icon">
            <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
        </svg>
        <span className="selected-filter__combo-item-times" onClick={filterRemove}>
            &times;
        </span>
    </div>)
}