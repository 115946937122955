import types from './types'

const INITIAL_STATE = {
    isOpen: false

}

const headerReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        
        case types.MAIN_SETKATKILI:
            {
                const { lastGeneric } = action;
                return { ...state, lastGeneric }
            }
        default:
            return state
    }
}
export default headerReducer;