import React from 'react';
import Activity from './Activity';
import ActivityHeader  from './ActivityHeader';

const Activities = props => {

    const { data, loading } = props;

    return <>
        <ActivityHeader />
        <div className="activities">
            {data.map(item => {
                return <Activity item={item} key={`key_${item.id}`} />
            })}
        </div>
    </>
}

export default Activities;