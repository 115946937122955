import types from './types';

const INITIAL_STATE = {
    selected: {}
    
    /* {
        id: 1,
        activityDescription: "BANKA KOMISYONU",
        debitAmount: 0.00,
        creditAmount: 2.12,
        activityDate: "2020-12-24T00:00:00",
        activityType: 61,
        invoicePaymentName: null,
        expensePaymentName: null,
        sourceBankName: null,
        relatedBankId: null,
        invoicePaymentId: null,
        expensePaymentId: null,
        balance: 86257.88,
        activityTypeName: "Hesaba para çikisi",
        abbreviation: "₺"
    } */
}

const activityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ACTIVITY_SET_SELECTED: {
            const { selected } = action;
            return { ...state, selected };
        }
        default:
            return state;
    }
}

export default activityReducer;
