import React, {  useEffect } from 'react';
import { DropDownElement } from './DropDownElement';
import { SelectedCombo } from './SelectedCombo';
import { useSelector, useDispatch } from 'react-redux';
import { changeActive, setPickerActive, setPickerDataName } from './duck/actions';
import useStateIfMounted from '../hook/useStateIfMounted';

export const DropDownFilter = props => {

    const { item, index } = props;
    const [active, setActive] = useStateIfMounted(false);
    const dispatch = useDispatch();
    const activeFilter = useSelector(state => state.filter.activeFilter);
    const mainClass = `selected-filter${active ? ' active' : ''}`

    useEffect(() => {
        if (active) {
            dispatch(changeActive(item.dataName));
        }

    }, [active]);

    useEffect(() => {
        if (activeFilter !== item.dataName && active) {
            setActive(false);
        }

    }, [activeFilter])

    return <div className={mainClass} >
        <div className="selected-filter__combo">
            <SelectedCombo item={item} setActive={() => {
                if (!active) {
                    dispatch(setPickerActive(false));
                    dispatch(setPickerDataName(''));
                }
                setActive(!active);
            }} />
        </div>
        <div className="selected-filter__list-container" style={{ zIndex: index }}>
            <ul className="selected-filter__list">
                {item.selectList.map(element => <DropDownElement key={`el_${element.value}`} element={element} dataName={item.dataName} />)}
            </ul>
        </div>
    </div>
}