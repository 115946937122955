import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import InvoiceArrangement from './InvoiceArrangement';
import InvoiceDefinition from './InvoiceDefinition';
import InvoicePolicy from './InvoicePolicy';
import InvoiceNumberCurrency from './InvoiceNumberCurrency';
import useStateIfMounted from '../hook/useStateIfMounted';
import DetailRows from './DetailRows';
import DetailHeader from './DetailHeader';
import InvoiceTotalPart from './InvoiceTotalPart';
import InvoiceAccount from './InvoiceAccount';
import InvoviceContext from '../contexts/invoice-context';
import { detail } from '../apis/invoice';
import { selectValue } from '../common/comops';
import Loading from '../common/Loading';
import InvoiceInstallments from './InvoiceInstallments';
import businessKeys from '../constants/businessKeys';

const setExchangeRate = (rate) => {
    const element = document.querySelector(`#${businessKeys.INVOICE_EXCHANGE_RATE}`);
    if (element)
        element.value = rate.toString();
}

const CreateInvoice = props => {

    const [loading, setLoading] = useState(false);
    const [policy, setPolicy] = useState({});
    const [arrangement, setArrangement] = useState(new Date());    
    const [due, setDue] = useState(new Date());
    const [currency, setCurrency] = useState({});
    const [taxRate, setTaxRate] = useState({ value: 0, label: '0%' });
    const [data, setData] = useStateIfMounted({ invoiceDetails: [], installments: [] });
    const [account, setAccount] = useState({ invoiceDetails: [] });
    const [active, setActive] = useState(-1);
    const [showEmpty, setShowEmpty] = useState(false);
    const [getData, setGetData] = useState(true);
    const [insurer, setInsurer] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [activeInstallment, setActiveInstallment] = useState(-1);
    const policyValue = selectValue(policy);
    const currencies = useSelector(state => state.main.currencies);
    const [genel, setGenel] = useState(false);





    useEffect(() => {
        if (!getData || !props.invoiceId) {
            if (getData)
                setGetData(false);
            return;
        };
        setLoading(true);
        setGetData(false);
        detail(props.invoiceId).then(result => {
            setData(result.data);
            if (result.data.dueDate)
                setDue(new Date(result.data.dueDate));
            if (result.data.arrangementDate)
                setArrangement(new Date(result.data.arrangementDate));
            if (result.data.currencyId)
                setCurrency(result.data.currencyId);
            const policyId = (result.data || {}).policyId;
            if (policyId) {
                setPolicy({ value: policyId, label: result.data.policyName });
            }
            setGenel(result.data.genel || false);
            const accountId = (result.data || {}).accountId;

            if (accountId) {
                setAccount({ value: accountId, label: result.data.accountName });
            }
            if (data.exchangeRate) {
                setExchangeRate(data.exchangeRate);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [, getData]);

    useEffect(() => {
        if (!selectValue(currency)) {
            const selectedCurrency = currencies.find(item => item.value === policy.intHelper);
            setCurrency(selectedCurrency);
        }
    }, [policyValue]);

    useEffect(() => {
        setInsurer(((policy || {}).intHelper2 || data.insurerId));
    }, [policyValue, data.insurerId]);

    useEffect(() => {

        // Eğer currency değişirse ona gore atiyor.
        if (currency && (data.currency !== selectValue(currency) || !data.exchangeRate) && currency.decimalHelper) {
            setExchangeRate(currency.decimalHelper);
        } else if (data.exchangeRate) {
            setExchangeRate(data.exchangeRate);
        }
    }, [currency]);

 

   
    return (
        <InvoviceContext.Provider value={{
            policy, setPolicy, arrangement, setArrangement, due, setDue,
            currency, setCurrency, taxRate, setTaxRate, data, active, setActive, showEmpty, setShowEmpty,
            setData, setGetData, invoiceId: props.invoiceId, activeInstallment, setActiveInstallment, account, setAccount,
            insurer, updating, setUpdating, genel, setGenel
        }}>
            <div className="create-invoice">
                {loading ? <Loading className="big" /> : <>
                    <div className="invoice-general">
                        <InvoiceDefinition />
                        <InvoiceNumberCurrency />
                        <InvoicePolicy data={data} />
                        <InvoiceAccount />
                        <InvoiceArrangement startDate={arrangement} setStartDate={setArrangement} />
                        <InvoiceInstallments />
                        {/*  <InvoiceDueMainSelection startDate={due} setStartDate={setDue} /> */}
                        {/*  <InvoiceDue startDate={due} setStartDate={setDue} /> */}

                    </div>

                    <div className="generic-detail">
                        <DetailHeader />
                        <DetailRows />
                        <InvoiceTotalPart />
                    </div></>}
            </div>
        </InvoviceContext.Provider >
    )
}

export default CreateInvoice;
