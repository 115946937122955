import types from './types'

const INITIAL_STATE = {
    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
    confirmation: true,
    isOpen: false,
    functions: {
        okClicked: () => { },
        cancelClicked: () => { }
    }
}

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.MODAL_TOGGLE: {
            const { status } = action;
            return { ...state, isOpen: status }
        }
        case types.MODAL_OPEN: {
            const { modalInput } = action;
            return { ...state, ...modalInput };
        }
        default:
            return state;
    }
}

export default modalReducer