import React from 'react';
import { Link } from 'react-router-dom';
import { Income } from './Income';
import { Expense } from './Expense';
import { Bank } from './Bank';
import { MetaData } from './MetaData';
import { Reports } from './Reports';
import { Translate } from '../common/Translate';
import { useDispatch, useSelector } from 'react-redux';
import { setAktif, setSubAktif } from './duck/actions';


export const LeftMenu = props => {

    const dispatch = useDispatch();
    const setEkran = ekran => dispatch(setAktif(ekran));
    const setSubEkran = subEkran => dispatch(setSubAktif(subEkran));
    const ekran = useSelector(state => state.leftMenu.aktif);
    const subEkran = useSelector(state => state.leftMenu.subAktif);

    return (
        <div className="left-menu">
            <nav className="left-menu__navigation">
                <ul className="first-level">
                    <li className={`first-level__item${(ekran === 'guncel-durum' ? ' active' : '')}`}>
                        <Link className="first-level__display" to="/current-status" onClick={e => { setEkran('guncel-durum'); }}>
                            <svg className="icon first-level__icon">
                                <use xlinkHref="../img/sprite.svg#icon-file-text"></use>
                            </svg>
                            <span className="first-level__span">
                                <Translate>Güncel Durum</Translate>
                            </span>
                        </Link>
                    </li>
                    <li className={`first-level__item${(ekran === 'satislar' ? ' active' : '')}`}>
                        <Link className="first-level__display" to="#" onClick={e => { e.preventDefault(); setEkran('satislar'); }} >
                            <svg className="icon first-level__icon">
                                <use xlinkHref="../img/sprite.svg#icon-box-add"></use>
                            </svg>
                            <span className="first-level__span">
                                <Translate>Satislar</Translate>
                            </span>
                            <svg className="icon first-level__icon circle">
                                <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
                            </svg>
                        </Link>
                        <Income subAktif={subEkran} setSubAktif={setSubEkran} />
                    </li>
                    <li className={`first-level__item${(ekran === 'giderler' ? ' active' : '')}`}>
                        <Link className="first-level__display" to="#" onClick={e => { e.preventDefault(); setEkran('giderler'); }}>
                            <svg className="icon first-level__icon">
                                <use xlinkHref="../img/sprite.svg#icon-box-remove"></use>
                            </svg>
                            <span className="first-level__span">
                                <Translate>Giderler</Translate>
                            </span>
                            <svg className="icon first-level__icon circle">
                                <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
                            </svg>
                        </Link>
                        <Expense subAktif={subEkran} setSubAktif={setSubEkran} />
                    </li>
                    <li className={`first-level__item${(ekran === 'bankahareketleri' ? ' active' : '')}`}>
                        <Link className="first-level__display" to="#" onClick={e => { e.preventDefault(); setEkran('bankahareketleri'); }}>
                            <svg className="icon first-level__icon">
                                <use xlinkHref="../img/sprite.svg#icon-calculator"></use>
                            </svg>
                            <span className="first-level__span">
                                <Translate>Banka</Translate>
                            </span>
                            <svg className="icon first-level__icon circle">
                                <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
                            </svg>
                        </Link>
                        <Bank subAktif={subEkran} setSubAktif={setSubEkran} />
                    </li>
                    <li className={`first-level__item${(ekran === 'veriler' ? ' active' : '')}`}>
                        <Link className="first-level__display" to="#" onClick={e => { e.preventDefault(); setEkran('veriler'); }}>
                            <svg className="icon first-level__icon">
                                <use xlinkHref="../img/sprite.svg#icon-database"></use>
                            </svg>
                            <span className="first-level__span">
                                <Translate>Ek Veri</Translate>
                            </span>
                            <svg className="icon first-level__icon circle">
                                <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
                            </svg>
                        </Link>
                        <MetaData subAktif={subEkran} setSubAktif={setSubEkran} />
                    </li>
                    <li className={`first-level__item${(ekran === 'raporlar' ? ' active' : '')}`}>
                        <Link className="first-level__display" to="#" onClick={e => { e.preventDefault(); setEkran('raporlar'); }}>
                            <svg className="icon first-level__icon">
                                <use xlinkHref="../img/sprite.svg#icon-database"></use>
                            </svg>
                            <span className="first-level__span">
                                <Translate>Raporlar</Translate>
                            </span>
                            <svg className="icon first-level__icon circle">
                                <use xlinkHref="../img/sprite.svg#icon-circle-down"></use>
                            </svg>
                        </Link>
                        <Reports subAktif={subEkran} setSubAktif={setSubEkran} />
                    </li>
                </ul>
            </nav>
        </div>
    )
}  