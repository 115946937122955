export default {
    LOGIN: `user/login`,
    TRANSLATION: `dictionary/translations`,
    VALIDATETOKEN: `user/validatetoken`,
    USER_LIMITED: `user/limited`,

    FILTER_INVOICE: `filter/invoice`,
    FILTER_EXPSENSE: `filter/expense`,
    FILTER_BANKINGACTIVITY: `filter/bankingactivity`,

    INVOICE_LIST: `invoice/list`,
    INVOICE_DETAIL: `invoice/detail`,
    INVOICE_SAVE: `invoice/save`,
    INVOICE_DELETE: `invoice/delete`,
    INVOICE_FUTURE: `invoice/future`,
    INVOICE_FUTUREBYACMA: `invoice/futurebyacma`,
    INVOICE_FUTUREBYEINSURER: `invoice/futurebyinsurer`,
    INVOICE_DETAILDESC: `invoice/detaildesc`,
    INVOICE_CLEARCACHE: `invoice/clear-invoice-cache`,
    INVOICE_EXCEL: `invoice/excel`,
    INVOICE_EXCELSUMMARY: `report/excel`,
        
    REPORT_INVOICESUMMARY: `report/invoicesummary`,
    REPORT_REMAININGLIST: `report/remaininglist`,
    REPORT_ACCOUNTINVOICES: `report/accountinvoices`,
    REPORT_ACCOUNTSALES: `report/accountsales`,
    REPORT_BYTYPEEXPENSES: `report/bytypeexpenses`,
    REPORT_ACCOUNTIMELINE: `report/invoicetimeline`,

    POLICY_SEARCHLIST: `policy/searchedlist`,

    ACCOUNT_SEARCHLIST: `account/searchedlist`,
    ACCOUNT_LIST_SEARCH: `account/listsearch`,
    ACCOUNT_SAVE: `account/save`,

    PARAMETER_CURRENCY: `select/currencies`,
    PARAMETER_TAXRATES: `select/taxrates`,
    PARAMETER_EXPENSETYPES: `select/expensetypes`,
    PARAMETER_MAIN_EXPENSETYPES: `select/expensemaintypes`,
    PARAMETER_VENDORS: `select/vendors`,
    PARAMETER_USERS: `select/users`,
    PARAMETER_EXPENSEPAYMENTSOURCE: `select/expensepaymentsource`,
    PARAMETER_ACTIVITYTYPES: `select/activitytypes`,

    PAYMENT_SAVE: `payment/save`,
    PAYMENT_LIST: `payment/list`,

    EXPENSE_LIST: `expense/list`,
    EXPENSE_SAVE: `expense/save`,
    EXPENSE_EXCEL: `expense/excel`,
    EXPENSE_DELETE: `expense/delete`,
    EXPENSE_DETAIL: `expense/detail`,
    EXPENSE_FUTURE: `expense/future`,
    EXPENSE_FUTUREBYACMA: `expense/futurebyacma`,
    EXPENSE_FUTUREBYEXPENSETYPE: `expense/futurebyexpensetype`,

    EXPENSEPAYMENT_SAVE: `expensepayment/save`,
    EXPENSEPAYMENT_LIST: `expensepayment/list`,

    VENDOR_LIST: `vendor/list`,
    VENDOR_SAVE: `vendor/save`,
    VENDOR_DELETE: `vendor/delete`,

    EXPENSETYPE_LIST: `expenseType/list`,
    EXPENSETYPE_SAVE: `expenseType/save`,
    EXPENSETYPE_DELETE: `expenseType/delete`,

    BANKING_ACTIVITY_LIST: `bankactivity/list`,
    BANKING_ACTIVITY_DETAIL: `bankactivity/detail`,
    BANKING_ACTIVITY_SAVE: `bankactivity/save`,
    BANKING_ACTIVITY_DELETE: `bankactivity/delete`,
    BANKING_GROUPEDACTIVITIES: `bankactivity/groupedactivies`




}