import React, { useEffect } from "react";
import { Header } from "../header/Header";
import { useSelector, useDispatch } from "react-redux";
import ErrorBoundary from "../errorBoundry/ErrorBoundry";
import { LeftMenu } from "../leftSidebar/LeftMenu";
import { currencies, taxRates, expenseTypes, vendors, users, expensePaymentSource, activityTypes, expenseMainTypes } from "../apis/parameter";
import { translations } from "../apis/dictionary";
import {
  setCurrencies, setDictionary, setExpenseTypes, setTaxRates, setExpenseMainTypes,
  setUsers, setCulture, setExpensePaymentSource, setActivityTypes
} from "./duck/actions";
import { Modal } from "../modal/Modal";
import Loading from "../common/Loading";

export const Main = props => {
  const dispatch = useDispatch();
  const tciDictionary = useSelector(state => state.main.tciDictionary);
  const tciTaxRates = useSelector(state => state.main.taxRates);
  const tciCurrencies = useSelector(state => state.main.currencies);
  const tciExpenseTypes = useSelector(state => state.main.expenseTypes);
  const tciExpenseMainTypes = useSelector(state => state.main.expenseMainTypes);
  const isOpen = useSelector((state) => (state.header.isOpen));
  const tciUsers = useSelector(state => state.main.users);
  const culture = useSelector(state => state.main.culture);
  const tciExpensePaymentSource = useSelector(state => state.main.expensePaymentSource);
  const tciActivityTypes = useSelector(state => state.main.activityTypes);

  let mainClass = isOpen ? `right-side status-isopen` : `right-side`;

  let userData = !localStorage.userData ? {} : JSON.parse(localStorage.userData);

  useEffect(() => {

    if (!culture) {
      const localCulture = localStorage.getItem('culture');
      dispatch(setCulture(localCulture ? localCulture : 'tr-Tr'))
    }

    if (!tciDictionary || tciDictionary.length == 0) {
      translations().then(result => {
        dispatch(setDictionary(result.data));
      })
    }
    if (!tciTaxRates || tciTaxRates.length == 0) {
      taxRates().then(result => {
        dispatch(setTaxRates(result.data));
      })
    }
    if (!tciCurrencies || tciCurrencies.length == 0) {
      currencies().then(result => {
        dispatch(setCurrencies(result.data));
      })
    }
    if (!tciExpenseTypes || tciExpenseTypes.length == 0) {
      expenseTypes().then(result => {
        dispatch(setExpenseTypes(result.data));
      })
    }
    if (!tciUsers || tciUsers.length == 0) {
      users().then(result => {
        dispatch(setUsers(result.data));
      })
    }
    if (!tciExpensePaymentSource || tciExpensePaymentSource.length == 0) {
      expensePaymentSource().then(result => {
        dispatch(setExpensePaymentSource(result.data));
      })
    }
    if (!tciActivityTypes || tciActivityTypes.length == 0) {
      activityTypes().then(result => {
        dispatch(setActivityTypes(result.data));
      })
    }
    if (!tciExpenseMainTypes || tciExpenseMainTypes.length == 0) {
      expenseMainTypes().then(result => {
        dispatch(setExpenseMainTypes(result.data));
      })
    }
  }, []);

  return (
    ((!tciActivityTypes || tciActivityTypes.length == 0
      || !tciCurrencies || tciCurrencies.length == 0
      || !tciExpenseTypes || tciExpenseTypes.length == 0)
      && userData.name && window.location.pathname !== '/' && window.location.pathname !== '/login'
    ) ?
      <Loading className="medium" />
      :
      <div className="wrapper" >
        <LeftMenu />
        <section className={mainClass} id="right-side">
          <Header />
          <section className="main">
            <Modal />
            <ErrorBoundary>{props.children}</ErrorBoundary>
          </section>
        </section>
      </div >
  );
};
