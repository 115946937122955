import React, { useContext } from 'react';
import { Translate } from '../common/Translate';
import AccountAsync from '../selectAsync/AccountAsync';
import ExpenseContext from '../contexts/expense-context';
import UsersSelect from '../select/UsersSelect';

const ExpenseAccount = props => {

    const { setAccount, account, user, setUser } = useContext(ExpenseContext);

    return (
        <div className="expense-general__row narrow">
            <div className="expense-general__label">
                <svg className="expense-general__label--icon-small icon icon-user selected-filter__combo-item-icon">
                    <use xlinkHref="../img/sprite.svg#icon-copy"></use>
                </svg>
                <span className="expense-general__label--label">
                    <Translate>Account</Translate>
                </span>
            </div>
            <div className="expense-general__input double-flex">
                <AccountAsync setAccount={setAccount} value={account} />
                <UsersSelect setValue={setUser} value={user} />
            </div>
            <div className="expense-general__buttons">

            </div>
        </div>
    )
}

export default ExpenseAccount;