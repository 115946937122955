import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReportDate from '../filter/ReportDate';
import useStateIfMounted from "../hook/useStateIfMounted";
import { expenseSales } from './duck/actions';
import SalesExpense from './SalesExpense';

const SalesExpenseContainer = props => {

    const [data, setData] = useStateIfMounted({ sales: [], expenses: [] });
    const selectedFilterValues = useSelector(state => state.filter.selectedFilterValues);
    const dispatch = useDispatch();
    const [salesExpanded, setSalesExpanded] = useState(false);
    const [expenseExpanded, setExpenseExpanded] = useState(false);

    useEffect(() => {
        if (!selectedFilterValues || !selectedFilterValues.length) return;
        dispatch(expenseSales(selectedFilterValues)).then(res => {
            setData(res);
        });
    }, [selectedFilterValues])


    return (
        <div className="report-sales">
            <ReportDate pickerName='DueDate' />
            <SalesExpense
                data={data.sales}
                className="report-sales border-bottom"
                nameKey='accountName'
                keyKey='accountId'
                dataKey='amount'
                expanded={salesExpanded}
                setExpanded={setSalesExpanded}
                itemCount={4}
                header='GELIR'
            />
            <SalesExpense
                data={data.expenses}
                nameKey='expenseName'
                keyKey='expenseTypeId'
                dataKey='amount'
                expanded={expenseExpanded}
                setExpanded={setExpenseExpanded}
                itemCount={4}
                header='GIDER'
            />
        </div>
    )
}

export default SalesExpenseContainer;