import React from 'react'; 
import { useSelector } from 'react-redux';
import businessKeys from '../constants/businessKeys';


const translate = (itemKey, dictionary, culture) => {
    const item = dictionary.find(t => t.tciKey === itemKey);
    if (!item) return itemKey;
    const result = culture === businessKeys.enUs ? item.english : item.turkish;
    return result || itemKey;
}

export const Translate = props => {
    const tciDictionary = useSelector(state => state.main.tciDictionary);
    const culture = useSelector(state => state.main.culture);

    return (
        <>
        {translate(props.children, tciDictionary, culture)}
        </>
    )
}