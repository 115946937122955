import React, { memo } from 'react';
import moment from 'moment';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer
} from "recharts";


const EkoSimpleBarchart = memo(props => {
    const newData = extendData(props.data, props.dataKey);

    return (
        <div className="tci-bar-simple">

            <ResponsiveContainer>
                <BarChart
                    data={newData}
                >
                    <XAxis tickFormatter={timeStr => moment(timeStr).locale(props.culture || 'tr_Tr').format('MMMM YY')} dataKey={props.dataKey} />
                    <YAxis />
                    <Bar dataKey="amount" fill="#3a7ca5" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )

})


const extendData = (data, key) => {

    const newData = data.slice();

    data.sort((a, b) => a[key] > b[key] ? 1 : -1);

    const maxDate = new Date(data.slice(-1)[0][key]);
    const minDate = new Date(data.slice(0, 1)[0][key]);


    let date = new Date(minDate);
    let i = 0;

    while (date < maxDate || (date >= maxDate && i < 4)) {

        const item = data.find(item => {
            return new Date(item[key]).getFullYear() === date.getFullYear() && new Date(item[key]).getMonth() === date.getMonth()
        });
        if (!item) {
            newData.push({
                period: `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}`,
                periodName: new Date(date).toUTCString(),
                amont: 0
            });
        }
        date.setMonth(date.getMonth() + 1);
        i++;
    }
    return newData.sort((a, b) => a[key] > b[key] ? 1 : -1);
}


export default EkoSimpleBarchart;