import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useStateIfMounted from '../hook/useStateIfMounted';
import ExpenseContext from '../contexts/expense-context';
import Loading from '../common/Loading';
import ExpenseDescription from './ExpenseDesription';
import ExpenseTypeAndPeriod from './ExpenseTypeAndPeriod';
import ExpenseCurrency from './ExpenseCurrency';
import ExpenseVendorInvoice from './ExpenseVendorInvoice';
import ExpensePolicy from './ExpensePolicy';
import ExpenseAccount from './ExpenseAccount';
import ExpenseAmount from './ExpenseAmount';
import ExpenseArrangement from './ExpenseArrangement';
import ExpenseInstallments from './ExpenseInstallments';
import { selectValue } from '../common/comops';
import { detail } from '../apis/expense';
import businessKeys from '../constants/businessKeys';
import { Period } from '../common/Period';
import { setPeriods } from '../main/duck/actions';

const setExchangeRate = (rate) => {
    const element = document.querySelector(`#${businessKeys.EXPENSE_EXCHANGE_RATE}`);
    if (element)
        element.value = rate.toString();
}

const CreateExpense = props => {

    const refPrice = useRef();
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState(undefined);
    const [policy, setPolicy] = useState(undefined);
    const [arrangement, setArrangement] = useState(new Date());
    const [dueDate, setDueDate] = useState(undefined);
    const [currency, setCurrency] = useState({});
    const [getData, setGetData] = useState(true);
    const [data, setData] = useStateIfMounted({ installments: [] });
    const [vendor, setVendor] = useState(undefined);
    const [expenseType, setExpenseType] = useState({});
    const [taxRate, setTaxRate] = useState({ value: 0, label: "0%" });
    const [updating, setUpdating] = useState(false);
    const [activeInstallment, setActiveInstallment] = useState(-1);
    const [genel, setGenel] = useState(false);
    const [singlePeriod, setSinglePeriod] = useState(false);
    const [user, setUser] = useState({});
    const currencies = useSelector(state => state.main.currencies);
    const expenseTypes = useSelector(state => state.main.expenseTypes);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!getData || !props.expenseId) {
            if (getData)
                setGetData(false);
            return;
        };
        setLoading(true);
        setGetData(false);
        detail(props.expenseId).then(result => {
            setData(result.data);
            if (result.data.dueDate)
                setDueDate(new Date(result.data.dueDate));
            if (result.data.arrangementDate)
                setArrangement(new Date(result.data.arrangementDate));
            setCurrency(result.data.currencyId);

            setTaxRate(result.data.taxRate || 0);
            setGenel(result.data.genel || false);
            setUser(result.data.accountManagerId)
            setSinglePeriod({ value: result.data.expensePeriod, label: result.data.expensePeriod });
            const expenseTypeId = (result.data || {}).expenseTypeId;
            if (expenseTypeId) {
                const exType = expenseTypes.find(ex => ex.value === expenseTypeId);
                setExpenseType(exType);
            }

            const accountId = (result.data || {}).accountId;
            if (accountId) {
                setAccount({ value: accountId, label: result.data.accountName });
            }
            const vendorId = (result.data || {}).vendorId;
            if (vendorId) {
                setVendor({ value: vendorId, label: result.data.vendor });
            }

            const policyId = (result.data || {}).policyId;
            if (policyId) {
                setPolicy({ value: policyId, label: result.data.policy });
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [, getData]);

    useEffect(() => {
        if (!expenseType || !expenseType.stringHelper2) {
            dispatch(setPeriods([]));
            setSinglePeriod(null);
            return;
        }
        const types = expenseType.stringHelper2.split(';');
        const per = types.flatMap(item => Period.getPeriods(new Date().getFullYear(), +item)).sort().reverse();
        dispatch(setPeriods(per));
    }, [expenseType])

    useEffect(() => {
        // Eğer currency değişirse ona gore atiyor.
        if (currency && (data.currency !== selectValue(currency) || !data.exchangeRate) && currency.decimalHelper) {
            setExchangeRate(currency.decimalHelper);
        } else if (data.exchangeRate) {
            setExchangeRate(data.exchangeRate);
        }
    }, [currency]);

    useEffect(() => {
        if (!selectValue(policy))
            return;
        if (!selectValue(account))
            setAccount({ value: policy.intHelper4, label: policy.stringHelper })
        if (!selectValue(currency)) {
            const selectedCurrency = currencies.find(item => item.value === policy.intHelper);
            setCurrency(selectedCurrency);
        }
        if (!selectValue(user))
            setUser(policy.intHelper3)

    }, [policy])

    return (<ExpenseContext.Provider value={{
        data, loading, account, policy,
        arrangement, currency, setCurrency, getData, setGetData, vendor, setVendor, expenseType, setExpenseType,
        expenseId: props.expenseId, setPolicy, setAccount, taxRate, setTaxRate, setArrangement, dueDate, setDueDate, refPrice,
        updating, setUpdating, setData, activeInstallment, setActiveInstallment, genel, setGenel, user, setUser, singlePeriod, setSinglePeriod
    }}>
        <div className="create-expense">
            {loading ? <Loading className="big" /> : <>
                <div className="expense-general">
                    <ExpenseDescription />
                    <ExpenseTypeAndPeriod />
                    <ExpenseVendorInvoice />
                    <ExpenseCurrency />
                    <ExpensePolicy />
                    <ExpenseAccount />
                    <ExpenseArrangement />
                    <ExpenseAmount ref={refPrice} />
                    {/*  <ExpenseDue /> */}
                    <ExpenseInstallments />
                </div>
            </>}
        </div>
    </ExpenseContext.Provider>
    )
}


export default CreateExpense;