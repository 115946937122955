import React from 'react';
import { Translate } from '../common/Translate';

const RadioButton = props => {

    const { checkChange, value, id, name } = props;
    const fn = checkChange || (() => { });
    return (
        <div className="radio-group" key={props.id}>
            <input
                type="radio"
                className="radio-group__input"
                id={`id${id}`}
                name={`${name || 'radio-generic-name'}`}
                onChange={fn}
                data-val={props.val}
            />
            <label htmlFor={`id${id}`} className="radio-group__label">
                <span className="radio-group__radio-button"></span>
                <span className="radio-group__text"><Translate>{value || ''}</Translate></span>
            </label>
        </div>
    )
}

export default RadioButton;