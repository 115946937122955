import * as comops from '../common/comops';
import urls from '../constants/urls';

export const list = async (filters, sort, pageNumber) => {

    const payload = {
        filters,
        sort,
        pageNumber
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.EXPENSE_LIST, options);

    if (!res.isSuccess || !res.data)
        res = [];

    return res;
}

export const excelExportExpense = (filters) => {
    const payload = {
      filters
    };
  
    const options = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(payload),
    };
  
    comops.postExcelApiResult(urls.EXPENSE_EXCEL, options);
  };

export const save = async (payload) => {

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.EXPENSE_SAVE, options);

    return res;
}

export const deleteItem = async (id) => {

    const payload = {
        id
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    const res = await comops.fetchAsync(urls.EXPENSE_DELETE, options);

    return res;
}


export const detail = async (id) => {

    const payload = {
        id
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload)
    };

    let res = await comops.fetchAsync(urls.EXPENSE_DETAIL, options);

    if (!res.isSuccess || !res.data)
        res = { data: { invoiceDetails: [] } };

    return res;
}


export const future = async () => {
    const options = {
        method: 'POST',
        mode: 'cors'
    };

    let res = await comops.fetchAsync(urls.EXPENSE_FUTURE, options);

    if (!res.isSuccess || !res.data)
        res = { data: [] };

    return res;
}

export const futureByAcma = async () => {
    const options = {
        method: 'POST',
        mode: 'cors'
    };

    let res = await comops.fetchAsync(urls.EXPENSE_FUTUREBYACMA, options);

    if (!res.isSuccess || !res.data)
        res = { data: [] };

    return res;
}

export const futureByExpenseType = async () => {
    const options = {
        method: 'POST',
        mode: 'cors'
    };

    let res = await comops.fetchAsync(urls.EXPENSE_FUTUREBYEXPENSETYPE, options);

    if (!res.isSuccess || !res.data)
        res = { data: [] };

    return res;
}