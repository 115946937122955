import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from '../common/Translate';
import Loading from '../common/Loading';
import { saveExpenseType, deleteExpenseType } from './duck/actions';
import ExpenseMainTypeSelect from '../select/ExpenseMainTypeSelect';
import { selectValue } from '../common/comops';


const ExpenseTypeData = props => {

    const { loading, data, selected, setSelected, onChange, setGetValues, mainType, setMainType } = props;

    let newItem = { id: 0, expenseDescription: '', userId: 0 }

    return (
        <>
            {
                !selected &&
                <div className="meta__new">
                    {
                        <>
                            <h3 className="meta__header"><Translate>Yeni Gider Türü</Translate></h3>
                            <ExpenseTypeActiveRow setGetValues={setGetValues} item={newItem} selected={selected} setSelected={setSelected} key={`key_${newItem.id}`} />
                        </>
                    }
                </div>
            }
            <div className="meta__text-container">
                <h3 className="meta__header"><Translate>Gider Türü Seç</Translate></h3>
                <input type="text" className="meta__text" onChange={onChange}></input>
            </div>
            <div className="meta__list">
                {
                    loading ? <Loading className="medium" /> :
                        data.map(item => item.id !== selected ? <ExpenseTypeRow setGetValues={setGetValues} item={item} selected={selected} setSelected={setSelected} key={`key_${item.id}`} />
                            : <ExpenseTypeActiveRow mainType={mainType} setMainType={setMainType} setGetValues={setGetValues} item={item} selected={selected} setSelected={setSelected} key={`key_${item.id}`} />)
                }
            </div>
        </>
    )

}


const ExpenseTypeRow = memo(props => {

    const { item, setSelected, setGetValues } = props;
    const dispatch = useDispatch();

    return (
        <div className="meta__list-row">
            <div className="meta__meta-name">
                <span>{item.expenseDescription}</span>
            </div>
            <div className="meta__meta-main">
                <span>{item.mainType}</span>
            </div>
            <div className="meta__buttons">
                <svg className="icon icon-plus" onClick={() => { setSelected(item.id); }}>
                    <use xlinkHref="../img/sprite.svg#icon-plus"></use>
                </svg>
                <svg className="icon icon-minus" onClick={() => dispatch(deleteExpenseType(item, setGetValues))}>
                    <use xlinkHref="../img/sprite.svg#icon-minus"></use>
                </svg>
            </div>
        </div>
    )
})


const ExpenseTypeActiveRow = props => {

    const { item, setSelected, setGetValues, mainType, setMainType } = props;
    const dispatch = useDispatch();
    const inputId = `meta-expense-type-name-${item.id}`;

    return (
        <div className="meta__list-row double-flex">
            <div className="meta__meta-name">
                <input type="text" id={inputId} defaultValue={item.expenseDescription}></input>
            </div>
            <div className="meta__meta-main double-flex">
                <ExpenseMainTypeSelect value={mainType} setValue={setMainType} />
            </div>
            <div className="meta__buttons">
                <svg className="icon icon-checkmark" onClick={() => {
                    const element = document.querySelector(`#${inputId}`);
                    item.expenseDescription = element ? element.value : '';
                    item.mainTypeId = selectValue(mainType);
                    dispatch(saveExpenseType(item, setGetValues)).finally(() => setSelected(0));
                }}>
                    <use xlinkHref="../img/sprite.svg#icon-checkmark"></use>
                </svg>
            <svg className="icon icon-minus" onClick={() => { setSelected(0); setGetValues(true); }}>
                <use xlinkHref="../img/sprite.svg#icon-cross"></use>
            </svg>
        </div>
        </div >
    )
}


export default ExpenseTypeData;