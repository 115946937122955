export default {
    FILTER_SETSELECTEDFILTER: 'FILTER_SETSELECTEDFILTER',
    FILTER_SETSELECTEDFILTERANDVALUE: 'FILTER_SETSELECTEDFILTERANDVALUE',
    FILTER_TOGGLEFILTERVALUE: 'FILTER_TOGGLEFILTERVALUE',
    FILTER_CHANGESELECTEDVALUES: 'FILTER_CHANGESELECTEDVALUES',
    FILTER_TOGGLEACTIVE: 'FILTER_TOGGLEACTIVE',
    FILTER_SETFIRSTDATE: 'FILTER_SETFIRSTDATE',
    FILTER_SETSEONDATE: 'FILTER_SETSECONDDATE',
    FILTER_SETDATES: 'FILTER_SETDATES',
    FILTER_PICKERACTIVE: 'FILTER_PICKERACTIVE',
    FILTER_PICKERDATANAME: 'FILTER_PICKERDATANAME',
    FILTER_RESETPICKER: 'FILTER_RESETPICKER',
    FILTER_CHANGEACTIVE: 'FILTER_CHANGEACTIVE',
    FILTER_GETVALUE: 'FILTER_GETVALUE',
    FILTER_SETORDER: 'FILTER_SETORDER',
    FILTER_PAGENUMBER: 'FILTER_PAGENUMBER',
    FILTER_INITIALIZE: 'FILTER_INITIALIZE'
}

//const initialDate = isSecond ? new Date().setMonth(new Date().getMonth() + 1) : new Date();
    