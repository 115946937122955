import React from 'react';
import { numericFilter, onBlur, onFocus } from './comops';

const guid = () => {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
}



const DecimalNumber = (props, ref) => {

    const className = `${props.className || ''}${props.className && ' '}decimal-textbox`;

    return <input className={className} ref={ref} id={props.id || guid()} type="text" defaultValue={props.defaultValue} onFocus={e => onFocus(e.target)} onKeyUp={e => numericFilter(e.target)}
        onBlur={e => onBlur(e.target, ((!props.pre && props.pre !== 0) ? 2 : props.pre))}></input>
}

const fowardedOne = React.forwardRef(DecimalNumber);

export default fowardedOne;