import React from 'react';
import { Main } from "../app/main/Main";
import BankActivities from '../app/bank/BankActivities';

const BankActivityPage = props => {
    return (<Main>
        <BankActivities />
    </Main>
    )
}

export default BankActivityPage;