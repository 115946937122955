import React from 'react';
import { Main } from "../app/main/Main";
import CreateExpense from '../app/expense/CreateExpense';

const ExpensePage = props => {

    const expenseId = parseInt(props.match.params.expenseId);
    return (<Main>
        <CreateExpense expenseId={expenseId} />
    </Main>
    )
}

export default ExpensePage;