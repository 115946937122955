import React, { memo } from 'react';
import dictionary from '../constants/dictionary';
import { Translate } from './Translate';
import businessKeys from '../constants/businessKeys';
import FormattedDecimal from '../common/FormattedDecimal';

const GenericSummaryTable = memo(props => {

    const { data, dataKey, nameKey, keyKey, expanded, itemCount, setExpanded, setKey, header } = props;
    const items = expanded ? data : data.slice(0, itemCount);
    let remainingCount = 0;

    const total = data.reduce((agg, num) => agg + num.amount, 0);
    const totalItem = {
        [keyKey]: null,
        [nameKey]: 'TOPLAM',
        [dataKey]: total
    }

    if (!expanded) {
        const remainingItems = data.slice(itemCount);
        const othersTotal = remainingItems.reduce((agg, item) => agg + item[dataKey], 0);
        items.push({
            [keyKey]: -1,
            [nameKey]: 'Diger',
            [dataKey]: othersTotal
        });
        remainingCount = remainingItems.length;
    }


    return (<div className="sales-summary">
        <SingleRow item={totalItem} setKey={setKey} dataKey={dataKey} nameKey={nameKey} keyKey={keyKey} header={header} />
        {items.map((item, index) => <SingleRow key={item[keyKey]} item={item} setKey={setKey} index={index} dataKey={dataKey} nameKey={nameKey} keyKey={keyKey} />)}
        <ExpandButton expanded={expanded} setExpanded={setExpanded} remainingCount={remainingCount} />
    </div>)

})



const SingleRow = props => {
    const { item, setKey, index, dataKey, nameKey, keyKey, header } = props;

    const color = businessKeys.KNOWNCOLORS.find(c => item[nameKey] === c.name)
        ? businessKeys.KNOWNCOLORS.find(c => item[nameKey] === c.name).color
        : businessKeys.COLORS[index % businessKeys.COLORS.length];

    return (
        <div
            className={`sales-summary__row sales-summary__row--${item[nameKey].toLowerCase().replace(/\s/g, '')}`}
            onClick={() => {
                if (item[keyKey] === -1) return;
                setKey(item[keyKey]);
            }}
        >
            <span className="sales-summary__row--legend legend-color" style={{ backgroundColor: color }}>

            </span>
            <span className="sales-summary__account">
                {header || item[nameKey]}
            </span>
            <FormattedDecimal text="" value={item[dataKey]} abb='$' className="sales-summary__amount" />
        </div>

    )
}


const ExpandButton = props => {
    const { setExpanded, expanded, remainingCount } = props;
    return (<div className="sales-summary__row container-button" onClick={() => setExpanded(!expanded)}>
        <span className="container-button__text">{(expanded ? <Translate>{dictionary.EXPANDED}</Translate> : <Translate>{dictionary.NOTEXPANDED}</Translate>)} {expanded ? '' : `(${remainingCount})`}</span>
    </div>)

}

export default GenericSummaryTable;