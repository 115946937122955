export const getInstallmentsByQuantity = (masterId, amount, date, quantity) => {
    const firstInstallment = new Date(date);

    const tutar = Math.round(amount * 100 / quantity) / 100;
    const artik = amount - (tutar * (quantity - 1));

    const result = new Array(quantity).fill().map((el, index) => ({
        id: -10 * quantity + index * 10,
        masterId,
        installmentNo: index + 1,
        installmentAmount: index === (quantity - 1) ? artik : tutar,
        installmentDate: new Date(getNextWeekDay(new Date(firstInstallment).setMonth(new Date(firstInstallment).getMonth() + index))),
        ispaid: false
    }));

    return result;
}


export const getNextWeekDay = date => {
    const weekDay = new Date(date).getDay();
    if (weekDay !== 6 && weekDay !== 0) return date;
    const diff = (8 - weekDay) % 7;
    return new Date(new Date(date).setDate(new Date(date).getDate() + diff));
}