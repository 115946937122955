import { deleteItem } from '../../apis/invoice';
import { showMessage, showConfirmation } from '../../modal/duck/actions';
import { setGetValues } from '../../filter/duck/actions';

export const deleteInvoice = (id) => dispatch => {

    var message = "Gideri silmek istediğinize eminmisiniz?";
    dispatch(showConfirmation(message, () => dispatch(deleteInvoiceNative(id))));
}


const deleteInvoiceNative = (id) => async dispatch => {

    var result = await deleteItem(id);

    dispatch(showMessage(result.data.message));

    if (result.isSuccess)
        dispatch(setGetValues(true));

} 